import { TRENDING_EXPERTS } from '../types';

const initialState = {
  trendingExperts: [],
  loading: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TRENDING_EXPERTS.FETCH_TRENDING_EXPERTS:
      return { ...state, trendingExperts: payload, loading: false };
    default:
      return state;
  }
};
