import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '../Components/Container';
import ReviewPetProfile from '../Components/PetPageComponents/ReviewPetProfile';
import SectionOne from '../Components/PetPageComponents/SectionOne';
import SectionThree from '../Components/PetPageComponents/SectionThree';
import SectionTwo from '../Components/PetPageComponents/SectionTwo';
import TopButtons from '../Components/PetPageComponents/TopButtons';
import { ADD_PET } from '../Redux/types';

const PetPage = () => {
  const { step } = useSelector(({ addPet }) => addPet);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: ADD_PET.CLEAR_DATA });
  }, []);

  return (
    <div className='py-4'>
      <Container>
        {step !== 3 && <TopButtons sec={step} />}
        <div className='mt-7 flex'>
          {step === 0 ? (
            <SectionOne />
          ) : step === 1 ? (
            <SectionTwo />
          ) : step === 2 ? (
            <SectionThree />
          ) : (
            <ReviewPetProfile />
          )}
        </div>
      </Container>
    </div>
  );
};

export default PetPage;
