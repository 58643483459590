import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = ({ name, id, title, checked, onPress, disabled }) => {
  return (
    <div className='form-check pb-4 cursor-pointer' onClick={onPress}>
      <input
        className='form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-action checked:border-action focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer'
        type='radio'
        name={name}
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={onPress}
      />
      <label
        className='form-check-label font-number text-sm pointer-events-none inline-block text-gray-800'
        htmlFor={id}
      >
        {title}
      </label>
    </div>
  );
};

RadioButton.defaultProps = {
  onPress: () => {},
  disabled: false,
};

RadioButton.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  onPress: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default RadioButton;
