import PropTypes from 'prop-types';
import React from 'react';
import { BsGenderFemale, BsGenderMale } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const PetCard = ({ item, pet, selected, className, animate, link }) => {
  const { image, name, breed, gender, age } = item;
  const size = pet ? 12 : 24;
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate(link);
  };

  return (
    <div
      className={`relative p-4 ${
        !selected ? 'border-1 border-borderColour' : 'border-2 border-action'
      } rounded-lg flex items-center flex-col cursor-pointer ${className} ${
        animate && 'wow animate__animated animate__fadeInUp animate__delay-0.4s'
      }`}
      onClick={navigateTo}
    >
      <div className='absolute left-2 top-2'>
        {gender === 'male' ? (
          <BsGenderMale size={20} fill='#79d1bb' />
        ) : gender === 'female' ? (
          <BsGenderFemale size={20} fill='#79d1bb' />
        ) : (
          ''
        )}
      </div>
      <div className={` w-${size} h-${size} rounded-full `}>
        <img
          src={image}
          alt='pet-image'
          style={{ height: '100%', width: '100%' }}
          className={` object-cover rounded-${!pet ? 'full' : 'none'}`}
        />
      </div>
      <p className='text-base font-bold mt-2 mb-1'>{name}</p>
      <p className='text-xs font-medium text-center lg:single-line md:single-line hidden lg:block'>
        {pet ? breed : `${breed} | ${age} years old`}
      </p>
      <p className='text-xs lg:hidden font-medium text-center lg:single-line md:single-line'>
        {pet ? breed : `${breed}`}
        <br></br>
        {pet ? breed : `${age} years old`}
      </p>
    </div>
  );
};

PetCard.defaultProps = {
  pet: false,
  selected: false,
  className: '',
  animate: false,
  link: '',
};

PetCard.propTypes = {
  item: PropTypes.object.isRequired,
  pet: PropTypes.bool,
  gender: PropTypes.string,
  selected: PropTypes.bool,
  className: PropTypes.string,
  animate: PropTypes.bool,
  link: PropTypes.string,
};

export default PetCard;
