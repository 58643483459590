import React from 'react';
import Modal from './HOC/Modal';
import PropTypes from 'prop-types';
import GradientButton from './GradientButton';

const LocationConfirm = ({ visibility, setVisibility, onConfirm }) => {
  return (
    <Modal visibilty={visibility}>
      <div className='relative p-4 flex-auto max-w-sm'>
        <div className='my-2'>
          <p className='text-dark text-base font-semibold leading-relaxed text-center'>
            Is this your Home address (where applicable) / just current
            location. Please ensure that this is your Home address
          </p>
        </div>
      </div>
      <div className='flex items-center justify-center mb-4 rounded-b'>
        <GradientButton
          title='YES'
          onPress={() => {
            setVisibility(false);
            onConfirm();
          }}
          size='sm'
        />
        <div className='w-4' />
        <GradientButton
          title='NO'
          onPress={() => {
            setVisibility(false);
          }}
          size='sm'
          grey
        />
      </div>
    </Modal>
  );
};

LocationConfirm.propTypes = {
  visibility: PropTypes.bool,
  setVisibility: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default LocationConfirm;
