const initialState = {
  selectedService: [],
  pathologyPackage: [],
  pathologyTest: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "ADD_SERVICES":
      return {
        ...state,
        selectedService: payload,
      };
    case "ADD_MORE_SERVICES":
      return {
        ...state,
        selectedService: [...state.selectedService, payload],
      };

    case "REMOVE_SERVICE":
      // eslint-disable-next-line no-case-declarations
      let newService = state.selectedService.filter(
        (service) => service.name !== payload.name
      );
      return {
        ...state,
        selectedService: newService,
      };
    case "GET_PATHOLOGY_PACKAGE_TEST":
      return {
        ...state,
        pathologyPackage: payload.pathologyPackage.packages,
        pathologyTest: payload.pathologyTest.test,
      };
    default:
      return state;
  }
};
