import React, {useRef, useState} from 'react';
import { FaDog, FaRegCalendarAlt } from 'react-icons/fa';
import { GiWeightScale } from 'react-icons/gi';
import { MdOutlineAccountCircle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { GradientButton } from '..';
import GENDER from '../../Assets/icons/Gender.png';
import {
  AGE_OPTIONS,
  MONTH_OPTIONS,
  WEIGHT_OPTIONS,
} from '../../Constants/Constants';
import {
  decrementStep,
  incrementStep,
  setPetDetails,
} from '../../Redux/actions/addPetActions';
import { isEmpty } from '../../utils/utils';
import FileInput from '../FileInput';
import Input from '../Input';
import InputSelect from '../InputSelect';
import Crop from '../ImageCrop/index'


const SectionTwo = () => {
  const { name, breed, weight, age, gender, months, image } = useSelector(
    ({ addPet }) => addPet
  );
  const [formData, setFormData] = useState({
    name: name,
    weight: weight,
    age: age,
    breed: breed,
    gender: gender,
    image: image,
    months,
  });

  const dispatch = useDispatch();

  const [cropImage, setCropImage] = useState(formData.image);

  return (
    <div className='pb-6 w-full'>
      <h4 className='text-lg'>
        <b>Enter your pet&apos;s details</b>
      </h4>
      {/* <h5>Lorem ipsum dolor sit amet, consectetur adipiscing.</h5> */}
      <div className='lg:flex'>
         <div className='max-w-[300px] min-w-[300px] mt-4 ml-[20%] md:ml-[35%] lg:ml-[1px]'>
          <Crop
            data={formData.image}
            setFile={(file) => {setCropImage(file)}}
            uploadFile={(file) => {setFormData({ ...formData, image: file })}}
          />
        </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setFormData({ ...formData, image: cropImage })
          dispatch(setPetDetails({ ...formData, image: cropImage }));
          dispatch(incrementStep());
        }}
      >
        <div className='grid md:grid-cols-2 xs:grid-cols-1 gap-5 mt-5 lg:w-[740px] lg:ml-[84px]'>
          <div>
            <Input
              required
              type='text'
              placeholder='Pet Name'
              Icon={MdOutlineAccountCircle}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              defaultValue={name}
            />
            <div className='mb-4'>
              <InputSelect
                options={WEIGHT_OPTIONS}
                placeholder='Weight'
                value={weight}
                Icon={GiWeightScale}
                onChange={(e) => setFormData({ ...formData, weight: e.value })}
              />
            </div>

            <div className='flex'>
              <InputSelect
                options={AGE_OPTIONS}
                className='col-span-2'
                placeholder='Age Year'
                Icon={FaRegCalendarAlt}
                onChange={(e) => setFormData({ ...formData, age: e.value })}
                value={age}
                fullWidth
              />
              <InputSelect
                placeholder='Months'
                options={MONTH_OPTIONS}
                className='w-32'
                onChange={(e) => setFormData({ ...formData, months: e.value })}
                value={months}
                defaultValue={months}
              />
            </div>
          </div>
          <div>
            <Input
              required
              type='text'
              placeholder='Breed / Species'
              Icon={FaDog}
              defaultValue={breed}
              onChange={(e) =>
                setFormData({ ...formData, breed: e.target.value })
              }
            />
            <InputSelect
              options={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
              ]}
              placeholder='Gender'
              Icon={() => (
                <img src={GENDER} className='h-6 w-6 object-contain' />
              )}
              onChange={(e) => setFormData({ ...formData, gender: e.value })}
              value={gender}
            />
          </div>
        </div>
        <div className='flex justify-center md:justify-end mt-6'>
          <GradientButton
            title='Back'
            className='mr-4'
            grey
            onPress={() => {
              dispatch(decrementStep());
            }}
          />
          <GradientButton
            title='NEXT'
            disabled={!isEmpty({...formData, cropImage}, ['image'])}
            type='submit'
          />
        </div>
      </form>
      </div>
    </div>
  );
};

export default SectionTwo;
