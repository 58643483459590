/* eslint-disable no-undef */
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { IoLocationOutline } from 'react-icons/io5';
import { MdOutlineLocationCity } from 'react-icons/md';
import { VscLocation } from 'react-icons/vsc';
import { useDispatch } from 'react-redux';
import { Container, GradientButton, Modal } from '..';
import {
  incrementStep,
  setPetLocation,
} from '../../Redux/actions/addPetActions';
import { isEmpty, distance } from '../../utils/utils';
import Input from '../Input';
import LocationConfirm from '../LocationConfirm';

const EditSectionTwo = ({ details }) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [locationConfirmation, setLocationConfirmation] = useState(false);
  const [notify, setNotify] = useState(true);
  const [change, setChange] = useState(false);

  const [formData, setFormData] = useState({
    address: details.location_address,
    latitude: details.location_latitude,
    longitude: details.location_longitude,
    building: details.building || '',
  });

  const dispatch = useDispatch();
  const handleLocationSelect = ({ formatted_address, geometry }) => {
    setFormData({
      ...formData,
      address: formatted_address,
      latitude: geometry.location.lat(),
      longitude: geometry.location.lng(),
    });
  };

  const updateLocation = () => {
    dispatch(
      setPetLocation({
        latitude: formData.latitude,
        longitude: formData.longitude,
        address: formData.address,
        building: formData.building,
      })
    );
    dispatch(incrementStep());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      distance(19.07609, formData.latitude, 72.877426, formData.longitude) >
        100 &&
      formData.address !== ''
    ) {
      setModalVisibility(true);
    } else {
      setLocationConfirmation(true);
    }
  };

  const [ loading , setLoading] = useState(true);

  return (
    <Container className='pb-6'>
      <Modal visibilty={modalVisibility}>
        <div className='relative p-6 flex-auto'>
          <p className='font-semibold text-7xl flex justify-center'>
            <IoLocationOutline fill='#79d1bb' />
          </p>
          <div className='my-4'>
            <p className='text-dark text-lg leading-relaxed text-center'>
              Thank you for showing your interest.
            </p>
            <p className='text-dark text-lg leading-relaxed text-center'>
              We are however not operational outside Mumbai.
            </p>
          </div>
          <div className='form-check flex justify-center items-center'>
            <input
              className='mr-1 cursor-pointer w-5 h-5'
              type='checkbox'
              value=''
              id='flexCheckDefault'
              checked={notify}
              onChange={() => setNotify(!notify)}
            />
            <label
              className='form-check-label text-base inline-block text-gray-800'
              htmlFor='flexCheckDefault'
            >
              Notify me when PawPurrfect is active in my city.
            </label>
          </div>
        </div>
        <div className='flex items-center justify-center p-6 rounded-b'>
          <button
            onClick={() => {
              setModalVisibility(false);
            }}>
            <GradientButton title='BACK' />
          </button>
        </div>
      </Modal>
      <h4 className='text-lg'>
        <b>Your pet location</b>
      </h4>
      <div className='grid md:grid-cols-2 gap-5'>
        <div className='mt-5'>
          <div style={{ height: '40vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_MAP_KEY,
              }}
              defaultCenter={{ lat: 19.076, lng: 72.8777 }}
              defaultZoom={14}
              center={{
                lat: formData.latitude || 19.076,
                lng: formData.longitude || 72.8777,
              }}
            >
              {formData.latitude && formData.longitude && (
                <FaMapMarkerAlt
                  lat={formData.latitude}
                  lng={formData.longitude}
                  size={26}
                  text={formData.address}
                  fill='#79d1bb'
                />
              )}
            </GoogleMapReact>
          </div>
        </div>
        <div>
          <form className='mt-5 grid grid-rows-8' onSubmit={handleSubmit}>
            <div
              className={`p-3 flex border-1 overflow-hidden my-3 first:mt-0 font-number rounded-md last:mb-0 relative ${
                change ? 'pointer-events-auto' : 'pointer-events-none'
              }`}
            >
              <div className='absolute absolute-center-x left-3'>
                <VscLocation size={26} fill='#79d1bb' />
              </div>
              <ReactGoogleAutocomplete
                apiKey={process.env.REACT_APP_MAP_KEY}
                options={{
                  componentRestrictions: { country: "in" },
                  types: ['establishment']
                }}
                onPlaceSelected={handleLocationSelect}
                className='pl-12 w-full'
                defaultValue={details.location_address}
              />
            </div>
            <div
              className='text-action font-semibold text-right cursor-pointer'
              onClick={() => setChange(true)}
            >
              Change
            </div>
            <Input
              required
              type='text'
              placeholder='Flat/Building/Street'
              Icon={MdOutlineLocationCity}
              onChange={(e) =>
                setFormData({ ...formData, building: e.target.value })
              }
              defaultValue={formData.building}
            />
            <div></div>
            <div></div>
            <div></div>
            <div className='p-3 flex md:justify-end justify-center'>
              {/* <GradientButton
                title='Back'
                className='mr-4'
                grey
                onPress={() => {
                  dispatch(decrementStep());
                }}
              /> */}
              <GradientButton
                title='REVIEW & SUBMIT'
                disabled={!isEmpty(formData)}
                type='submit'
              />
            </div>
          </form>
        </div>
      </div>
      <LocationConfirm
        visibility={locationConfirmation}
        setVisibility={setLocationConfirmation}
        onConfirm={updateLocation}
      />
    </Container>
  );
};

EditSectionTwo.defaultProps = {
  details: {},
};

EditSectionTwo.propTypes = {
  details: PropTypes.object,
};

export default EditSectionTwo;
