import React from "react";
import { GradientButton } from "..";
import PropTypes from "prop-types";
import LogoImg from "../../Assets/logo.png";
import { getAuth } from "firebase/auth";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const PayWithRazorPay = ({ onComplete, amount, startTransaction }) => {
  const displayRazorpay = async (e) => {
    startTransaction();
    e.preventDefault();
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const auth = getAuth();

    let options = {
      // eslint-disable-next-line no-undef
      key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      amount: (amount * 1000) / 10, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Pawpurrfect",
      description: "Test Transaction",
      image: LogoImg,
      // order_id: 'order_9A33XWu170gUtm', //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        onComplete(response.razorpay_payment_id);
      },
      prefill: {
        name: auth.currentUser?.displayName,
        email: auth.currentUser?.email,
        contact: auth.currentUser?.phoneNumber,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#1EAF9C",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
  };

  return (
    <GradientButton
      title="PAY NOW"
      className="w-full mt-7"
      disabled={amount === 0}
      onPress={displayRazorpay}
    />
  );
};

PayWithRazorPay.defaultProps = {
  onComplete: () => {},
  startTransaction: () => {},
};

PayWithRazorPay.propTypes = {
  onComplete: PropTypes.func,
  amount: PropTypes.number,
  startTransaction: PropTypes.func,
};

export default PayWithRazorPay;
