import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {InputSelect, RadioButton} from '..';
import {SESSIONS, TRAINING_TYPE, SESSIONS_OLDER} from '../../Constants/Constants';

const TrainingOptions = ({ setData }) => {
  const [type, setType] = useState(1);
  const [formData, setFormData] = useState({
    type: 'firstTraining',
    sessions: 'Three',
  });

  const [formData2, setFormData2] = useState({
    type: 'firstTraining',
    sessions: 'Three',
  });

  useEffect(() => {
    setData(formData);
  }, [formData]);

  return (
    <>
    <h6 className='mb-3 '>Select service for</h6>
    <div className='flex gap-7 items-center mt-2'>
      
        <RadioButton
          name='puppyType'
          id='puppyType'
          title='Young puppy'
          checked={type === 1}
          onPress={() => {
            setType(1)
            setFormData(({
              type: 'firstTraining',
              sessions: 'Three',
            }))
          }}
        />
        <RadioButton
          name='puppyType'
          id='puppyType'
          title='Older pet'
          checked={type === 2}
          onPress={() => {
            setType(2)
            setFormData({
              type: 'reTraining',
              sessions: 'Three',
            })
          }}
        />
      </div>
      
      <div className='grid grid-cols-2 mt-5 mb-3 gap-5'>
        {type === 2 ? (
          <>
          <div>
            <h2 className='mb-3'>Type Of Training</h2>
            <InputSelect
              placeholder='Please Select'
              options={TRAINING_TYPE}
              value={formData2.type}
              onChange={(e) => setFormData2({ ...formData2, type: e.value })}
            />
          </div>
          <div>
          <h6 className='mb-3'>Number of Sessions</h6>
          <InputSelect
            placeholder={SESSIONS_OLDER[0].label}
            options={SESSIONS_OLDER}
            value={formData.sessions}
            onChange={(e) => setFormData({ ...formData, sessions: e.value })}
          />
        </div>
        </>
        ): null}
        {type === 1 ? (
        <div>
          <h6 className='mb-3'>Number of Sessions</h6>
          <InputSelect
            placeholder={SESSIONS[0].label}
            options={SESSIONS}
            value={formData.sessions}
            onChange={(e) => setFormData({ ...formData, sessions: e.value })}
          />
        </div>
        ) : null
        }
      </div>
      
    </>
  );
};

TrainingOptions.defaultProps = {
  setData: () => {},
};

TrainingOptions.propTypes = {
  setData: PropTypes.func,
};

export default TrainingOptions;
