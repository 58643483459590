import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

// Get a document from the collection
// Accept two parameters
// 1. collection name - Type String
// 2. document id - Type String

export const getDocuments = async (collectionName, documentName) => {
  try {
    const docRef = doc(db, collectionName, documentName);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (error) {
    console.log("Error getting document:", error);
  }
};
