import { getAuth } from "firebase/auth";
import { doc, getDocs, limit, query, setDoc, where, updateDoc } from "firebase/firestore";
import { db, seekerDetailsRef } from "../../firebase";
import { USER } from "../types";

export const setUser = (user) => async (dispatch) => {
  dispatch({ type: USER.SET_USER, payload: user });
  const uid = user.uid;
  const q = query(seekerDetailsRef, where("uid", "==", uid), limit(1));

  try {
    //When ever user logs in, we store user time and source of login in database
    await updateDoc(doc(db, "seeker_details", uid), {
      last_login: {
        time: new Date(),
        source: "website",
      },
    });
  } catch (error) {
    console.log(error.message);
  }

  const querySnapshot = await getDocs(q);
  const result = [];
  querySnapshot.forEach((doc) => {
    result.push(doc.data());
  });
  dispatch({ type: USER.SET_SEEKER_DATA, payload: result[0] });
  setTimeout(async function () {
    const q = query(seekerDetailsRef, where("uid", "==", uid), limit(1));
    const querySnapshot = await getDocs(q);
    const result = [];
    querySnapshot.forEach((doc) => {
      result.push(doc.data());
    });
    dispatch({ type: USER.SET_SEEKER_DATA, payload: result[0] });
  }, 10000);
};

export const setUserQuery = (user) => async (dispatch) => {
  dispatch({ type: USER.SET_USER_QUERY, payload: user });
};

export const setRedirect = (url) => async (dispatch) => {
  dispatch({ type: USER.SET_REDIRECT, payload: url });
};

export const updateUser = async (updatedData) => {
  const auth = getAuth();
  const uid = auth.currentUser.uid;
  await setDoc(doc(db, "users_common", uid), {
    ...updatedData,
    aadhar_proof: "",
    birthdate: "",
    profile_updated: true,
    updated_date: new Date().toLocaleString(),
    created_date: new Date().toLocaleString(),
    uid,
    has_profile_picture: false,
    mobile: auth.currentUser.phoneNumber,
    passport_proof: "",
    is_provider: false,
    is_seeker: true,
    is_active: false,
    terms_accepted_provider: false,
    terms_accepted_seeker: false,
    kyc_submitted: false,
    kyc_proof_type: "",
    election_card_proof: "",
    seeker_raised_request: false,
    licence_proof: "",
    seeker_has_pets: false,
    seeker_device_id: {
      time: new Date().toLocaleString(),
      source: "website",
    },
  });
};

export const removeUser = () => (dispatch) => {
  dispatch({ type: USER.REMOVE_USER });
};

export const deleteOrRestoreUser = async (updatedData) => {
  const auth = getAuth();
  const uid = auth.currentUser.uid;
  await updateDoc(doc(db, "seeker_details", uid), {
    ...updatedData,
  })
}
