import PropTypes from 'prop-types';
import React from 'react';
import { GradientButton } from '..';

const TopButtonsInsurance = ({ stage }) => {
  return (
    <div
      className={`grid md:grid-cols-4 gap-4 sm:grid-cols-1`}
    >
      <GradientButton
        title='GET QUOTE'
        className={`rounded-none h-10 text-sm md:text-base md:block lg:block ${stage == 1 ? 'mb:block' : 'mb:hidden'}`}
        size={'xs'}
        active={stage === 1 ? true : false}
        done={stage === 2 || stage === 3 ? true : false}
      />
      <GradientButton
        title='PET DETAILS'
        className={`rounded-none h-10 text-sm md:text-base md:block lg:block ${stage == 2 ? 'mb:block' : 'mb:hidden'}`}
        size={'xs'}
        disabled={stage === 1 ? true : false}
        active={stage === 2 ? true : false}
        done={stage === 3 ? true : false}
      />
      <GradientButton
        title='PARENT&apos;S DETAILS'
        className={`rounded-none h-10 text-sm md:text-base md:block lg:block ${stage == 3 ? 'mb:block' : 'mb:hidden'}`}
        size={'xs'}
        disabled={stage === 1 || stage === 2 ? true : false}
        active={stage === 3 ? true : false}
        done={stage === 4 ? true : false}

      />
      <GradientButton
        title='MAKE PAYMENT'
        className={`rounded-none h-10 text-sm md:text-base md:block lg:block ${stage == 4 ? 'mb:block' : 'mb:hidden'}`}
        size={'xs'}
        disabled={stage === 3 || stage === 2 || stage === 1 ? true : false}
        active={stage === 4 ? true : false}
      />
    </div>
  );
};



TopButtonsInsurance.propTypes = {
  stage: PropTypes.number,
};

export default TopButtonsInsurance;
