import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaDog, FaRegCalendarAlt } from 'react-icons/fa';
import GENDER from '../../Assets/icons/Gender.png';
import { GiWeightScale } from 'react-icons/gi';
import { MdOutlineAccountCircle } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { GradientButton } from '..';
import {
  AGE_OPTIONS,
  MONTH_OPTIONS,
  WEIGHT_OPTIONS,
} from '../../Constants/Constants';
import {
  incrementStep,
  setPetDetails,
} from '../../Redux/actions/addPetActions';
import { isEmpty } from '../../utils/utils';
import FileInput from '../FileInput';
import Input from '../Input';
import InputSelect from '../InputSelect';
import Crop from "../ImageCrop";

const EditSectionOne = ({ details }) => {
  const [formData, setFormData] = useState({
    name: details.name,
    weight: details.weight,
    age: details.age,
    breed: details.breed,
    gender: details.gender,
    image: details.image,
    month: details.months,
  });

  useEffect(() => {
    setFormData({
      image: details.image,
      weight: details.weight,
      age: details.age,
      breed: details.breed,
      gender: details.gender,
      month: details.months,
      name: details.name,
    });
  }, [details]);

  const dispatch = useDispatch();

  const [cropImage, setCropImage] = useState(formData.image);

  return (
    <div className='pb-6 w-full'>
      <h4 className='text-lg'>
        <b>Enter your pet&apos;s details</b>
      </h4>
      {/* <h5>Lorem ipsum dolor sit amet, consectetur adipiscing.</h5> */}
      <div className='lg:flex'>
        <div className='max-w-[300px] min-w-[300px] mt-4 ml-[20%] md:ml-[35%] lg:ml-[1px]'>
          <Crop
            data={formData.image}
            setFile={(file) => {setCropImage(file)}}
            uploadFile={(file) => {setFormData({ ...formData, image: file })}}
          />
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(
              setPetDetails({
                name: formData.name,
                weight: formData.weight,
                age: formData.age,
                breed: formData.breed,
                gender: formData.gender,
                image: cropImage,
                months: formData.month,
              },
                () => {
                  dispatch(incrementStep());
                }
              )
            );
          }}
        >
          <div className='grid md:grid-cols-2 xs:grid-cols-1 gap-5 mt-5 lg:w-[740px] lg:ml-[84px]'>
            <div>
              <Input
                required
                type='text'
                placeholder='Pet Name'
                Icon={MdOutlineAccountCircle}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                defaultValue={details.name}
              />
              <div className='mb-4'>
                <InputSelect
                  options={WEIGHT_OPTIONS}
                  placeholder='Weight'
                  Icon={GiWeightScale}
                  onChange={(e) => setFormData({ ...formData, weight: e.value })}
                  value={formData.weight || details.weight}
                />
              </div>

              <div className='flex'>
                <InputSelect
                  options={AGE_OPTIONS}
                  className='col-span-2'
                  placeholder='Age Year'
                  Icon={FaRegCalendarAlt}
                  value={formData.age || details.age}
                  onChange={(e) => setFormData({ ...formData, age: e.value })}
                  fullWidth
                />
                <InputSelect
                  placeholder='Months'
                  options={MONTH_OPTIONS}
                  className='w-32'
                  onChange={(e) => setFormData({ ...formData, month: e.value })}
                  value={formData.month || details.months}
                />
              </div>
            </div>
            <div>
              <Input
                required
                type='text'
                placeholder='Breed / Species'
                Icon={FaDog}
                onChange={(e) =>
                  setFormData({ ...formData, breed: e.target.value })
                }
                defaultValue={details.breed}
              />
              <InputSelect
                options={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                ]}
                placeholder='Gender'
                Icon={() => (
                  <img src={GENDER} className='h-6 w-6 object-contain' />
                )}
                onChange={(e) => setFormData({ ...formData, gender: e.value })}
                value={formData.gender || details.gender}
              />
            </div>
          </div>
          <div className='flex md:justify-end justify-center'>
            <GradientButton
              title='NEXT'
              className='mt-4'
              disabled={!isEmpty(formData)}
              type='submit'
            />
          </div>
        </form>
      </div>
    </div>
  );
};

EditSectionOne.defaultProps = {
  details: {},
};

EditSectionOne.propTypes = {
  details: PropTypes.object,
};

export default EditSectionOne;
