import 'animate.css';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AiTwotoneStar } from 'react-icons/ai';
// import WOW from 'wowjs';
import LazyImage from './LazyImage';

const ServiceCard = ({ data, size, selected }) => {
  // useEffect(() => {
  //   new WOW.WOW().init();
  // }, []);
  return (
    <div
      className={`${
        selected ? 'border-2 border-darkGreen' : 'border-2'
      } rounded-lg px-1 py-2 hover:shadow-sm ${
        size !== 'big'
          ? 'wow animate__animated animate__fadeInUp animate__delay-0.4s'
          : 'cursor-pointer'
      }`}
    >
      <div className='flex'>
        <LazyImage
          src={data?.profile_picture}
          alt='expert'
          className={`${
            size === 'big' ? 'w-20' : 'w-14'
          } aspect-square object-cover rounded-full`}
        />
        <div className='ml-2'>
          <p
            className={`${
              size === 'big' ? 'text-[18px] mt-3' : 'text-base'
            } font-semibold`}
          >
            {data?.name}
          </p>
          <div className='flex items-center'>
            <p className={`${size === 'big' ? 'text-base' : 'text-xs'}`}>
              Rating
            </p>
            {data?.expert_avg_rating
              ?
              (<AiTwotoneStar fill='#F49F02' className='mx-1' />)
              :
              null
            }
            
            <p
              className={`${
                size === 'big' ? 'text-base' : 'text-xs'
              } font-number font-medium ml-1`}
            >
              {data?.expert_avg_rating || 'No Yet Rated'}
            </p>
          </div>
        </div>
      </div>
      <div className='flex flex-wrap mt-1'>
        {data?.active_services?.map((service) => (
          <div
            key={service.service_name}
            className='border-r-1 last:border-r-0  border-gray-700'
          >
            <p className='text-xs mx-1  font-semibold'>
              {service.service_name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

ServiceCard.defaultProps = {
  data: [],
  size: 'small',
  selected: false,
};

ServiceCard.propTypes = {
  data: PropTypes.object,
  size: PropTypes.string,
  selected: PropTypes.bool,
};

export default ServiceCard;
