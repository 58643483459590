import React from 'react';
import PropTypes from 'prop-types';
import {
  BATHING_OPTIONS,
  ClientVetService,
  GROOMING_OPTIONS,
  HAIR_STYLE_OPTIONS,
  VetServicesOptions,
  TRAINING_TYPE, TRAINING_OPTIONS, jobDetailTitle
} from "../../Constants/Constants";

const JobDetails = ({ stage, offer }) => {
  // console.log("offer",offer);
  const jobDetailsData = [
    {
      title: 'Sub Total:',
      cost: `₹ ${offer.total}`,
    },
  ];

  let totalCost = ((offer.total / 100) + ((offer.total / 100) * 2) / 100) * 10

  return (
    <div>
      <div className='bg-darkGreen h-14 flex items-center rounded-t-lg'>
        <h1 className='text-white font-semibold ml-7'>Job details</h1>
      </div>
      <div className='grid grid-cols-1 border-1 border-borderColour rounded-b-lg'>
        <div className='p-7'>
          {Object.keys(offer.services).map((service, i) => (
            offer.services[service] ? (

              <div className='grid grid-cols-2 mb-4' key={i}>
                <div>
                  <h1 className='text-base'>
                    {GROOMING_OPTIONS.find(item => item.value === service)?.title}
                    {BATHING_OPTIONS.find(item => item.value === service)?.label}
                    {HAIR_STYLE_OPTIONS.find(item => item.value === service)?.label}
                    {VetServicesOptions.find(item => item.value === service)?.title}
                    {ClientVetService.find(item => item.value === service)?.title}
                    {TRAINING_OPTIONS[service]}
                    {jobDetailTitle[service]}
                  </h1>
                </div>
                <div className='flex justify-end'>
                  <h1 className='text-base font-number font-bold'>{offer.services[service]}</h1>
                </div>
              </div>
            ): null
          ))}
          {jobDetailsData.map((item, i) => (
            <div className='grid grid-cols-2 my-4' key={i}>
              <div>
                <h1 className='text-base'>{item.title}</h1>
              </div>
              <div className='flex justify-end'>
                <h1 className='text-base font-number font-bold'>{item.cost}</h1>
              </div>
            </div>
          ))}
          <div>
            <h1 className='text-base'>Payment Gateway fee 2% </h1>
          </div>
          <div className='flex justify-end mt-[-20px]'>
            <h1 className='text-base font-number font-bold'>{(offer.total / 1000) * 2}</h1>
          </div>
          {stage === 2 ? (
            <h5 className='bg-lightYellow col-span-2 mt-3 py-2 px-7 text-center rounded w-full text-sm'>
              You will have to make a down payment of 10% of the total amount to
              complete the booking
            </h5>
          ) : (<div>
            <div>
              <h1 className='text-base'>Advanced Paid</h1>
            </div>
            <div className='flex justify-end mt-[-20px]'>
              <h1 className='text-base font-number font-bold'> - ₹ {Math.round(totalCost * 100) / 100}</h1>
            </div>
          </div>)}
        </div>
        <div className='bg-lightYellow grid grid-cols-1 p-7'>
          {stage === 2 ? (
            <div className='grid grid-cols-2'>
              <div>
                <h1 className='text-base'>Payable amount for now</h1>
              </div>
              <div className='flex justify-end'>
                <h1 className='text-base font-number font-bold'>
                  ₹ {Math.round(totalCost * 100) / 100}
                </h1>
              </div>
            </div>
          ) : (
            <div className='grid grid-cols-2'>
              <div>
                <h1 className='text-base'>Payable at the end of service (excluding payment gateway charges)</h1>
              </div>
              <div className='flex justify-end'>
                <h1 className='text-base font-number font-bold'>
                  ₹ {Math.round((offer?.total - (offer.total / 10)) * 100) / 100}
                </h1>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

JobDetails.defaultProps = {
  stage: null,
};

JobDetails.propTypes = {
  stage: PropTypes.number,
  offer: PropTypes.object,
};

export default JobDetails;
