import { PATHOLOGY_TOPBUTTON } from '../types';

const initialState = {
  type: 'Tests',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PATHOLOGY_TOPBUTTON.UPDATE_TYPE:
      return { ...state, type: payload };

    default:
      return state;
  }
};
