import { getAuth } from "firebase/auth";
import { addDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import moment from "moment";
import { toast } from "react-toastify";
import { providerServicesRef, serviceRequestRef } from "../../firebase";
import geoHash from "../../utils/geohash";
import { formatGetExpiryDate } from "../../utils/utils";
import { CREATE_REQ } from "../types";
import { addRequiredDates } from "../../utils/datetime";

const addHour = (time, hours) => {
  const _date = formatGetExpiryDate(time);
  const date = moment(_date).add(hours, "hour").format("DD/MM/YYYY, hh:mm:ss");
  return date;
};

const getRequestData = (pet) => {
  const date = new Date();
  const geohash = geoHash.encode(pet.location_latitude, pet.location_longitude, "4");

  const neighbours_geohash = geoHash.getNeighbouringHash(geohash);

  return {
    created_date: formatGetExpiryDate(addHour(date, 0)),
    // updated_date: formatGetExpiryDate(addHour(date, 0)),
    testMode: true,
    accept_offer_reminder: false,
    accept_offer_expiry_date: formatGetExpiryDate(addHour(date, 60)),
    make_offer_expiry_date: formatGetExpiryDate(addHour(date, 36)),
    day_before_service_reminder: false,
    utc_offset: new Date().getTimezoneOffset(),
    // geo_location: neighbours_geohash,
    // hard_match_notitication: true,
    // hard_match_providers: [],
    make_offer_reminder: false,
    offer_acceptance_status: "awaiting_acceptance",
    status: "awaiting_offers",
    offer_received_count: 0,
    request_visibility_status: "public",
    request_open_for_offers: "accepting_offers",
    offered_provider_id: "",
    on_day_service_reminder: false,
    pet_data_obj: `/pets/${pet.docid}`,
    pet_id: pet.docid,
    pet_obj: pet,
    petphoto: pet.photo || pet?.image,
    send_soft_notificatons_date: formatGetExpiryDate(addHour(date, 0)),
  };
};

export const setCreateReq =
  (data, onComplete = () => {}) =>
  async (dispatch, getState) => {
    try {
      const {
        pet: { pets },
        auth: { seeker_object },
      } = getState();

      const selectedPet = pets.find((item) => item.docid === data.petId);
      const {
        geohash: petGeoHash,
        type,
        location_latitude,
        location_longitude,
        weight,
      } = selectedPet;

      const serviceDate = new Date(data.date);
      serviceDate.setHours(data.startTime);
      serviceDate.setMinutes(0);
      const endServiceDate = new Date(data.endDate);
      endServiceDate.setHours(data.startTime);
      endServiceDate.setMinutes(0);

      const request_datetimestamp = serviceDate.getTime();

      const user_requirement = {
        ...data.userRequirements,
        location_lat: location_latitude,
        location_long: location_longitude,
        pet_type: type,
        service: data.serviceDatas,
        service_type: data.service,
        visit_type: data.visitType,
        weight,
        optionalRequirements: data.optionalRequirements,
      };

      const serviceDates = addRequiredDates(
        serviceDate,
        data.multipleDays,
        endServiceDate
      );

      const auth = getAuth();
      const { uid } = auth.currentUser;
      const requestData = {
        user_requirement,
        seeker_doc_id: uid,
        seeker_data_obj: `/seeker_details/${uid}`,
        seeker_obj: {
          name: seeker_object.name,
          email: seeker_object.email,
          mobile: seeker_object.mobile,
          air_table_id: seeker_object.air_table_id || "",
          kyc_status: seeker_object.kyc_status || "",
          status: seeker_object.status || "",
          profile_picture: seeker_object.profile_picture || "",
        },
        ...getRequestData(selectedPet),
        // from text
        other_request: data.optionalRequirements,
        request_datetime: serviceDate,
        request_datetimestamp,
        // todo for pet sitter and boarder
        request_end_datetime: "",
        request_start_datetime: "",
        service_required_dates: serviceDates,
        //  added by sumit
        soft_match_notitication: true,
        source: "website",
      };

      if (data.reqType === "repeat") {
        requestData.hard_match_providers = data.repeatData;
        requestData.repeat_booking = true;
      }

      const docRef = await addDoc(serviceRequestRef, requestData);

      onComplete(true);
      toast.success("Request placed successfully");
      updateDoc(docRef, { docid: docRef.id, id: docRef.id });
      dispatch({
        type: CREATE_REQ.ADD_REQUEST,
        payload: { ...requestData, docid: docRef.id },
      });
    } catch (error) {
      console.log(error);
      toast.error(error.message || error);
      onComplete(false);
    }
  };
