import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import photo from '../Assets/dog.png';
import photo from '../Assets/Pawpurrfect_logo.png'
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../firebase';

const isURL = (path = '') => {
  return path.includes('https://');
};

const LazyImage = ({ src, alt, className, style }) => {
  const [image, setImage] = useState(src);
  const updateURL = async () => {
    try {
      const petPhotoRef = ref(storage, src);
      let cachedImages = JSON.parse(
        localStorage.getItem('@cachedImages') || '{}'
      );

      if (cachedImages[src]) {
        const downloadUrl = cachedImages[src];
        setImage(downloadUrl);
      } else {
        const downloadUrl = await getDownloadURL(petPhotoRef);
        setImage(downloadUrl);
        cachedImages = JSON.stringify({ ...cachedImages, src: downloadUrl });
        localStorage.setItem('@cachedImages', cachedImages);
      }
    } catch (error) {
      console.warn(error.message);
    }
  };

  useEffect(() => {
    if (!isURL(src)) {
      updateURL();
    }
  }, []);
  if (isURL(image)) {
    return (
      <img
        src={image}
        style={style}
        alt={alt}
        className={`object-contain ${className}`}
      />
    );
  }
  return (
    <img
      src={photo}
      alt={alt}
      style={style}
      className={`object-contain rounded-none ${className}`}
    />
  );
};

LazyImage.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alt: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default LazyImage;
