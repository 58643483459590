import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, GradientButton, MyRequest, ReviewModal } from "../Components";
import { MODAL } from "../Redux/types";

const MyRequestPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestId, type, visibility } = useSelector(({ modalReducer }) => modalReducer);

  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <>
      <Container className="py-8" wrapperClassName="">
        <div className="flex justify-between">
          <div className="text-lg">
            <h4>My Service Requests</h4>
          </div>
          <GradientButton
            onPress={() => navigate("/create-request")}
            className="hidden md:block"
            title="CREATE A REQUEST"
          />
        </div>
        <MyRequest showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      </Container>
      {type === "review" && (
        <ReviewModal
          modalVisibility={visibility}
          setModalVisibility={(payload) => {
            dispatch({ type: MODAL.TOGGLE_VISIBILITY, payload });
          }}
          requestId={requestId}
        />
      )}
    </>
  );
};

export default MyRequestPage;
