/* eslint-disable react/prop-types */
import React from 'react';

import { HiPlusSm, HiMinusSm } from 'react-icons/hi';

const AccordionLayout = ({
  title,
  children,
  index,
  activeIndex,
  setActiveIndex,
}) => {
  const handleSetIndex = (index) =>
    activeIndex !== index && setActiveIndex(index);

  return (
    <>
      <div
        onClick={() => handleSetIndex(index)}
        className='flex w-full p-2 mt-2 rounded cursor-pointer'
      >
        <div className='flex items-center justify-center'>
          {activeIndex === index ? (
            <HiMinusSm className='w-7 h-7' />
          ) : (
            <HiPlusSm className='w-7 h-7' />
          )}
        </div>
        <div className='flex items-center ml-4'>
          <div className='font-bold'>{title}</div>
        </div>
      </div>

      {activeIndex === index && (
        <div className='shadow-3xl rounded-2xl shadow-cyan-500/50 p-4 mb-6'>
          {children}
        </div>
      )}
    </>
  );
};

export default AccordionLayout;
