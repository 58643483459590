import React, { useEffect, useRef, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdNotifications } from "react-icons/md";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Drawer, GradientButton, Logo } from "../Components";
import { authLinks, userLinks } from "../Constants/Constants";
import { auth } from "../firebase";
import { removeUser } from "../Redux/actions/authActions";

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [menuVisibility, setMenuVisibility] = useState(false);
  const [dropdownLogout, setDropDownLogout] = useState(false);
  const [dropdownHowToBook, setDropdownHowToBook] = useState(false);
  const [dropdownFAQs, setDropdownFAQs] = useState(false);
  const { user, unreadCount } = useSelector(({ auth, notification }) => ({
    user: auth.user,
    unreadCount: notification.unreadCount,
  }));
  const dispatch = useDispatch();

  const handleLogout = () => {
    auth.signOut();
    dispatch(removeUser());
    navigate("/login");
    setDropDownLogout(false);
  };

  const useOutsideAlerter = (ref1, ref2) => {
    useEffect(() => {
      // if clicked on outside of element
      const handleClickOutside = (event) => {
        if (ref1.current && !ref1.current.contains(event.target)) {
          setDropDownLogout(false);
        }
      };
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref1, ref2]);
  };

  const wrapperRef1 = useRef(null);
  const wrapperRef2 = useRef(null);
  useOutsideAlerter(wrapperRef1, wrapperRef2);

  return (
    <>
      <div className="flex md:justify-between font-open_sans items-center border-b-1 border-grey header">
        <a
          href="https://pawpurrfect.co/"
          target="_blank"
          className="ml-auto"
          rel="noreferrer"
        >
          <Logo className="md:pr-2" />
        </a>
        <div className="ml-auto">
          <GiHamburgerMenu
            size={26}
            onClick={() => setMenuVisibility(!menuVisibility)}
            className="md:hidden mr-4 cursor-pointer"
          />
        </div>
        <div className="hidden  md:flex flex-grow justify-center">
          {user &&
            userLinks.map((option) => (
              <Link key={option.title} to={option.link} className="px-3">
                <p
                  className={` font-semibold text-sm  text-center border-primary border-l-0 border-r-0 border-t-0 border-${
                    pathname === option.link ? "2" : "0"
                  } py-1`}
                >
                  {option.title}
                </p>
              </Link>
            ))}
          {!user &&
            authLinks.map((option) => {
              {
                return option.link !== "/" ? (
                  <a
                    key={option.title}
                    href={option.link}
                    className="px-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p
                      className={`uppercase font-semibold text-sm  text-center border-primary border-l-0 border-r-0 border-t-0 border-${
                        pathname === option.link ? "2" : "0"
                      } py-1`}
                    >
                      {option.title}
                    </p>
                  </a>
                ) : (
                  <div
                    className="cursor-pointer"
                    onMouseEnter={() => {
                      if (option.title === "How to book")
                        setDropdownHowToBook(true);
                      else setDropdownFAQs(true);
                    }}
                    onMouseLeave={() => {
                      if (option.title === "How to book")
                        setDropdownHowToBook(false);
                      else setDropdownFAQs(false);
                    }}
                  >
                    <div className="px-3">
                      <p
                        className={`uppercase font-semibold text-sm text-center border-primary border-l-0 border-r-0 border-t-0 border-${
                          pathname === option.link ? "2" : "0"
                        } py-1`}
                      >
                        {option.title}
                      </p>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                      }}
                      className={`${
                        option.title === "How to book"
                          ? dropdownHowToBook
                            ? ""
                            : "hidden"
                          : dropdownFAQs
                          ? ""
                          : "hidden"
                      } bg-white divide-y divide-gray-100 shadow w-36 ml-auto mr-12`}
                    >
                      <ul className="py-1 text-sm text-gray-700">
                        {option.listElements?.map((item) => (
                          <a
                            href={item.link}
                            key={item.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <li className="block cursor-pointer text-base text-center py-2 hover:bg-gray-100 dark:hover:text-white">
                              {item.title}
                            </li>
                          </a>
                        ))}
                      </ul>
                    </div>
                  </div>
                );
              }
            })}
        </div>
        {user?.displayName ? (
          <div className="md:flex items-center">
            <div
              className="relative mr-4 cursor-pointer"
              onClick={() => {
                navigate("/notifications");
              }}
            >
              <MdNotifications size={24} color="#1EAF9C" />
              {unreadCount > 0 && (
                <div
                  className="absolute bg-primary h-4 w-4 rounded-full flex items-center justify-center "
                  style={{ top: -5, right: -5 }}
                >
                  <p
                    className="text-white text-xs font-medium"
                    style={{ marginBottom: 1 }}
                  >
                    {unreadCount}
                  </p>
                </div>
              )}
            </div>
            <div
              className="hidden md:flex items-center cursor-pointer ml-auto"
              onClick={() => setDropDownLogout(!dropdownLogout)}
              ref={wrapperRef1}
            >
              <p className="text-base font-semibold mr-2">
                Hi {user.displayName}
              </p>
              <Avatar letter={user.displayName[0]} />
            </div>
          </div>
        ) : (
          <GradientButton
            onPress={() => navigate("/login")}
            className="pl-2 ml-auto"
            title="Login/Signup"
            size="sm"
          />
        )}
      </div>
      <div
        style={{ position: "absolute", right: "0" }}
        className={`${
          dropdownLogout ? "" : "hidden"
        } bg-white divide-y divide-gray-100 rounded shadow w-28 ml-auto mr-12`}
        ref={wrapperRef2}
      >
        <ul className="py-1 text-sm text-gray-700">
          <li
            onClick={handleLogout}
            className="block cursor-pointer text-center px-4 py-2 hover:bg-gray-100 dark:hover:text-white"
          >
            Logout
          </li>
        </ul>
      </div>
      <Drawer showSidebar={menuVisibility} setShowSidebar={setMenuVisibility}>
        <div
          className={`top-0 right-0 w-[100vw] h-full bg-action px-7 fixed z-40  ease-in-out duration-300 ${
            menuVisibility ? "translate-x-0 " : "translate-x-full"
          }`}
        >
          <div className="flex flex-col mt-9">
            {user?.displayName && (
              <div className="flex items-center cursor-pointer mr-auto mb-7">
                <Avatar letter={user.displayName[0]} />
                <p className="text-base font-semibold ml-2">
                  Hi {user.displayName}
                </p>
              </div>
            )}
            {user &&
              userLinks.map((option) => (
                <Link
                  key={option.title}
                  to={option.link}
                  className={`block py-2 ${
                    window.location.pathname === option.link
                      ? "text-white"
                      : "text-dark"
                  } text-base border-b-1 border-white cursor-pointer`}
                  onClick={() => setMenuVisibility(!menuVisibility)}
                >
                  <b className="text-inherit">
                    {window.location.pathname === option.link && "|  "}
                  </b>
                  {option.title}
                </Link>
              ))}
            {!user &&
              authLinks.map((option) => {
                {
                  return option.link !== "/" ? (
                    <a
                      key={option.title}
                      href={option.link}
                      className={`block py-2 ${
                        window.location.pathname === option.link
                          ? "text-white"
                          : "text-dark"
                      } text-base border-b-1 border-white cursor-pointer`}
                      onClick={() => setMenuVisibility(!menuVisibility)}
                    >
                      <b className="text-inherit">
                        {window.location.pathname === option.link && "|  "}
                      </b>
                      {option.title}
                    </a>
                  ) : (
                    <>
                      <div
                        key={option.title}
                        className={`block py-2 ${
                          window.location.pathname === option.link
                            ? "text-white"
                            : "text-dark"
                        } text-base border-b-1 border-white cursor-pointer flex`}
                        onClick={() =>
                          option.title === "How to book"
                            ? setDropdownHowToBook(!dropdownHowToBook)
                            : setDropdownFAQs(!dropdownFAQs)
                        }
                      >
                        <b className="text-inherit">
                          {window.location.pathname === option.link && "|  "}
                        </b>
                        <div style={{ color: "inherit" }}>{option.title}</div>
                        <div className="text-lg">
                          {option.title === "How to book" ? (
                            dropdownHowToBook ? (
                              <RiArrowDropUpLine />
                            ) : (
                              <RiArrowDropDownLine />
                            )
                          ) : dropdownFAQs ? (
                            <RiArrowDropUpLine />
                          ) : (
                            <RiArrowDropDownLine />
                          )}
                        </div>
                      </div>
                      {option.listElements.map((element) => (
                        <div
                          key={element.title}
                          className={`${
                            option.title === "How to book"
                              ? dropdownHowToBook
                                ? "block"
                                : "hidden"
                              : dropdownFAQs
                              ? "block"
                              : "hidden"
                          } py-2 text-white text-sm border-b-1 border-white bg-darkGreen cursor-pointer`}
                        >
                          {element.title}
                        </div>
                      ))}
                    </>
                  );
                }
              })}
            {user && (
              <div
                className="block py-2 text-dark text-base border-b-1 border-white cursor-pointer"
                onClick={handleLogout}
              >
                Logout
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Header;
