import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { InputSelect, RadioButton } from '..';
import { WALK_OPTIONS } from '../../Constants/Constants';

const SittingOptions = ({ setData }) => {
  const [formData, setFormData] = useState({
    petWalk: false,
    walkNo: 0,
  });

  useEffect(() => {
    setData(formData);
  }, [formData]);

  return (
    <>
      <div className='grid lg:grid-cols-2 sm:grid-cols-1'>
        <div className='bg-lightYellow mt-3 p-2 rounded w-50 text-sm'>
          Note: All food and treats should be provided by the petparent
        </div>
      </div>
      <div>
        <h1>Does your pet need to be walked?</h1>
        <div className='flex gap-7 items-center mt-4'>
          <RadioButton
            name='walkSitting'
            id='walkSittingYes'
            title='YES'
            checked={formData.petWalk}
            onPress={() => setFormData({ ...formData, petWalk: true })}
          />
          <RadioButton
            name='walkSitting'
            id='walkSittingNo'
            title='NO'
            checked={formData.petWalk === false}
            onPress={() => setFormData({ ...formData, petWalk: false })}
          />
        </div>

        <div className='grid lg:grid-cols-12 sm:grid-cols-1'>
          {formData.petWalk && (
            <>
              <h1>How many times a day?</h1>
              <InputSelect
                options={WALK_OPTIONS}
                placeholder={formData.walkNo}
                value={formData.walkNo}
                onChange={(e) => setFormData({ ...formData, walkNo: e.value })}
                className='col-span-1'
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

SittingOptions.defaultProps = {
  setData: () => {},
};

SittingOptions.propTypes = {
  setData: PropTypes.func,
};

export default SittingOptions;
