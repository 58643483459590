import { NOTIFICATION } from "../types";

const initialState = {
  items: [],
  loading: true,
  unreadCount: 0,
  hasMore: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case NOTIFICATION.FETCH_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        unreadCount: getUnreadCount(payload.items),
      };
    case NOTIFICATION.FETCH_FAILED:
      return { ...state, loading: false };
    case NOTIFICATION.MARK_AS_READ:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.docid === payload) {
            return {
              ...item,
              seen: true,
            };
          }
          return item;
        }),
        unreadCount: state.unreadCount - 1,
      };

    default:
      return state;
  }
};

function getUnreadCount(arr = []) {
  let res = 0;
  for (let i = 0; i < arr.length; i++) {
    const curr = arr[i];
    if (!curr.seen) {
      res = res + 1;
    }
  }
  return res;
}
