import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { InputSelect, RadioButton } from '..';
import { WALK_OPTIONS } from '../../Constants/Constants';
import Alert from '../Alert';

const BoardingOptions = ({ setData }) => {
  const [formData, setFormData] = useState({
    pickService: false,
    drop: 'NO',
    selfFeed: false,
    petWalk: false,
    walkNo: 0,
  });

  const [modalVisibility, setModalVisibility] = useState(false);

  useEffect(() => {
    setData(formData);
  }, [formData]);

  return (
    <>
      <div className='mt-7'>
        <h1>Any Other Services You Need?</h1>
        <div className='grid lg:grid-cols-2 sm:grid-cols-1 gap-4'>
          <div className='mt-4'>
            <h1>Do you need your pet to be picked up from your home?</h1>
            <div className='flex gap-4 mt-4'>
              <RadioButton
                name='pickService'
                id='pickupYes'
                title='YES'
                checked={formData.pickService}
                onPress={() => {
                  setModalVisibility(true);
                  setFormData({ ...formData, pickService: true });
                }}
              />
              <RadioButton
                name='pickService'
                id='pickupNo'
                title='NO'
                checked={formData.pickService === false}
                onPress={() => setFormData({ ...formData, pickService: false })}
              />
            </div>
          </div>
          <div className='xl:mt-3'>
            <h1>Food + Treats will be provided by you?</h1>
            <div className='flex gap-4 mt-4'>
              <RadioButton
                name='selfFeed'
                id='foodTreatsYes'
                title='YES'
                checked={formData.selfFeed}
                onPress={() => setFormData({ ...formData, selfFeed: true })}
              />
              <RadioButton
                name='selfFeed'
                id='foodTreatsNo'
                title='NO'
                checked={formData.selfFeed === false}
                onPress={() => setFormData({ ...formData, selfFeed: false })}
              />
            </div>
          </div>
          <div>
            <h1>Do you need your pet to be droped up at your home?</h1>
            <div className='flex gap-4 mt-4'>
              <RadioButton
                name='drop'
                id='dropYes'
                title='YES'
                checked={formData.drop === 'YES'}
                onPress={() => setFormData({ ...formData, drop: 'YES' })}
              />
              <RadioButton
                name='drop'
                id='dropNo'
                title='NO'
                checked={formData.drop === 'NO'}
                onPress={() => setFormData({ ...formData, drop: 'NO' })}
              />
            </div>
          </div>
          <div>
            <h1>
              Does your pet need to be walked? If yes, how many times a day?
            </h1>
            <div className='flex gap-7 mt-4 items-center'>
              <RadioButton
                name='petWalk'
                id='walkYes'
                title='YES'
                checked={formData.petWalk}
                onPress={() => setFormData({ ...formData, petWalk: true })}
              />
              <RadioButton
                name='petWalk'
                id='walkNo'
                title='NO'
                checked={formData.petWalk === false}
                onPress={() =>
                  setFormData({ ...formData, petWalk: false, walkNo: 0 })
                }
              />
              {formData.petWalk && (
                <InputSelect
                  options={WALK_OPTIONS}
                  placeholder={formData.walkNo}
                  value={formData.petWalk}
                  onChange={(e) =>
                    setFormData({ ...formData, walkNo: e.value })
                  }
                  className='w-28'
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Alert
        visibility={modalVisibility}
        setVisibility={setModalVisibility}
        text='Pick up and drop cost will be at actual the cost of service will not include the charge of pickup and drop'
      />
    </>
  );
};

BoardingOptions.defaultProps = {
  setData: () => {},
};

BoardingOptions.propTypes = {
  setData: PropTypes.func,
};

export default BoardingOptions;
