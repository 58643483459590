import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, GradientButton, PetProfileCard } from '../Components';

const MyPetProfileScreen = () => {
  const { pets } = useSelector(({ pet }) => pet);

  const navigate = useNavigate();

  return (
    <Container className='py-8' wrapperClassName=''>
      <div className='flex items-center justify-between pb-6'>
        <h4 className='text-lg'>My Pet Profile</h4>
        <GradientButton
          onPress={() => navigate('/create-request')}
          className='hidden md:block'
          title='CREATE REQUEST'
        />
      </div>
      <div className='grid md:grid-cols-2 gap-4'>
        {pets.map((item) => (
          <PetProfileCard key={item.docid} data={item} />
        ))}
      </div>
      <div className='flex justify-center mt-7'>
        <GradientButton
          onPress={() => navigate('/create-request')}
          className='block md:hidden'
          title='CREATE REQUEST'
        />
      </div>
      <div className='flex justify-center mt-2'>
        <GradientButton onPress={() => navigate('/add-pet')} title='ADD PET' />
      </div>
    </Container>
  );
};

export default MyPetProfileScreen;
