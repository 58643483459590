import { getAuth } from "firebase/auth";
import { addDoc } from "firebase/firestore";
import { doc, updateDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db, petInsuranceRef } from "../../firebase";
import { toast } from "react-toastify";
import store from "../store";

import { imageUpload } from "../../Functions/ImageUpload";

export const addPetInsurance = (payload) => async (dispatch) => {
  const auth = getAuth();
  payload.creatorId = auth.currentUser.uid;

  const paymentDetails = {
    transactionStatus: "pending",
    startDate: Date.now(),
  };
  payload.petImage = {};

  if (payload.CertificateOfGoodHealth) {
    const CertificateOfGoodHealth = await imageUpload(
      "petInsurance",
      payload.CertificateOfGoodHealth
    );
    payload.CertificateOfGoodHealth = CertificateOfGoodHealth;
  }
  if (payload.image1) {
    const faceImage = await imageUpload("petInsurance", payload.image1);
    delete payload.image1;
    payload.petImage.faceImage = faceImage;
  }
  if (payload.image2) {
    const birthMarkImage = await imageUpload("petInsurance", payload.image2);
    delete payload.image2;
    payload.petImage.birthMarkImage = birthMarkImage;
  }
  if (payload.image3) {
    const rightFace = await imageUpload("petInsurance", payload.image3);
    delete payload.image3;
    payload.petImage.rightFace = rightFace;
  }
  if (payload.image4) {
    const leftFace = await imageUpload("petInsurance", payload.image4);
    delete payload.image4;
    payload.petImage.leftFace = leftFace;
  }

  payload.paymentDetails = paymentDetails;

  // Creating Collection in firestore
  const docRef = await addDoc(petInsuranceRef, payload);
  console.log("Document written with ID: ", docRef.id);

  payload.docID = docRef.id;

  updateDoc(docRef, { docId: docRef.id });

  dispatch({
    type: "PET_INSURANCE",
    payload,
  });
};

export const completePetInsurance = (payload) => async (dispatch) => {
  let docID = "";
  const q = query(
    collection(db, "petInsurance"),
    where("petID", "==", payload.petID),
    where("creatorId", "==", getAuth().currentUser.uid)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    //console.log(doc.id, " => ", doc.data());
    docID = doc.id;
  });

  const docRef = doc(db, "petInsurance", docID);
  updateDoc(docRef, {
    paymentDetails: {
      razorpayPaymentId: payload.razorPaymentId,
      transactionStatus: "success",
      startDate: Date.now(),
    },
  }).then((docRef) => {
    console.log("Entire Document has been updated successfully");
  });
};
