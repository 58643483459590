import PropTypes from "prop-types";
import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import Select, { components } from "react-select";

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <GiHamburgerMenu fill="grey" />
    </components.DropdownIndicator>
  );
};

const InputSelectPathology = ({
  Icon,
  placeholder,
  onChange,
  options,
  className,
  fullWidth,
  value,
  image,
  controlled,
}) => {
  return (
    <div className=" w-85 rounded-md  border-[0.443703px] border-[#ABB4BD]  hover:border-action focus:border-action flex py-0.5">
      <div className="flex-grow  text-base bg-none">
        <Select
          options={options}
          components={{ Placeholder, DropdownIndicator }}
          className={``}
          placeholder={placeholder}
          onChange={onChange}
          value={options.find((i) => i.value === value)}
          defaultValue={options.find((i) => i.value === value)}
          styles={{
            input: (provided, state) => ({
              ...provided,
              padding: 8,
            }),
            control: (base) => ({
              ...base,
              border: 0,
              boxShadow: "none",
            }),
          }}
          theme={(theme) => ({
            ...theme,
    
            colors: {
              ...theme.colors,
              primary25: "rgba(121, 209, 187,0.15)",
              primary: "#79d1bb",
            },
          })}
        />
        {Icon && (
          <div className="absolute absolute-center-x left-3">
            <Icon size={25} fill="#79d1bb" />
          </div>
        )}
      </div>
    </div>
  );
};

InputSelectPathology.defaultProps = {
  Icon: null,
  placeholder: "",
  options: [],
  onChange: () => {},
  className: "",
  fullWidth: false,
  value: "",
  image: "",
  controlled: false,
};

InputSelectPathology.propTypes = {
  Icon: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
  controlled: PropTypes.bool,
};

export default InputSelectPathology;
