import React from "react";
import PropTypes from "prop-types";

const PathologyTestCard = ({ detail, onChangeCapture, price, isChecked }) => {
  return (
    <div className="flex justify-between border-1 px-[20px]  border-[#ABB4BD] rounded-md lg:w-[513.83px] mb:w-full h-[160px]">
      <div className="lg:w-[339.19px] mb:w-[230px] h-[110px]">
        <p className="text-[14px] my-[19.98px] font-sans font-normal text-[#818181]">
          {detail}
        </p>
        <hr className="border-yellow" />
        <p className="text-darkGreen font-semibold text-[25px] font-sans mt-[20px]">
          Rs. {price}
        </p>
      </div>
      <div>
        <input
          type="checkbox"
          className="checkboxStyle2"
          defaultChecked={isChecked}
          // className='h-[34.77px] w-[34.77px] border-1 border-darkGreen accent-[white] mt-[62.62px]'
          onChangeCapture={onChangeCapture}
        />
      </div>
    </div>
  );
};

PathologyTestCard.propTypes = {
  detail: PropTypes.string,
  onChangeCapture: PropTypes.func,
  price: PropTypes.number,
  isChecked: PropTypes.bool,
};
PathologyTestCard.defaultProps = {
  isChecked: false,
};
export default PathologyTestCard;
