import React from "react";
import PropTypes from "prop-types";
import { Modal, ServiceCard, GradientButton } from "../Components";

const CancelRequestModal = ({ modalVisibility, imageURl, onPressYes, onPressNo }) => {
  return (
    <Modal visibilty={modalVisibility}>
      <div className="relative py-20 px-10 flex-auto rounded">
        <div className="flex justify-center">
          <img src={imageURl} alt="sad" srcSet="" />
        </div>
        <div className="my-4">
          <p className="text-dark text-md font-semibold leading-relaxed text-center">
            Your request is incomplete!
          </p>
          <p className="text-dark text-md leading-relaxed text-center">
            Do you want to exit?
          </p>
        </div>
        <div className="flex justify-center mt-4 gap-3">
          <GradientButton grey onPress={onPressYes} title="YES" />
          <GradientButton onPress={onPressNo} title="NO" />
        </div>
      </div>
    </Modal>
  );
};

CancelRequestModal.propTypes = {
  modalVisibility: PropTypes.bool.isRequired,
  imageURl: PropTypes.string.isRequired,
  onPressYes: PropTypes.func.isRequired,
  onPressNo: PropTypes.func.isRequired,
};

export default CancelRequestModal;
