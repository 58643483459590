import { ADD_PET } from '../types';

const initialState = {
  step: 0,
  type: '',
  name: '',
  weight: '',
  age: '',
  breed: '',
  gender: '',
  address: '',
  latitude: '',
  longitude: '',
  building: '',
  image: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_PET.ADD_TYPE:
      return {
        ...state,
        type: payload.type,
      };
    case ADD_PET.SET_IMAGE_URL:
      console.log(payload);
      return {
        ...state,
        image: payload.downloadURL,
        photo: payload.name,
      };
    case ADD_PET.ADD_DETAILS:
      return {
        ...state,
        ...payload,
      };
    case ADD_PET.CLEAR_DATA:
      return initialState;
    case ADD_PET.ADD_LOCATION:
      return {
        ...state,
        ...payload,
      };
    case ADD_PET.INCREMENT_STEP:
      return {
        ...state,
        step: state.step + 1,
      };
    case ADD_PET.DECREMENT_STEP:
      return {
        ...state,
        step: state.step - 1,
      };
    case ADD_PET.RESET_STEP:
      return {
        ...state,
        step: 0,
      };
    default:
      return state;
  }
};
