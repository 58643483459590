import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TopButtonsInsurance from "../Components/PetPageComponents/TopButtonsInsurance";
import { Container, GradientButton } from "../Components";
import {
  INSURANCE_TYPE,
  INSURANCE_OPTIONS,
  VACCINATIONS_PROVIDED,
} from "../Constants/Constants";
import FileInput from "../Components/FileInput";
//TODO: Remove Make This from image
import image1 from "../Assets/Insurance/ph_dog.png";
import image2 from "../Assets/Insurance/Mark.png";
import image3 from "../Assets/Insurance/Right.png";
import image4 from "../Assets/Insurance/Left.png";
import UserLogo from "../Assets/Insurance/UserLogo.png";
import { PetProfileCard } from "../Components";
import { selectedPetForInsurance } from "./CreateRequestPage";
import Rectangle from "../Assets/Insurance/Rectangle.png";
import { PayWithRazorPay } from "../Components/RequestPageComponents/..";
import { setCreateReq } from "../Redux/actions/createReqAction";
import {
  addPetInsurance,
  completePetInsurance,
} from "../Redux/actions/petInsuranceActions";

function PetInsurance() {
  const dispatch = useDispatch();
  const { pets } = useSelector(({ pet }) => pet);
  const selectedPet = pets.find((item) => item.docid === selectedPetForInsurance);
  const navigate = useNavigate();

  const [stage, setStage] = useState(1);

  const coverAmountOption = [
    { value: 10000, label: "10,000" },
    { value: 20000, label: "20,000" },
    { value: 30000, label: "30,000" },
    { value: 40000, label: "40,000" },
    { value: 50000, label: "50,000" },
  ];
  const [totalCoverAmount, setTotalCoverAmount] = useState(coverAmountOption[0].value);
  const [additionalService, setAdditionalService] = useState({
    Additional1: "Yes",
    Additional2: "Yes",
    Additional3: "Yes",
    Additional4: "Yes",
  });

  const [formData, setFormData] = useState({
    DistinguishingFeature: "",
    TagNo: "",
    IdentificationMark: "",
    PetValue: "",
    healthy: "Yes",
    commercial_purpose: "Yes",
    CertificateOfGoodHealth: "",
    PreExisting_disease: "Yes",
    already_insured: "Insured",
    NameOfTheInsuranceCompany: "",
    PolicyNumber: "",
    vaccinationsProvided: {
      Rabies: false,
      Leptospirosis: false,
      Distemper: false,
      Parainfluenza: false,
      Hepatitis: false,
      Parvovirus: false,
      Adeno_virus: false,
      Corona: false,
    },
    image1: "",
    image2: "",
    image3: "",
    image4: "",
    ownAnyOtherDog: "Yes",
    VeterinaryDoctorForYourPet: "Yes",
    LostAnyPet: "Yes",
    FreeFromVice: "Yes",
  });

  const [disclaimer, setDisclaimer] = useState({
    first: false,
    second: false,
    third: false,
  });

  const [vaccinationsProvidedList, setVaccinationsProvidedList] = useState({
    Rabies: false,
    Leptospirosis: false,
    Distemper: false,
    Parainfluenza: false,
    Hepatitis: false,
    Parvovirus: false,
    Adeno_virus: false,
    Corona: false,
  });

  const [parentData, setParentData] = useState({
    parentName: "",
    MobileNo: "",
    EmailId: "",
    Gender: "",
    Address1: "",
    Address2: "",
    Address3: "",
    City: "",
    PinCode: null,
    PanCardNumber: "",
    Occupation: "",
    MaritalStatus: "",
  });

  const handleBackButton = () => {
    {
      stage > 1 ? setStage(stage - 1) : navigate(-1);
    }
  };

  const handleInput = (e) => {
    let variable = e.target.name;
    let value = e.target.value;

    setParentData({ ...parentData, [variable]: value });
  };

  const handlePetInput = (e) => {
    let variable = e.target.name;
    let value = e.target.value;

    setFormData({ ...formData, [variable]: value });
  };

  const removeDuplicateFromService = () => {
    let chars = formData.vaccinationsProvided;

    let uniqueChars = [];
    let flag2 = 0;
    let uniqueArray = [];

    for (let i = 0; i < chars.length; i++) {
      for (let j = 0; j < chars.length; j++) {
        if (chars[i] === chars[j]) {
          flag2 = flag2 + 1;
        }
      }
      if (flag2 % 2 !== 0) {
        uniqueChars.push(chars[i]);
      }
      flag2 = 0;
    }

    for (let i = 0; i < uniqueChars.length; i++) {
      if (!uniqueArray.includes(uniqueChars[i])) {
        uniqueArray.push(uniqueChars[i]);
      }
    }

    setFormData({ ...formData, vaccinationsProvided: uniqueArray });
  };

  const handleCreateRequest = () => {
    const serviceData = {
      petId: selectedPetForInsurance,
      service: "Insurance",
      //   userRequirements: formData.userRequirements,
      //   serviceDatas: formData.serviceDatas,
      //   optionalRequirements: formData.optionalRequirements,
      //   visitType,
    };

    const handleNewRequest = () => {
      //   serviceData.reqType = 'new';
      //   serviceData.repeatData = null;
      //   dispatch(
      //     setCreateReq(serviceData, (success) => {
      //       if (success) {
      navigate("/congrats", {
        state: { service: "Insurance" },
      });
      // setFormData(initialState);
      //   }
      //     })
      //   );
    };

    // eslint-disable-next-line no-unused-vars
    // const next = () => {
    //   serviceData.reqType = 'repeat';
    //   serviceData.repeatData = [repeatData];
    //   dispatch(
    //     setCreateReq(serviceData, (success) => {
    //       if (success) {
    //         navigate('/congrats', {
    //           state: { service: SERVICES_TEXT[formData.selectedService] },
    //         });
    //         setFormData(initialState);
    //         setStartTime();
    //         setEndTime('');
    //       }
    //     })
    //   );
    // };

    // if (previousExperts.length > 0) setSubmitmodalVisibility(true);
    // else handleNewRequest();

    handleNewRequest();
    // return {

    //   handleNewRequest: handleNewRequest,
    // //   next: next,
    // };
  };

  const handleNextButton = () => {
    // if(stage == 4){
    //     return;
    // }
    setStage(stage + 1);

    stage === 2 && removeDuplicateFromService();
  };

  const [Insurance_Premium, SetInsurance_Premium] = useState(
    INSURANCE_OPTIONS[0].premiun
  );

  let Insurance_PremiumGST = (Insurance_Premium / 100) * 18;
  const [AddOns, setAddOns] = useState(127);

  const GatewayFees = ((Insurance_Premium + Insurance_PremiumGST) / 100) * 2;

  useEffect(() => {
    setAddOns(Insurance_Premium - 361);
  }, [
    additionalService.Additional1,
    additionalService.Additional2,
    additionalService.Additional3,
    additionalService.Additional4,
  ]);

  let Final_Premium =
    Insurance_Premium + Insurance_PremiumGST + GatewayFees + Number(totalCoverAmount);

  const submitBuyInsurance = () => {
    dispatch(
      addPetInsurance({
        petDetails: selectedPet,
        totalCoverAmount: Insurance_Premium,
        additionalService: additionalService,
        ...formData,
        petVaccination: vaccinationsProvidedList,
        ownerDetails: parentData,
        petID: selectedPet?.id,
      })
    );
  };

  const onCompletionOfPayment = (res) => {
    dispatch(
      completePetInsurance({
        razorPaymentId: res,
        transactionStatus: "success",
        petID: selectedPet?.id,
      })
    );
    navigate("/");
  };

  return (
    <Container>
      <div className="py-4">
        <TopButtonsInsurance stage={stage} />

        {/* Show pet selector Here */}

        {(stage === 1 || stage === 4) && (
          <>
            {stage === 1 && (
              <>
                <PetProfileCard data={selectedPet} className="mt-4" noEdit />
                <h4 className="text-lg mt-5">
                  <b>Base Plan + Add Ons</b>
                </h4>
              </>
            )}

            <div className="lg:flex mt-8">
              <div className="lg:w-[60%] mb-4">
                {stage === 1 ? (
                  <div className="border-1 border-[#AAAAAA] rounded-md py-[14.83px] px-[20.40px]">
                    <p>Sum Insured</p>
                    <div className="flex flex-wrap justify-between">
                      <p className="bg-lightYellow mt-1 p-2 rounded w-[300px] lg:text-sm mb:text-[12px]">
                        The total cover amount provided to your dog
                      </p>
                      {/* put the price for Insurance in Constants.js file */}
                      <select
                        className="border-1 border-[#ABB4BD] px-4"
                        name="languages"
                        id="lang"
                        onChange={(e) => {
                          setTotalCoverAmount(e.target.value);
                        }}
                      >
                        {coverAmountOption.map((item, index) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="border-[4.17px] border-darkGreen lg:w-[346.06px] mb:w-full h-[150.1px] rounded-md">
                    <img
                      src={INSURANCE_OPTIONS[0].image}
                      className="w-[244.61px] h-[91.73px] my-[29.19px] mx-[50.73px]"
                    />
                  </div>
                )}

                <div className="border-1 border-gray-400 rounded-md p-4 mt-5">
                  <h4 className="font-bold">Additional Add-ons you can choose from:</h4>
                  <div className="mt-4">
                    {INSURANCE_TYPE.map(({ title, price, value }) => (
                      <div key={value} className="mt-2">
                        <label className="flex justify-between">
                          <span className="flex">
                            <input
                              type="checkbox"
                              className="checkboxStyle mr-2"
                              name={value}
                              // defaultChecked
                              checked={
                                price === 10
                                  ? additionalService.Additional1 === "Yes"
                                    ? true
                                    : false
                                  : price === 65
                                  ? additionalService.Additional2 === "Yes"
                                    ? true
                                    : false
                                  : price === 20
                                  ? additionalService.Additional3 === "Yes"
                                    ? true
                                    : false
                                  : price === 32
                                  ? additionalService.Additional4 === "Yes"
                                    ? true
                                    : false
                                  : null
                              }
                              onChangeCapture={() => {
                                {
                                  stage === 1
                                    ? title === "Third Party Liability Cover"
                                      ? additionalService.Additional1 === "Yes"
                                        ? (setAdditionalService({
                                            ...additionalService,
                                            Additional1: "No",
                                          }),
                                          SetInsurance_Premium(Insurance_Premium - 10))
                                        : (setAdditionalService({
                                            ...additionalService,
                                            Additional1: "Yes",
                                          }),
                                          SetInsurance_Premium(Insurance_Premium + 10))
                                      : title === "Lost and Stolen Cover"
                                      ? additionalService.Additional2 === "Yes"
                                        ? (setAdditionalService({
                                            ...additionalService,
                                            Additional2: "No",
                                          }),
                                          SetInsurance_Premium(Insurance_Premium - 65))
                                        : (setAdditionalService({
                                            ...additionalService,
                                            Additional2: "Yes",
                                          }),
                                          SetInsurance_Premium(Insurance_Premium + 65))
                                      : title === "Emergency Pet Minding"
                                      ? additionalService.Additional3 === "Yes"
                                        ? (SetInsurance_Premium(Insurance_Premium - 20),
                                          setAdditionalService({
                                            ...additionalService,
                                            Additional3: "No",
                                          }))
                                        : (setAdditionalService({
                                            ...additionalService,
                                            Additional3: "Yes",
                                          }),
                                          SetInsurance_Premium(Insurance_Premium + 20))
                                      : title === "Vet Consultation & Doctor on Call" &&
                                        (additionalService.Additional4 === "Yes"
                                          ? (setAdditionalService({
                                              ...additionalService,
                                              Additional4: "No",
                                            }),
                                            SetInsurance_Premium(Insurance_Premium - 32))
                                          : (setAdditionalService({
                                              ...additionalService,
                                              Additional4: "Yes",
                                            }),
                                            SetInsurance_Premium(Insurance_Premium + 32)))
                                    : null;
                                }
                              }}
                            />
                            <p>{title}</p>
                          </span>
                          <p className="font-semibold">Rs.{price}</p>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="border-1 border-gray-400 border-t-0 rounded-md lg:w-[38%] ml-0 lg:ml-5 pt-0">
                <p className="bg-[#f49f02] p-2 rounded-md text-sm mt-0 text-center">
                  Your Quote No. # ##############
                </p>
                <div className="m-4">
                  <span className="flex font-semibold justify-between">
                    <p>Plan Details</p>
                    <p>Amount</p>
                  </span>
                  <span className="flex justify-between">
                    <p>
                      {selectedPet.name} , {selectedPet.breed}
                    </p>
                    <p>Rs. {totalCoverAmount}</p>
                  </span>
                  {/* { (additionalService.Additional1 === 'Yes' || additionalService.Additional2 === 'Yes' || additionalService.Additional3 === 'Yes' || additionalService.Additional4 === 'Yes') && */}
                  <span className="flex mt-4 font-semibold justify-between">
                    <p>Add-Ons</p>
                    <p>Rs. {AddOns}</p>
                  </span>
                  {/* } */}
                  <span className="flex mt-4 font-semibold justify-between">
                    <p>Total Premium</p>
                    <p>Rs. {Insurance_Premium}</p>
                  </span>
                  <span className="flex font-semibold justify-between">
                    <p>Premium without Service Tax</p>
                    <p>Rs. {Insurance_Premium}</p>
                  </span>
                  <hr className="my-4" />
                  <span className="flex font-semibold justify-between">
                    <p>Gross Total</p>
                    <p>Rs.{Insurance_Premium}</p>
                  </span>
                  <span className="flex justify-between">
                    <p>GST @ 18%</p>
                    <p>Rs. {Insurance_PremiumGST}</p>
                  </span>
                  <span className="flex justify-between">
                    <p>Payment Gateway fees 2%</p>
                    <p>Rs. {GatewayFees.toFixed(2)}</p>
                  </span>
                  <hr className="my-4" />
                  <span className="flex font-semibold justify-between">
                    <p>Final Premium</p>
                    <p>Rs. {Final_Premium.toFixed(2)}</p>
                  </span>
                  <p className="bg-lightYellow mt-5 p-2 rounded text-sm text-center">
                    The total amount you pay (incl. GST) while buying the policy
                  </p>
                </div>
              </div>
            </div>
          </>
        )}

        {stage === 2 && (
          <>
            <h4 className="text-lg mt-[35.03px] mb-[21.4px]">
              <b>Enter your pet&apos;s details</b>
            </h4>

            <PetProfileCard data={selectedPet} className="mt-4" noEdit />

            <form action="" method="POST">
              <div className="flex flex-wrap lg:justify-between mb:justify-center">
                <div className="my-4 w-full lg:w-[450px]">
                  <p>Distinguishing Feature</p>
                  <input
                    className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
                    type="text"
                    name="DistinguishingFeature"
                    value={formData.DistinguishingFeature}
                    onChange={handlePetInput}
                  />
                </div>
                <div className="my-4 w-full lg:w-[450px]">
                  <p>Tag No.</p>
                  <input
                    className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
                    type="number"
                    name="TagNo"
                    value={formData.TagNo}
                    onChange={handlePetInput}
                  />
                </div>
                <div className="my-4 w-full lg:w-[450px]">
                  <p>Identification Mark - Eg : Mole on the left Eye</p>
                  <input
                    className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
                    type="text"
                    name="IdentificationMark"
                    value={formData.IdentificationMark}
                    onChange={handlePetInput}
                  />
                </div>
                <div className="my-4 w-full lg:w-[450px]">
                  <p>Pet Value</p>
                  <input
                    className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
                    type="text"
                    name="PetValue"
                    value={formData.PetValue}
                    onChange={handlePetInput}
                  />
                </div>
              </div>

              <hr className="w-[80%] ml-[10%] my-4" />

              <div className="flex flex-wrap lg:justify-between mb:justify-center">
                <div className="border-1 border-[#1eaf9c] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
                  <div>
                    <p className="font-semibold font-roboto text-[18px]">
                      Is your pet dog(s) sound and healthy?
                    </p>
                    <div className="flex mt-3 gap-7">
                      <div>
                        <input
                          type="radio"
                          name="healthy"
                          className="radioStyle"
                          value={formData.healthy}
                          onChange={() => {
                            setFormData({ ...formData, healthy: "Yes" });
                          }}
                          defaultChecked
                        />{" "}
                        Yes
                      </div>
                      <div>
                        <input
                          type="radio"
                          value={formData.healthy}
                          name="healthy"
                          className="radioStyle"
                          onChange={() => {
                            setFormData({ ...formData, healthy: "No" });
                          }}
                        />{" "}
                        No
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-1 border-[#1eaf9c] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
                  <div>
                    <p className="font-semibold font-roboto text-[18px]">
                      Is the dog&apos;s used for commercial purposes?
                    </p>
                    <div className="flex mt-3 gap-7">
                      <div>
                        <input
                          type="radio"
                          value="Yes"
                          className="radioStyle"
                          name="commercial_purpose"
                          defaultChecked
                          onChange={() => {
                            setFormData({ ...formData, commercial_purpose: "Yes" });
                          }}
                        />{" "}
                        Yes
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="No"
                          className="radioStyle"
                          name="commercial_purpose"
                          onChange={() => {
                            setFormData({ ...formData, commercial_purpose: "No" });
                          }}
                        />{" "}
                        No
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-1 border-[#1eaf9c] max-h-[125px] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
                  <div>
                    <p className="font-semibold font-roboto text-[18px]">
                      Any Pre-existing disease/conditions Pet Dog(s) suffers from?
                    </p>
                    <div className="flex mt-3 gap-7">
                      <div>
                        <input
                          type="radio"
                          value="Yes"
                          className="radioStyle"
                          name="PreExisting_disease"
                          defaultChecked
                          onChange={() => {
                            setFormData({ ...formData, PreExisting_disease: "Yes" });
                          }}
                        />{" "}
                        Yes
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="No"
                          className="radioStyle"
                          name="PreExisting_disease"
                          onChange={() => {
                            setFormData({ ...formData, PreExisting_disease: "No" });
                          }}
                        />{" "}
                        No
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-1 border-[#1eaf9c] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
                  <div>
                    <p className="font-semibold font-roboto text-[18px]">
                      Is the dog&apos;s already insured elsewhere?
                    </p>
                    <div className="flex mt-3 gap-7">
                      <div>
                        <input
                          type="radio"
                          value="Insured"
                          className="radioStyle"
                          name="already_insured"
                          defaultChecked
                          onChange={() => {
                            setFormData({ ...formData, already_insured: "Insured" });
                          }}
                        />{" "}
                        Insured
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="Not_Insured "
                          className="radioStyle"
                          name="already_insured"
                          onChange={() => {
                            setFormData({ ...formData, already_insured: "Not Insured" });
                          }}
                        />{" "}
                        Not Insured
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="Insured_previousl"
                          className="radioStyle"
                          name="already_insured"
                          onChange={() => {
                            setFormData({
                              ...formData,
                              already_insured: "Insured previously",
                            });
                          }}
                        />{" "}
                        Insured previously
                      </div>
                    </div>
                    {/* Implement logic for "if else" condition showing the input part   */}
                    {formData.already_insured !== "Not Insured" && (
                      <>
                        <input
                          type="text"
                          className="border-1 border-gray-500 w-[100%] px-3 py-1 my-4 rounded-md"
                          placeholder="Name of the Insurance Company"
                          name="NameOfTheInsuranceCompany"
                          onChange={handlePetInput}
                        />
                        <input
                          type="number"
                          className="border-1 border-gray-500 w-[100%] px-3 py-1 my-2 rounded-md"
                          placeholder="Policy number"
                          name="PolicyNumber"
                          onChange={handlePetInput}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div
                  className={`border-1 border-[#1eaf9c] my-4 rounded-md w-full lg:w-[450px] lg:mt-[-80px] ${
                    formData.already_insured !== "Not Insured"
                      ? "lg:mt-[-80px]"
                      : "lg:mt-[15px]"
                  } px-2 py-5`}
                >
                  <div>
                    <p className="font-semibold font-roboto text-[18px]">
                      Please select names of the vaccinations provided to your dog(s){" "}
                    </p>
                    <div className="grid grid-cols-2 gap-y-6 mt-3 justify-between">
                      {VACCINATIONS_PROVIDED.map((item, i) => (
                        <div className="flex w-[80%]" key={i}>
                          <input
                            className="checkboxStyle mr-2"
                            type="checkbox"
                            value=""
                            name="vaccinationsProvided"
                            defaultChecked={vaccinationsProvidedList[item.value]}
                            onChangeCapture={() => {
                              setVaccinationsProvidedList({
                                ...vaccinationsProvidedList,
                                [item.value]: !vaccinationsProvidedList[item.value],
                              });
                            }}
                          />{" "}
                          {item.title}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="border-1 border-[#1eaf9c] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
                  <div>
                    <p className="font-semibold font-roboto text-[18px]">
                      Upload a certificate of good health issued by a qualified veterinary
                      practitioner for the Pet Dog proposed for insurance. (Optional) (It
                      can be doc/pdf format)
                    </p>
                    <div className="flex mt-3 gap-7 border-1 border-yellow">
                      <input
                        type="file"
                        placeholder="Browse Files (File should be less than 5MB)"
                        name="CertificateOfGoodHealth"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            CertificateOfGoodHealth: e.target.files[0],
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr className="w-[80%] ml-[10%] my-4" />

              {/* Implement the Upload photograph part Here */}
              <div className="flex mb:justify-center">
                <div className="border-1 border-[#1eaf9c] rounded-md p-4 my-4 w-full ">
                  <p className="font-semibold font-roboto text-[18px]">
                    Upload a photograph showing the following angles. (It can be
                    jpeg/png/pdf format)
                  </p>
                  <p className="bg-lightYellow mt-1 p-2 rounded w-[300px] text-sm mt-4">
                    Each file should be less than 5MB
                  </p>
                  <div className="flex flex-wrap mb:ml-[15%] lg:ml-1 justify-between mt-4">
                    <div>
                      <FileInput
                        file={formData.image1 ? formData.image1 : image1}
                        placeholder="Upload Face Photo"
                        name="image1"
                        onChange={(e) => {
                          setFormData({ ...formData, image1: e.target.files[0] });
                        }}
                      />
                    </div>
                    <div>
                      <FileInput
                        file={formData.image2 ? formData.image2 : image2}
                        placeholder="Upload Birth mark (if any) (Optional)"
                        name="image2"
                        onChange={(e) =>
                          setFormData({ ...formData, image2: e.target.files[0] })
                        }
                      />
                    </div>
                    <div>
                      <FileInput
                        file={formData.image3 ? formData.image3 : image3}
                        placeholder="Upload Right Face Profile"
                        name="image3"
                        onChange={(e) =>
                          setFormData({ ...formData, image3: e.target.files[0] })
                        }
                      />
                    </div>
                    <div>
                      <FileInput
                        file={formData.image4 ? formData.image4 : image4}
                        placeholder="Upload Left Face Profile"
                        name="image4"
                        onChange={(e) =>
                          setFormData({ ...formData, image4: e.target.files[0] })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <hr className="w-[80%] ml-[10%] my-4" />

              <div className="flex flex-wrap lg:justify-between mb:justify-center">
                <div className="border-1 border-[#1eaf9c] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
                  <div>
                    <p className="font-semibold font-roboto text-[18px]">
                      {" "}
                      Do you own any other dog apart from the one you are insuring?{" "}
                    </p>
                    <div className="flex mt-3 gap-7">
                      <div>
                        <input
                          type="radio"
                          value="Yes"
                          className="radioStyle"
                          name="ownAnyOtherDog"
                          defaultChecked
                          onChange={() => {
                            setFormData({ ...formData, ownAnyOtherDog: "Yes" });
                          }}
                        />{" "}
                        Yes
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="No"
                          className="radioStyle"
                          name="ownAnyOtherDog"
                          onChange={() => {
                            setFormData({ ...formData, ownAnyOtherDog: "No" });
                          }}
                        />{" "}
                        No
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-1 border-[#1eaf9c] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
                  <div>
                    <p className="font-semibold font-roboto text-[18px]">
                      {" "}
                      Do you have access to a Veterinary Doctor for your Pet Dog(s){" "}
                    </p>
                    <div className="flex mt-3 gap-7">
                      <div>
                        <input
                          type="radio"
                          value="Yes"
                          className="radioStyle"
                          name="VeterinaryDoctorForYourPet"
                          defaultChecked
                          onChange={() => {
                            setFormData({
                              ...formData,
                              VeterinaryDoctorForYourPet: "Yes",
                            });
                          }}
                        />{" "}
                        Yes
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="No"
                          className="radioStyle"
                          name="VeterinaryDoctorForYourPet"
                          onChange={() => {
                            setFormData({
                              ...formData,
                              VeterinaryDoctorForYourPet: "No",
                            });
                          }}
                        />{" "}
                        No
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-1 border-[#1eaf9c] max-h-[125px] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
                  <div>
                    <p className="font-semibold font-roboto text-[18px]">
                      Have you lost any Pet Dog/s during the last three years?
                    </p>
                    <div className="flex mt-3 gap-7">
                      <div>
                        <input
                          type="radio"
                          value="Yes"
                          className="radioStyle"
                          defaultChecked
                          name="LostAnyPet"
                          onChange={() => {
                            setFormData({ ...formData, LostAnyPet: "Yes" });
                          }}
                        />{" "}
                        Yes
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="No"
                          className="radioStyle"
                          name="LostAnyPet"
                          onChange={() => {
                            setFormData({ ...formData, LostAnyPet: "No" });
                          }}
                        />{" "}
                        No
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-1 border-[#1eaf9c] max-h-[125px] my-4 rounded-md w-full lg:w-[450px] px-2 py-5">
                  <div>
                    <p className="font-semibold font-roboto text-[18px]">
                      {" "}
                      Is the dog’s house sound, healthy and free from vice?{" "}
                    </p>
                    <div className="flex mt-3 gap-7">
                      <div>
                        <input
                          type="radio"
                          value="Yes"
                          className="radioStyle"
                          name="FreeFromVice"
                          defaultChecked
                          onChange={() => {
                            setFormData({ ...formData, FreeFromVice: "Yes" });
                          }}
                        />{" "}
                        Yes
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="No"
                          className="radioStyle"
                          name="FreeFromVice"
                          onChange={() => {
                            setFormData({ ...formData, FreeFromVice: "No" });
                          }}
                        />{" "}
                        No
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}

        {stage === 3 && (
          <>
            <h4 className="text-lg mt-5">
              <b>Enter Pet Parent&apos;s details</b>
            </h4>
            <div className="mt-5 mb:ml-3 lg:ml-0">
              <form
                action="https://fglpg001.futuregenerali.in/CoreApi/api/SaveDetails/AddProposer"
                method="POST"
                className="flex flex-wrap justify-between"
              >
                <div className="flex flex-col w-full lg:w-[450px]">
                  <label htmlFor="parentName">Parent&apos;s Name</label>
                  <input
                    value={parentData.parentName}
                    onChange={handleInput}
                    type="text"
                    name="parentName"
                    className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
                    required
                  />
                </div>

                <div className="flex flex-col my-4">
                  <label htmlFor="MobileNo">Mobile Number</label>
                  <div className="flex w-full lg:w-[450px]">
                    <input
                      value="+91"
                      type="text"
                      className="border-1 border-[#1eaf9c] rounded-l-md font-roboto mt-2 p-4 py-2 max-w-[20%] lg:w-[60px] "
                      disabled
                    />
                    <input
                      value={parentData.MobileNo}
                      onChange={handleInput}
                      type="number"
                      name="MobileNo"
                      className="border-1 border-[#1eaf9c] rounded-r-md font-roboto mt-2 p-4 py-2 w-[80%] lg:w-[390px] "
                      required
                    />
                  </div>
                </div>

                <div className="flex flex-col my-4 w-full lg:w-[450px]">
                  <label htmlFor="EmailId">Email ID</label>
                  <input
                    value={parentData.EmailId}
                    onChange={handleInput}
                    type="email"
                    name="EmailId"
                    className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px] "
                    required
                  />
                </div>

                <div className="flex flex-col my-4 w-full lg:w-[450px]">
                  <label htmlFor="Gender">Gender</label>
                  <input
                    value={parentData.Gender}
                    onChange={handleInput}
                    type="text"
                    name="Gender"
                    className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px] "
                    required
                  />
                </div>

                <div className="flex flex-col my-4 w-full lg:w-[450px]">
                  <label htmlFor="Address1">Address 1</label>
                  <input
                    value={parentData.Address1}
                    onChange={handleInput}
                    type="text"
                    name="Address1"
                    className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px] "
                    required
                  />
                </div>

                <div className="flex flex-col my-4 w-full lg:w-[450px]">
                  <label htmlFor="Address2">Address 2 (Optional)</label>
                  <input
                    value={parentData.Address2}
                    onChange={handleInput}
                    type="text"
                    name="Address2"
                    className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px] "
                  />
                </div>

                <div className="flex flex-col my-4 w-full lg:w-[450px]">
                  <label htmlFor="Address3">Address 3 (Optional)</label>
                  <input
                    value={parentData.Address3}
                    onChange={handleInput}
                    type="text"
                    name="Address3"
                    className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px] "
                  />
                </div>

                <div className="flex flex-col my-4 w-full lg:w-[450px]">
                  <label htmlFor="City">City</label>
                  <input
                    value={parentData.City}
                    onChange={handleInput}
                    type="text"
                    name="City"
                    className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px] "
                    required
                  />
                </div>

                <div className="flex flex-col my-4 w-full lg:w-[450px]">
                  <label htmlFor="PinCode">Pin Code</label>
                  <input
                    value={parentData.PinCode}
                    onChange={handleInput}
                    type="number"
                    name="PinCode"
                    className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px] "
                    required
                  />
                </div>

                <div className="flex flex-col my-4 w-full lg:w-[450px]">
                  <label htmlFor="PanCardNumber">PAN Card Number</label>
                  <input
                    value={parentData.PanCardNumber}
                    onChange={handleInput}
                    type="text"
                    name="PanCardNumber"
                    className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
                    required
                  />
                </div>

                <div className="flex flex-col my-4 w-full lg:w-[450px]">
                  <label htmlFor="Occupation">Occupation</label>
                  <input
                    value={parentData.Occupation}
                    onChange={handleInput}
                    type="text"
                    name="Occupation"
                    className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
                    required
                  />
                </div>

                <div className="flex flex-col my-4 w-full lg:w-[450px]">
                  <label htmlFor="MaritalStatus">Marital Status</label>
                  <input
                    value={parentData.MaritalStatus}
                    onChange={handleInput}
                    type="text"
                    name="MaritalStatus"
                    className="border-1 border-[#1eaf9c] mt-2 rounded-md p-4 py-2 w-full lg:w-[450px]"
                    // onChange={(e) => {
                    //     console.log(e.target.value)
                    // }}
                    required
                  />
                </div>
              </form>
            </div>
          </>
        )}

        {stage === 4 && (
          <div className="overflow-hidden">
            <hr className="w-[80%] ml-[10%] my-4" />
            <h4 className="text-lg mt-5">
              <b>Verify Parent&apos;s details</b>
            </h4>
            <div className="flex lg:flex-nowrap mb:flex-wrap border-1 border-gray rounded-md py-4 pl-8">
              <div className="min-w-[160px] h-[156px] bg-[#FFCE76]">
                <img src={UserLogo} className="ml-8 mt-6" />
              </div>
              <div className="flex lg:flex-row mb:flex-col mb:gap-y-3 lg:gap-y-0 flex-wrap gap-x-2 lg:ml-12 mb:ml-0">
                <div className="flex lg:flex-col max-w-[240px] min-w-[240px]">
                  <p className="text-darkGreen font-sans font-bold text-[15px] mb:mr-4">
                    Name
                  </p>
                  <p className="text-[20px] font-sans break-words">
                    {parentData.parentName}
                  </p>
                </div>
                <div className="flex lg:flex-col max-w-[270px] min-w-[270px]">
                  <p className="text-darkGreen font-sans font-bold text-[15px] mb:mr-4">
                    Email
                  </p>
                  <p className="text-[20px] font-sans break-words">
                    {parentData.EmailId}
                  </p>
                </div>
                <div className="flex lg:flex-col lg:max-w-[170px] lg:min-w-[170px] mb:max-w-[220px] mb:min-w-[220px]">
                  <p className="text-darkGreen font-sans font-bold text-[15px] mb:mr-4">
                    Mobile No.
                  </p>
                  <p className="text-[20px] font-sans break-words">
                    {parentData.MobileNo}
                  </p>
                </div>
                <div className="flex lg:flex-col max-w-[150px] min-w-[150px]">
                  <p className="text-darkGreen font-sans font-bold text-[15px] mb:mr-4">
                    Gender
                  </p>
                  <p className="text-[20px] font-sans break-words">{parentData.Gender}</p>
                </div>
                <div className="flex lg:flex-col max-w-[240px] min-w-[240px]">
                  <p className="text-darkGreen font-sans font-bold text-[15px] mb:mr-4">
                    City
                  </p>
                  <p className="text-[20px] font-sans break-words">{parentData.City}</p>
                </div>
                <div className="flex lg:flex-col max-w-[270px] min-w-[270px]">
                  <p className="text-darkGreen font-sans font-bold text-[15px] mb:mr-4">
                    Occupation
                  </p>
                  <p className="text-[20px] font-sans break-words">
                    {parentData.Occupation}
                  </p>
                </div>
                <div className="flex lg:flex-col lg:max-w-[170px] lg:min-w-[170px] mb:max-w-[220px] mb:min-w-[220px]">
                  <p className="text-darkGreen font-sans font-bold text-[15px]  mb:mr-4">
                    Marital Status
                  </p>
                  <p className="text-[20px] font-sans break-words">
                    {parentData.MaritalStatus}
                  </p>
                </div>
                <div className="flex lg:flex-col max-w-[150px] min-w-[150px]">
                  <p className="text-darkGreen font-sans font-bold text-[15px] mb:mr-4">
                    Pan Card
                  </p>
                  <p className="text-[20px] font-sans">{parentData.PanCardNumber}</p>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap justify-between mt-4">
              <div className="w-[486px] mt-4">
                <p className="font-sans text-[20px] ">Address 1</p>
                <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
                  {parentData.Address1}
                </p>
              </div>

              {parentData.Address2 !== "" && (
                <div className="w-[486px] mt-4">
                  <p className="font-sans text-[20px] ">Address 2 (Optional)</p>
                  <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
                    {parentData.Address2}
                  </p>
                </div>
              )}

              {parentData.Address3 !== "" && (
                <div className="w-[486px] mt-4">
                  <p className="font-sans text-[20px] ">Address 3 (Optional)</p>
                  <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
                    {parentData.Address3}
                  </p>
                </div>
              )}

              <div className="w-[486px] mt-4">
                <p className="font-sans text-[20px] ">Pin Code</p>
                <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
                  {parentData.PinCode}
                </p>
              </div>
            </div>
            <hr className="w-[80%] ml-[10%] my-4" />
            <h4 className="text-lg mt-5">
              <b>Verify Pet&apos;s details</b>
            </h4>
            <PetProfileCard data={selectedPet} className="mt-8" noEdit />
            <div className="flex flex-wrap justify-between mt-4">
              <div className="w-[486px] mt-4">
                <p className="font-sans text-[20px] ">Distinguishing Feature</p>
                <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
                  {formData.DistinguishingFeature}
                </p>
              </div>

              <div className="w-[486px] mt-4">
                <p className="font-sans text-[20px] ">Tag No.</p>
                <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
                  {formData.TagNo}
                </p>
              </div>

              <div className="w-[486px] mt-4">
                <p className="font-sans text-[20px] ">
                  Identification Mark - Eg : Mole on the left Eye
                </p>
                <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
                  {formData.IdentificationMark}
                </p>
              </div>

              <div className="w-[486px] mt-4">
                <p className="font-sans text-[20px] ">Pet Value</p>
                <p className="border-b-2 border-darkGreen font-roboto text-[23px] mt-4 pb-2">
                  {formData.PetValue}
                </p>
              </div>
            </div>

            <div className="flex flex-wrap mt-14 justify-between gap-y-[31.17px]">
              <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px]">
                <h6 className="font-roboto font-semibold text-[21.17px]">
                  Is your pet dog(s) sound and healthy?
                </h6>
                <div className="flex mt-[31.75px]">
                  <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
                    <img src={Rectangle} />
                  </div>
                  <h6 className="font-sans ml-2 text-[19.05px]">{formData.healthy}</h6>
                </div>
              </div>

              <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px]">
                <h6 className="font-roboto font-semibold text-[21.17px]">
                  {" "}
                  Is the dog&apos;s used for commercial purposes?{" "}
                </h6>
                <div className="flex mt-[31.75px]">
                  <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
                    <img src={Rectangle} />
                  </div>
                  <h6 className="font-sans ml-2 text-[19.05px]">
                    {formData.commercial_purpose}
                  </h6>
                </div>
              </div>

              <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px] lg:h-[177px]">
                <h6 className="font-roboto font-semibold text-[21.17px]">
                  Any Pre-existing disease/conditions Pet Dog(s) suffers from?{" "}
                </h6>
                <div className="flex mt-[31.75px]">
                  <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
                    <img src={Rectangle} />
                  </div>
                  <h6 className="font-sans ml-2 text-[19.05px]">
                    {formData.PreExisting_disease}
                  </h6>
                </div>
              </div>

              <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px]">
                <p className="font-semibold font-roboto text-[18px]">
                  Is the dog&apos;s already insured elsewhere?
                </p>
                <div className="flex mt-3 gap-7">
                  <div className="flex mt-[31.75px]">
                    <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
                      <img src={Rectangle} />
                    </div>
                    <h6 className="font-sans ml-2 text-[19.05px]">
                      {formData.already_insured}
                    </h6>
                  </div>
                </div>
                {/* Implement logic for "if else" condition showing the input part   */}
                {formData.NameOfTheInsuranceCompany !== "" && (
                  <div className="border-1 border-gray-500 w-[100%] px-3 py-1 my-4 rounded-md">
                    <h3>{formData.NameOfTheInsuranceCompany}</h3>
                  </div>
                )}

                {formData.PolicyNumber !== "" && (
                  <div className="border-1 border-gray-500 w-[100%] px-3 py-1 my-4 rounded-md">
                    <h3>{formData.PolicyNumber}</h3>
                  </div>
                )}
              </div>

              <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px] px-2 py-5 my-4">
                <p className="font-semibold font-roboto text-[18px]">
                  Please select names of the vaccinations provided to your dog(s){" "}
                </p>
                <div className="grid grid-cols-2 gap-y-6 mt-3 justify-between">
                  <div className="flex w-[30%]">
                    <input
                      className="checkboxStyle mr-2"
                      type="checkbox"
                      value="Rabies"
                      name="vaccinationsProvided"
                      // onChangeCapture={() => {
                      //     setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Rabies'] })
                      // }}
                      checked={vaccinationsProvidedList.Rabies}
                    />{" "}
                    Rabies
                  </div>
                  <div className="flex w-[80%]">
                    <input
                      className="checkboxStyle mr-2"
                      type="checkbox"
                      value="Leptospirosis"
                      name="vaccinationsProvided"
                      //onChangeCapture={() => {
                      //    setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Leptospirosis'] })
                      //}}
                      checked={vaccinationsProvidedList.Leptospirosis}
                    />{" "}
                    Leptospirosis
                  </div>
                  <div className="flex w-[80%]">
                    <input
                      className="checkboxStyle mr-2"
                      type="checkbox"
                      value="Distemper"
                      name="vaccinationsProvided"
                      // onChangeCapture={() => {
                      //     setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Distemper'] })
                      // }}
                      checked={vaccinationsProvidedList.Distemper}
                    />{" "}
                    Distemper
                  </div>
                  <div className="flex w-[80%]">
                    <input
                      className="checkboxStyle mr-2"
                      type="checkbox"
                      value="Parainfluenza"
                      name="vaccinationsProvided"
                      // onChangeCapture={() => {
                      //     setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Parainfluenza'] })
                      // }}
                      checked={vaccinationsProvidedList.Parainfluenza}
                    />{" "}
                    Parainfluenza
                  </div>
                  <div className="flex w-[80%]">
                    <input
                      className="checkboxStyle mr-2"
                      type="checkbox"
                      value="Hepatitis"
                      name="vaccinationsProvided"
                      // onChangeCapture={() => {
                      //     setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Hepatitis'] })
                      // }}
                      checked={vaccinationsProvidedList.Hepatitis}
                    />{" "}
                    Hepatitis
                  </div>
                  <div className="flex w-[80%]">
                    <input
                      className="checkboxStyle mr-2"
                      type="checkbox"
                      value="Parvovirus"
                      name="vaccinationsProvided"
                      // onChangeCapture={() => {
                      //     setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Parvovirus'] })
                      // }}
                      checked={vaccinationsProvidedList.Parvovirus}
                    />{" "}
                    Parvovirus
                  </div>
                  <div className="flex w-[80%]">
                    <input
                      className="checkboxStyle mr-2"
                      type="checkbox"
                      value="Adeno_virus"
                      name="vaccinationsProvided"
                      // onChangeCapture={() => {
                      //     formData.vaccinationsProvided.map((element, i) => (
                      //         (element === 'Adeno virus' &&
                      //             (console.log('index', i),
                      //                 formData.vaccinationsProvided.splice(i, 1))),

                      //         element !== 'Adeno virus' &&
                      //         setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Adeno virus'] })
                      //     ))
                      // }}
                      checked={vaccinationsProvidedList.Adeno_virus}
                    />{" "}
                    Adeno virus
                  </div>
                  <div className="flex w-[80%]">
                    <input
                      className="checkboxStyle mr-2"
                      type="checkbox"
                      value="Corona"
                      name="vaccinationsProvided"
                      // onChangeCapture={() => {
                      //     setFormData({ ...formData, vaccinationsProvided: [...formData.vaccinationsProvided, 'Corona'] })
                      // }}
                      checked={vaccinationsProvidedList.Corona}
                    />{" "}
                    Corona
                  </div>
                </div>
              </div>

              <div className="border-1 border-[#ABB4BD] my-4 rounded-md lg:w-[519.63px] mb:w-full px-2 py-5">
                <div>
                  <p className="font-semibold font-roboto text-[18px]">
                    Upload a certificate of good health issued by a qualified veterinary
                    practitioner for the Pet Dog proposed for insurance. (Optional) (It
                    can be doc/pdf format)
                  </p>
                  <div className="flex mt-3 gap-7 border-1 border-yellow">
                    <input
                      type="file"
                      placeholder="Browse Files (File should be less than 5MB)"
                      name="CertificateOfGoodHealth"
                      disabled
                      // onChange={(e) =>
                      //     setFormData({ ...formData, CertificateOfGoodHealth: e.target.files[0] })
                      // }
                    />
                  </div>
                </div>
              </div>

              <div className="border-1 border-[#1eaf9c] w-full rounded-md p-4 my-8">
                <p className="font-semibold font-roboto text-[18px]">
                  Upload a photograph showing the following angles. (It can be
                  jpeg/png/pdf format)
                </p>
                <p className="bg-lightYellow mt-1 p-2 rounded w-[300px] text-sm mt-4">
                  Each file should be less than 5MB
                </p>
                <div className="flex flex-wrap mb:ml-[15%] lg:ml-1 justify-between mt-4">
                  <div>
                    <FileInput
                      file={formData.image1}
                      placeholder="Upload Face Photo"
                      name="image1"
                      disable={true}
                    />
                  </div>
                  <div>
                    <FileInput
                      file={formData.image2}
                      placeholder="Upload Birth mark (if any) (Optional)"
                      name="image2"
                      disable={true}
                    />
                  </div>
                  <div>
                    <FileInput
                      file={formData.image3}
                      placeholder="Upload Right Face Profile"
                      name="image3"
                      disable={true}
                    />
                  </div>
                  <div>
                    <FileInput
                      file={formData.image4}
                      placeholder="Upload Left Face Profile"
                      name="image4"
                      disable={true}
                    />
                  </div>
                </div>
              </div>

              <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px]">
                <h6 className="font-roboto font-semibold text-[21.17px]">
                  {" "}
                  Do you own any other dog apart from the one you are insuring?{" "}
                </h6>
                <div className="flex mt-[31.75px]">
                  <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
                    <img src={Rectangle} />
                  </div>
                  <h6 className="font-sans ml-2 text-[19.05px]">
                    {formData.ownAnyOtherDog}
                  </h6>
                </div>
              </div>

              <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px]">
                <h6 className="font-roboto font-semibold text-[21.17px]">
                  {" "}
                  Do you have access to a Veterinary Doctor for your Pet Dog(s){" "}
                </h6>
                <div className="flex mt-[31.75px]">
                  <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
                    <img src={Rectangle} />
                  </div>
                  <h6 className="font-sans ml-2 text-[19.05px]">
                    {formData.VeterinaryDoctorForYourPet}
                  </h6>
                </div>
              </div>

              <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px]">
                <h6 className="font-roboto font-semibold text-[21.17px]">
                  {" "}
                  Have you lost any Pet Dog/s during the last three years?
                </h6>
                <div className="flex mt-[31.75px]">
                  <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
                    <img src={Rectangle} />
                  </div>
                  <h6 className="font-sans ml-2 text-[19.05px]">{formData.LostAnyPet}</h6>
                </div>
              </div>

              <div className="border-[1px] border-[#ABB4BD] rounded-md w-[519.63px]  py-[33.87px] px-[21.17px]">
                <h6 className="font-roboto font-semibold text-[21.17px]">
                  {" "}
                  Is the dog&apos;s house sound, healthy and free from vice?{" "}
                </h6>
                <div className="flex mt-[31.75px]">
                  <div className="w-[27.52px] h-[27.52px] rounded-[50%] border-[2.12px] border-darkGreen p-[2.23px]">
                    <img src={Rectangle} />
                  </div>
                  <h6 className="font-sans ml-2 text-[19.05px]">
                    {formData.FreeFromVice}
                  </h6>
                </div>
              </div>
            </div>

            <form>
              <div className="border-1 border-gray rounded-md my-5 py-5 px-10 font-sans text-[18px] font-normal">
                <p className="font-semibold font-roboto text-[23px]">Disclaimer</p>
                <div className="flex my-5">
                  <input
                    className="mr-4 h-[30px] mt-1 checkboxStyle"
                    type="checkbox"
                    onChangeCapture={() =>
                      setDisclaimer({ ...disclaimer, first: !disclaimer.first })
                    }
                    value=""
                    name=""
                  />
                  <p>
                    I / we here by propose to insure the above-mentioned dog owned by me /
                    us with Future Generali India Insurance Co. Ltd. Subject to the terms
                    and conditions and exclusions of the Company’s Policy. I / we warrant
                    the answer to the above queries are true and complete in all respects
                    and that all the Dog are accurately described and are in sound and
                    good health and free from vice and that they are and shall be used
                    solely for the purpose above stated. I / we declare that no
                    information material to the insurance has been withheld and agree that
                    this proposal shall be the basis of the contract between the Company
                    and me/us and I/we agree to accept a policy subject to the terms and
                    conditions prescribed by the Company
                  </p>
                </div>

                <div className="flex my-5">
                  <input
                    className="mr-4 h-[30px] checkboxStyle mt-0 mb:mt-1"
                    type="checkbox"
                    onChangeCapture={() =>
                      setDisclaimer({ ...disclaimer, second: !disclaimer.second })
                    }
                    value=""
                    name=""
                  />
                  <p>
                    I have read the prospectus and agree to the TERMS & CONDITIONS
                    prescribed by the Company.
                  </p>
                </div>

                <div className="flex my-5">
                  <input
                    className="mr-4 h-[30px] checkboxStyle mt-1"
                    type="checkbox"
                    onChangeCapture={() =>
                      setDisclaimer({ ...disclaimer, third: !disclaimer.third })
                    }
                    value=""
                    name=""
                  />
                  <p>
                    Go Green: We will send you a digitally signed policy document via
                    e-mail and, if opted for, over WhatsApp. If you wish to receive a
                    physical copy as well, kindly uncheck this box. Go Green. Go Paperless
                  </p>
                </div>
              </div>
            </form>
          </div>
        )}

        <hr className="w-[80%] mt-[52.92px] ml-[10%] my-4" />

        <div className="flex justify-center my-16">
          <div>
            <GradientButton
              title="BACK"
              onPress={handleBackButton}
              className={"mr-2 bg-blue-300 text-blue-500"}
            />
          </div>
          {stage <= 3 ? (
            <GradientButton title="NEXT" onPress={handleNextButton} className={"ml-2"} />
          ) : (
            <div
              className={`h-[50px] mt-[-28px] ml-2  ${
                disclaimer.first === true &&
                disclaimer.second === true &&
                disclaimer.third === true
                  ? "block"
                  : "hidden"
              }`}
            >
              {/* <GradientButton
                title="PAY NOW"
                className="w-full mt-7"
                onPress={submitBuyInsurance}
              /> */}
              <PayWithRazorPay
                amount={Final_Premium.toFixed(2)}
                onComplete={onCompletionOfPayment}
                startTransaction={submitBuyInsurance}
                // disabled={true}
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}

export default PetInsurance;
