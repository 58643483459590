import React, { useState } from 'react';
import { RequestProfileCard } from '..';
import ProfileDetails from './ProfileDetails';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import NoOfferReceived from '../NoOfferReceived';
import { getTimeLeft } from '../../utils/utils';
import { useEffect } from 'react';

const RequestReceived = ({ bookProvider, requestId, setProvider }) => {
  const [detailPerson, setDetailPerson] = useState('');
  const { services } = useSelector(({ service }) => service);

  // console.log(services);

  useEffect(() => {
    setProvider(detailPerson);
  }, [detailPerson]);

  const {
    providers_offers,
    make_offer_expiry_date,
    accept_offer_expiry_date,
    pet_obj,
  } = services.find((item) => item.docid === requestId) || {};

  // console.log({ pet_obj, make_offer_expiry_date });
  // const notRejectedProvider = providers_offers?.filter(
  //   (item) => item.status !== 'rejected'
  // );

  return (
    <div>
      <h1 className='text-lg font-semibold'>Request Received</h1>
      <div className='grid sm:grid-cols-1 sm:text-center lg:grid-cols-5'>
        <h5 className='bg-lightYellow col-span-2 mt-3 p-2 rounded w-full text-sm'>
          Click on the below profiles to know more about the expert.
        </h5>
      </div>
      {!providers_offers?.length && (
        <NoOfferReceived expireTime={make_offer_expiry_date} />
      )}
      <div className='grid lg:grid-cols-2 xs:grid-cols-1 mt-7 gap-7'>
        {providers_offers?.map((item, i) => (
          <RequestProfileCard
            key={i}
            details={item}
            requestId={requestId}
            expiryDate={getTimeLeft(accept_offer_expiry_date)}
            showDetails={() => setDetailPerson(item)}
            stage={1}
            petDetails={pet_obj}
          />
        ))}
      </div>
      {detailPerson?.provider_doc_id && (
        <ProfileDetails
          uid={detailPerson?.provider_obj.uid}
          requestId={requestId}
          id={detailPerson?.provider_doc_id}
          bookProvider={(provider_details) => {
            bookProvider({ provider_offer: detailPerson, provider_details });
            window.scrollTo(0, 0);
          }}
          status={detailPerson?.status}
        />
      )}
    </div>
  );
};

RequestReceived.defaultProps = {
  bookProvider: () => {},
};

RequestReceived.propTypes = {
  bookProvider: PropTypes.func,
  requestId: PropTypes.string.isRequired,
  setProvider: PropTypes.func,
};

export default RequestReceived;
