import React from 'react';
import PropTypes from 'prop-types';
import { Carousel as GridCarousel } from 'react-responsive-carousel';
import '../CSS/dasboardCarousel.css';

const DashboardCarousel = ({
  items,
  Component,
  smallImage,
  className,
  otherDatas,
  numItems,
}) => {
  const getElements = () => {
    let res = [];
    let tempRes = [];

    items.map((item, i) => {
      if ((i + 1) % numItems === 0 || i === items.length - 1) {
        tempRes.push(item);
        res.push(tempRes);
        tempRes = [];
      } else {
        tempRes.push(item);
      }
    });
    return res;
  };
  const renderArray = getElements();
  

  function getLength  (){
    const lengthOfItem = items.length;

    return lengthOfItem;
  }
  const lengthOfItem = getLength();

  return (
    <>
    {
      lengthOfItem > 8
      ?
      <GridCarousel
      showStatus={false}
      showThumbs={false}
      emulateTouch
      hideArrow
      className={`${smallImage && 'dashboard'}`}
    >
      {renderArray.map((item, i) => (
        <div
          key={i}
          className={`${
            className
              ? className
              : 'grid md:grid-cols-2 gap-4 mb-10 wow animate__animated animate__fadeInUp'
          }`}
        >
          {item.map((details, j) => (
            <Component key={j} data={details} otherDatas={otherDatas} />
          ))}
        </div>
      ))}
    </GridCarousel>
    :
    <>
    {renderArray.map((item, i) => (
      <div
        key={i}
        className={`${
          className
            ? className
            : 'grid md:grid-cols-2 gap-4 mb-10 wow animate__animated animate__fadeInUp'
        }`}
      >
        {item.map((details, j) => (
          <Component key={j} data={details} otherDatas={otherDatas} />
        ))}
      </div>
    ))}
    </>
    }
    </>
  );
};

DashboardCarousel.defaultProps = {
  items: [],
  Component: null,
  smallImage: false,
  className: '',
  otherDatas: {},
  numItems: 4,
};

DashboardCarousel.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
  Component: PropTypes.any,
  smallImage: PropTypes.bool,
  otherDatas: PropTypes.object,
  numItems: PropTypes.number,
};

export default DashboardCarousel;
