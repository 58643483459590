import React from 'react';
import NOT_FOUND from '../Assets/RequestsNf.png';

const NoRecordsFound = () => {
  return (
    <div className='flex flex-col items-center justify-center mt-6'>
      <img src={NOT_FOUND} alt='not found' className='w-32 h-auto' />
      <p className='text-base font-semibold mt-3'>No Records Found</p>
    </div>
  );
};

export default NoRecordsFound;
