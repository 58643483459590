import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  GradientButton,
  PetProfileCard,
  PathologyPackageCardSmall,
  PathologyTestCardSmall,
  InputSelectPathology,
} from "../Components";
import { PayWithRazorPay } from "../Components/RequestPageComponents/..";

import Delete from "../Assets/Delete.png";
import searchIcon from "../Assets/searchIcon.png";
import { PICK_UP_CHARGES } from "../Constants/Constants";

import {
  selectedDateForPathology,
  selectedTimeForPathology,
  selectedPetForInsurance,
} from "./CreateRequestPage";

import { setCreateReq } from "../Redux/actions/createReqAction";
import { removeServices, addMoreServices } from "../Redux/actions/petPathologyActions";

import { imageUpload } from "../Functions/ImageUpload";

function PetPathology() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Global State
  const petPathologyGlobal = useSelector((state) => state.petPathology);
  //Pathology Packages and Tests Data
  const PATHOLOGY_PACKAGES = petPathologyGlobal.pathologyPackage;
  const PATHOLOGY_OPTIONS = petPathologyGlobal.pathologyTest;

  //Selected Pet
  const { pets } = useSelector(({ pet }) => pet);
  const data = pets.find((item) => item.docid === selectedPetForInsurance);

  //Pathology Form State
  const [pathologyFormData, setPathologyFormData] = useState({
    Recommended_By: "Doctor",
    Recommending_Doctor: null,
    Doctor_Location: null,
    Upload_Prescription: null,
    Tested_Location: "No",
    pet_Location: null,
    pet_Location_Post_Office: null,
    Pick_Up_Amount: null,
  });

  //Total Package Price
  const [totalPackageCost, setTotalPackageCost] = useState(0);
  //Total Test Price
  const [totalTestCost, setTotalTestCost] = useState(0);
  //SubTotal Price (Total Package Price + Total Test Price)
  const [subTotalValue, setSubTotalValue] = useState(0);
  //GateWay Charges (2% of SubTotal Price)
  const [gatewayCharges, setGatewayCharges] = useState(0);
  //GST Charges (18% of SubTotal Price)
  const [gst, setGst] = useState(0);
  //Total Price (SubTotal Price + GateWay Charges + GST)
  const [totalAmount, setTotalAmount] = useState(0);

  //TODO: Need Refactoring
  useEffect(() => {
    setTotalPackageCost(
      petPathologyGlobal.selectedService
        .filter((service) => service.isPackage === true)
        .reduce((acc, curr) => acc + curr.price, 0)
    );
    setTotalTestCost(
      petPathologyGlobal.selectedService
        .filter((service) => service.isTest === true)
        .reduce((acc, curr) => acc + curr.price, 0)
    );
  }, [petPathologyGlobal.selectedService]);

  const [pickUpAmount, setPickUpAmount] = useState(0);

  useEffect(() => {
    setSubTotalValue(totalPackageCost + totalTestCost + pickUpAmount);
    setGatewayCharges(
      Math.round(
        (totalPackageCost +
          totalTestCost +
          pickUpAmount +
          (totalPackageCost + totalTestCost + pickUpAmount) * 0.18) *
          0.02
      )
    );
    setGst(Math.round((totalPackageCost + totalTestCost + pickUpAmount) * 0.18));
  }, [totalPackageCost, totalTestCost, pathologyFormData.Tested_Location, pickUpAmount]);

  useEffect(() => {
    //convert to whole number
    setTotalAmount(Math.round(subTotalValue + gatewayCharges + gst));
    //setTotalAmount(subTotalValue + gatewayCharges + gst + pickUpAmount);
  }, [subTotalValue]);

  //TODO: Need Refactoring
  let humanDate = new Date(selectedDateForPathology);
  if (humanDate.toString() === "Invalid Date") {
    navigate("/create-request");
  }

  const [addVisibility, setAddVisibility] = useState(false);
  const [testClick, setTestClick] = useState(true);
  const [search, setSearch] = useState("");

  let selMon;
  let selAcMon;
  const selectMonth = () => {
    selMon = humanDate.getMonth() + 1;

    switch (selMon) {
      case 1:
        selAcMon = "January";
        break;
      case 2:
        selAcMon = "February";
        break;
      case 3:
        selAcMon = "March";
        break;
      case 4:
        selAcMon = "April";
        break;
      case 5:
        selAcMon = "May";
        break;
      case 6:
        selAcMon = "June";
        break;
      case 7:
        selAcMon = "July";
        break;
      case 8:
        selAcMon = "August";
        break;
      case 9:
        selAcMon = "September";
        break;
      case 10:
        selAcMon = "October";
        break;
      case 11:
        selAcMon = "November";
        break;
      case 12:
        selAcMon = "December";
        break;
    }
  };

  let selDay = humanDate.getDay();
  let selAcDay;
  const selectDay = () => {
    switch (selDay) {
      case 1:
        selAcDay = "Monday";
        break;
      case 2:
        selAcDay = "Tuesday";
        break;
      case 3:
        selAcDay = "Wednesday";
        break;
      case 4:
        selAcDay = "Thursday";
        break;
      case 5:
        selAcDay = "Friday";
        break;
      case 6:
        selAcDay = "Saturday";
        break;
      case 7:
        selAcDay = "Sunday";
        break;
    }
  };

  selectDay();
  selectMonth();

  const handleCreateRequest = () => {
    const serviceData = {
      petId: selectedPetForInsurance,
      service: "pathology",
      date: selectedDateForPathology,
      startTime: selectedTimeForPathology,
      userRequirements: {
        request_source: "website",
        ...pathologyFormData,
        service_type: "pathology",
        charges: {
          packageCharge: totalPackageCost,
          testCharge: totalTestCost,
          subTotal: subTotalValue,
          gatewayCharges: gatewayCharges,
          gst: gst,
          totalAmount: totalAmount,
          pickup: pathologyFormData.Tested_Location === "Yes" ? 100 : 0,
        },
      },
      serviceDatas: petPathologyGlobal.selectedService,
      optionalRequirements: {},
      visitType: "Home Visit",
    };
    dispatch(
      setCreateReq(serviceData, (success) => {
        if (success) {
          navigate("/congrats", {
            state: { service: "Pathology" },
          });
        }
      })
    );
  };

  return (
    <Container>
      {addVisibility && (
        <div className="bg-modalColour justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="flex flex-col px-6 bg-white lg:ml-[10%] mb:ml-0  lg:w-[850px] mb:w-[400px] rounded-lg fixed outline-none focus:outline-none">
            <div className=" relative w-full bg-white outline-none focus:outline-none">
              <div className="flex justify-between my-4">
                <button
                  className="bg-darkGreen rounded-md text-white text-[17px] font-roboto text-center w-[91px] h-[44px] lg:mt-[0.1px] mb:mt-3"
                  onClick={() => setAddVisibility(false)}
                >
                  Add
                </button>
                <h1
                  className=" text-lg rounded-full lg:mr-2 mb:mr-10 cursor-pointer font-extrabold"
                  onClick={() => setAddVisibility(false)}
                >
                  X
                </h1>
              </div>
              <div className="flex flex-wrap lg:justify-between mb:justify-center font-semibold cursor-pointer">
                <div className="flex">
                  <h1
                    onClick={() => setTestClick(true)}
                    className={`${
                      testClick
                        ? "text-darkGreen border-b-[3px] border-primary"
                        : "text-veryLightDark"
                    }`}
                  >
                    Tests
                  </h1>
                  <h1
                    onClick={() => setTestClick(false)}
                    className={`ml-4 ${
                      !testClick
                        ? "text-darkGreen border-b-[3px] border-primary"
                        : "text-veryLightDark"
                    }`}
                  >
                    Packages
                  </h1>
                </div>
                <div className="flex border-1 border-[#ABB4BD]  w-[300px] h-[40px] lg:mr-9  mb:-mr-0 p-2 rounded-md lg:mt-0 mb:mt-2">
                  <input
                    type="text"
                    placeholder="Search for Tests / Packages"
                    className="w-[285px] "
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  />
                  <button>
                    <img src={searchIcon} className="w-6" />
                  </button>
                </div>
              </div>
            </div>

            {!testClick && (
              <div className="flex flex-wrap justify-between lg:justify-around mb:justify-center overflow-hidden overflow-scroll overflow-x-hidden lg:h-[500px] mb:h-[450px]">
                {PATHOLOGY_PACKAGES?.filter(
                  (item) =>
                    item.title.toLowerCase().includes(search.toLowerCase()) ||
                    item.detail.toLowerCase().includes(search.toLowerCase())
                ).map((item, index) => (
                  <>
                    <PathologyPackageCardSmall
                      key={index}
                      title={item.title}
                      detail={item.detail}
                      price={item.price}
                      isChecked={
                        petPathologyGlobal?.selectedService.findIndex(
                          (el) => el.name === item.title
                        ) !== -1
                          ? true
                          : false
                      }
                      onChangeCapture={() => {
                        const isIncluded =
                          petPathologyGlobal?.selectedService.findIndex(
                            (el) => el.name === item.title
                          ) !== -1
                            ? true
                            : false;
                        if (!isIncluded) {
                          dispatch(
                            addMoreServices({
                              name: item.title,
                              detail: item.detail,
                              price: item.price,
                              isPackage: item.isPackage,
                              isTest: item.isTest,
                            })
                          );
                        } else {
                          dispatch(
                            removeServices({
                              name: item.title,
                              detail: item.detail,
                              price: item.price,
                              isPackage: item.isPackage,
                              isTest: item.isTest,
                            })
                          );
                        }
                      }}
                    />
                  </>
                ))}
              </div>
            )}

            {testClick && (
              <div className="flex flex-wrap justify-between lg:justify-start mb:justify-center overflow-hidden overflow-scroll overflow-x-hidden lg:h-[500px] mb:h-[450px]">
                {PATHOLOGY_OPTIONS?.filter(
                  (item) =>
                    item.title.toLowerCase().includes(search.toLowerCase()) ||
                    item.detail.toLowerCase().includes(search.toLowerCase())
                ).map((item, index) => (
                  <PathologyTestCardSmall
                    key={index}
                    detail={item.detail}
                    price={item.price}
                    isChecked={
                      petPathologyGlobal?.selectedService.findIndex(
                        (el) => el.name === item.title
                      ) !== -1
                        ? true
                        : false
                    }
                    onChangeCapture={() => {
                      const isIncluded =
                        petPathologyGlobal?.selectedService.findIndex(
                          (el) => el.name === item.title
                        ) !== -1
                          ? true
                          : false;
                      if (!isIncluded) {
                        dispatch(
                          addMoreServices({
                            name: item.title,
                            detail: item.detail,
                            price: item.price,
                            isPackage: item.isPackage,
                            isTest: item.isTest,
                          })
                        );
                      } else {
                        dispatch(
                          removeServices({
                            name: item.title,
                            detail: item.detail,
                            price: item.price,
                            isPackage: item.isPackage,
                            isTest: item.isTest,
                          })
                        );
                      }
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      <PetProfileCard noEdit data={data} className="mt-[30px]" />

      <h6 className="text-[20.65px] font-roboto mt-[50px]">Selected Time Slot</h6>
      <div className="flex flex-wrap justify-between my-4">
        <h6 className="bg-primary rounded-md text-white lg:text-[21px] mb:text-[15px] font-sans font-semibold pt-2 text-center lg:w-[442px] mb:w-full min-h-[59px] ">
          {humanDate.getDate() == 1
            ? `${humanDate.getDate()}st`
            : humanDate.getDate() == 2
            ? `${humanDate.getDate()}nd`
            : humanDate.getDate() == 3
            ? `${humanDate.getDate()}rd`
            : `${humanDate.getDate()}th`}{" "}
          {selAcMon} {humanDate.getFullYear()} , {selAcDay} ,{" "}
          {selectedTimeForPathology > 12
            ? selectedTimeForPathology - 12
            : selectedTimeForPathology}
          :00 {selectedTimeForPathology > 12 ? "PM" : "AM"}
        </h6>
        <button
          className="bg-darkGreen rounded-md text-white text-[17px] font-roboto text-center w-[161px] h-[54px] lg:mt-[0.1px] mb:mt-3"
          onClick={() => setAddVisibility(true)}
        >
          Add More Items
        </button>
      </div>

      <div className="flex flex-wrap justify-between">
        <div>
          <div className="border-1 border-[#B8B8B8] pl-[20px] rounded-md  lg:w-[516.86px] mb:w-full pb-5">
            <h6 className="mt-[25px] mb-[20px] text-[20px] font-roboto font-semibold">
              Recommended By:
            </h6>
            <input
              type="radio"
              name="Recommended_By"
              className="radioStyle mr-2"
              defaultChecked
              onChange={() => {
                setPathologyFormData({ ...pathologyFormData, Recommended_By: "Doctor" });
              }}
            />{" "}
            Doctor
            <input
              type="radio"
              name="Recommended_By"
              className="radioStyle ml-4 mr-2 "
              onChange={() => {
                setPathologyFormData({
                  ...pathologyFormData,
                  Recommended_By: "Self",
                  Doctor_Location: null,
                  Recommending_Doctor: null,
                  Upload_Prescription: null,
                });
              }}
            />{" "}
            Self
            {pathologyFormData.Recommended_By === "Doctor" && (
              <>
                <h6 className="mt-[20px] mb-[20px] text-[20px] font-roboto font-semibold">
                  Doctor&apos;s Name
                </h6>
                <input
                  className="border-[0.443703px] border-[#ABB4BD] rounded-md lg:w-[451px] mb:w-[95%] h-[41px] pl-2"
                  type="text"
                  onChange={(e) => {
                    setPathologyFormData({
                      ...pathologyFormData,
                      Recommending_Doctor: e.target.value,
                    });
                  }}
                />

                <h6 className="mt-[25px] mb-[20px] text-[20px] font-roboto font-semibold">
                  Doctor&apos;s Location
                </h6>
                <input
                  className=" pl-2 border-[0.443703px] border-[#ABB4BD] rounded-md lg:w-[451px] mb:w-[95%] h-[41px]"
                  type="text"
                  onChange={(e) => {
                    setPathologyFormData({
                      ...pathologyFormData,
                      Doctor_Location: e.target.value,
                    });
                  }}
                />

                <h6 className="mt-[25px] mb-[20px] text-[20px] font-roboto font-semibold">
                  Upload Prescription
                </h6>
                <div className="flex mt-3 gap-7 border-1 border-yellow  lg:w-[451px] mb:w-[300px]">
                  <input
                    type="file"
                    onChange={async (e) => {
                      try {
                        const url = await imageUpload(
                          "doctor-prescription",
                          e.target.files[0]
                        );
                        setPathologyFormData({
                          ...pathologyFormData,
                          Upload_Prescription: url,
                        });
                      } catch (error) {
                        console.log("error", error);
                      }
                    }}
                  />
                </div>
              </>
            )}
          </div>

          <h6 className="my-[30.71px] font-roboto text-[20.65px]">Pick Up</h6>

          <div className="border-1 border-[#ABB4BD] pb-[20px] rounded-md px-[20px] lg:w-[516.86px] mb:w-full">
            <h6 className="font-roboto text-[20px] font-semibold mt-[32px] mb-[15px]">
              Do you want us to arrange a visit to draw the sample (blood test) from the
              pet’s location (Charges Apply).
              <br></br>You can also drop off the sample (blood test) at our location for
              no added charges.
            </h6>
            <input
              type="radio"
              className="radioStyle mx-2"
              name="Tested_Location"
              onChange={() => {
                setPathologyFormData({
                  ...pathologyFormData,
                  Tested_Location: "Yes",
                  Pick_Up_Amount: 100,
                });
              }}
            />
            Yes
            <input
              className="radioStyle mx-2"
              type="radio"
              defaultChecked
              name="Tested_Location"
              onChange={() => {
                setPickUpAmount(0);
                setPathologyFormData({
                  ...pathologyFormData,
                  Tested_Location: "No",
                  Pick_Up_Amount: 0,
                });
              }}
            />
            No
            {pathologyFormData.Tested_Location === "Yes" && (
              <div>
                <h6 className="mt-[20px] mb-[20px] text-[20px] font-roboto font-semibold">
                  Pet&apos;s location
                </h6>
                <input
                  className="  pl-2 border-[0.443703px] border-[#ABB4BD] rounded-md lg:w-[451px] mb:w-[100%] h-[41px]"
                  type="text"
                  placeholder="Enter Pincode"
                  onChange={(e) => {
                    setPathologyFormData({
                      ...pathologyFormData,
                      pet_Location: e.target.value,
                    });
                  }}
                />

                <div className="mt-4  rounded-md lg:w-[451px] mb:w-[100%] ">
                  <InputSelectPathology
                    placeholder="Post Office"
                    options={PICK_UP_CHARGES[pathologyFormData.pet_Location]}
                    className="w-15"
                    onChange={(e) => {
                      console.log("e", e);
                      setPathologyFormData({
                        ...pathologyFormData,
                        Pick_Up_Amount: e.price,
                        pet_Location_Post_Office: e.value,
                      });

                      setPickUpAmount(e.price);
                    }}
                    fullWidth={false}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="border-1 border-gray-400 border-t-0 rounded-md lg:ml-5 mb:ml-1 mb:mt-4 lg:mt-0 pt-0 lg:w-[498.88px] mb:w-full ">
          <p className="bg-[#f49f02] p-2 rounded-md text-[23.48px] font-sans text-white mt-0 text-center h-[57px]">
            Added Items
          </p>
          <div className="mx-[30.34px] mb:mx-[22px]">
            <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
              <h6>Packages</h6>
              <h6>Rs. {totalPackageCost} </h6>
            </div>

            {petPathologyGlobal.selectedService.map((item, index) => {
              return (
                item.isPackage && (
                  <div key={index} className="flex justify-between mt-[20px]">
                    <div className="flex">
                      <button
                        onClick={() => {
                          dispatch(removeServices(item));
                        }}
                      >
                        <img src={Delete} />
                      </button>
                      <h6 className="lg:w-[287px] mb:w-[227px] lg:ml-6 mb:ml-3 mr-2">
                        {item.name}
                      </h6>
                    </div>
                    <h6 className="min-w-[55px]">Rs. {item.price}</h6>
                  </div>
                )
              );
            })}

            <hr className="bg-gray mt-[20px]" />

            <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
              <h6>Individual Tests</h6>
              <h6>Rs. {totalTestCost}</h6>
            </div>
            {petPathologyGlobal.selectedService.map((item, index) => {
              return (
                item.isTest && (
                  <div key={index} className="flex justify-between mt-[20px]">
                    <div className="flex w-[100%]">
                      <button
                        onClick={() => {
                          dispatch(removeServices(item));
                        }}
                      >
                        <img src={Delete} className="min-w-[15px] max-w-[15px] mr-2" />
                      </button>
                      <h6 className="lg:max-w-[320px] lg:min-w-[320px] mb:max-w-[217px] mb:min-w-[217px] lg:ml-4 mb:ml-1 break-all">
                        {item.name}
                      </h6>
                    </div>
                    <h6 className="min-w-[55px] ml-2 mb:ml-1">Rs. {item.price}</h6>
                  </div>
                )
              );
            })}

            {pathologyFormData.Tested_Location === "Yes" && (
              <>
                <hr className="bg-gray mt-[20px]" />
                <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
                  <h6>Pickup Charges</h6>
                  <h6>Rs. {pickUpAmount}</h6>
                </div>
              </>
            )}

            <hr className="bg-gray mt-[20px]" />

            <div className="flex justify-between mt-[20px]">
              <h6>Sub-total</h6>
              <h6 className="font-semibold">Rs. {subTotalValue}</h6>
            </div>

            <div className="flex justify-between mt-[10px]">
              <h6>GST @ 18%</h6>
              <h6 className="font-semibold">Rs. {gst}</h6>
            </div>

            <div className="flex justify-between mt-[10px]">
              <h6>Payment Gateway fees 2%</h6>
              <h6 className="font-semibold">Rs. {gatewayCharges}</h6>
            </div>

            <hr className="bg-gray mt-[20px]" />

            <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
              <h6>Final Amount</h6>
              <h6>Rs. {totalAmount}</h6>
            </div>

            <div className="bg-lightYellow mt-[40px] mb-[30px]  lg:h-[40px] mb:h-[60px] h-[41px] pt-2 rounded-md">
              <p className="text-[14.71px] font-roboto text-center">
                The total amount you pay (incl. GST) while buying the tests
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr className="bg-gray mt-[60px] mb-[50px]" />

      <div className="flex justify-center my-16">
        <GradientButton
          title="CANCEL"
          onPress={() => {
            navigate("/create-request");
          }}
          className={"mr-2 bg-blue-300 text-blue-500"}
        />
        <div className={`h-[50px] mt-[-28px] ml-2`}>
          <PayWithRazorPay amount={totalAmount} onComplete={handleCreateRequest} />
        </div>
      </div>
    </Container>
  );
}

export default PetPathology;
