/* eslint-disable react/prop-types */
import React from 'react';

const Drawer = ({ showSidebar, setShowSidebar, children }) => {
  return (
    <>
      {showSidebar && (
        <button
          className='flex text-4xl text-black items-center cursor-pointer fixed right-10 top-6 z-50'
          onClick={() => setShowSidebar(!showSidebar)}
        >
          x
        </button>
      )}
      {children}
    </>
  );
};

export default Drawer;
