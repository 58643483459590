import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, GradientButton } from "../Components";
import { ServiceOptions } from "../Constants/Constants";

const CongratsScreen = () => {
  const location = useLocation();
  const img = ServiceOptions.find((item) => {
    return item.title === location.state.service;
  });
  console.log(img);
  return (
    <>
      <Container className="my-16">
        <div className="grid grid-cols-1 text-center items-center">
          <div className="flex justify-center mb-7">
            <img src={img.image} className="h-24" />
          </div>
          <div>
            <h1 className="text-lg font-semibold">
              Your request for {location.state.service} has been placed!
            </h1>
          </div>
          <div className="grid grid-cols-4">
            <div></div>
            <div className="col-span-2 mt-4 text-center">
              <p>
                <p>
                  {" "}
                  {img.value === "pathology"
                    ? "Please Wait for further intimation"
                    : "We are checking with highly rated experts around your area. You will get their response in the next 4-5 hours in the notification section of the website."}
                </p>
              </p>
            </div>
            <div></div>
          </div>
          <div className="grid grid-cols-4">
            <div></div>
            <div className="col-span-2 mt-4 text-center bg-lightYellow p-2 rounded">
              <p>
                {" "}
                {img.value === "pathology"
                  ? "*Our team will be in touch with you soon."
                  : "*Please accept one of the responses to confirm booking."}
              </p>
            </div>
            <div></div>
          </div>
          <div className="grid grid-cols-4 mt-10">
            <div></div>
            <div className="col-span-2 mt-4 text-center rounded">
              <Link to="/my-requests">
                <GradientButton title="GO TO MY REQUESTS" />
              </Link>
            </div>
            <div></div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default CongratsScreen;
