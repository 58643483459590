import Boarding from "../Assets/icons/board.png";
import Grooming from "../Assets/icons/groom.png";
import Sitting from "../Assets/icons/sit.png";
import training from "../Assets/icons/train.png";
import Veterinarian from "../Assets/icons/vet.png";
import Walking from "../Assets/icons/dogwalk.png";
import board from "../Assets/icons/board.png";
import dogwalk from "../Assets/icons/dogwalk.png";
import EarCleaning from "../Assets/icons/EarCleaning.png";
import groom from "../Assets/icons/groom.png";
import Hair from "../Assets/icons/Hair.png";
import Nails from "../Assets/icons/Nails.png";
import Paw from "../Assets/icons/Paw.png";
import sit from "../Assets/icons/sit.png";
import train from "../Assets/icons/train.png";
import vet from "../Assets/icons/vet.png";
import Insurance from "../Assets/icons/Insurance.png";
import pathology from "../Assets/pathology.png";

import cat1 from "../Assets/removeable/cat1.jpg";
import cat2 from "../Assets/removeable/cat2.jpg";
import cat3 from "../Assets/removeable/cat3.jpg";
import cert1 from "../Assets/removeable/cert.webp";
import cert2 from "../Assets/removeable/cert2.jpg";
import cert3 from "../Assets/removeable/cert3.jpg";
import cert4 from "../Assets/removeable/cert4.png";
import dog1 from "../Assets/removeable/dog1.jpg";
import dog2 from "../Assets/removeable/dog2.jpg";
import dog3 from "../Assets/removeable/dog3.jpg";
import Casper from "../Assets/removeable/Ellipse 2.png";
import Rosy from "../Assets/removeable/Ellipse 5.png";
import Person1 from "../Assets/removeable/Person1.png";
import Person2 from "../Assets/removeable/Person2.png";
import LGLOGO from "../Assets/fglogo.png";

export const PICK_UP_CHARGES = {
  400001: [
    {
      value: "Bazargate S.O",
      label: "Bazargate S.O",
      price: 700,
    },
    {
      value: "M.P.T.S.O.",
      label: "M.P.T.S.O.",
      price: 700,
    },
    {
      value: "Stock Exchange S.O.",
      label: "Stock Exchange S.O.",
      price: 700,
    },
    {
      value: "Tajmahal S.O.",
      label: "Tajmahal S.O.",
      price: 700,
    },
    {
      value: "Town Hall S.O (Mumbai)",
      label: "Town Hall S.O (Mumbai)",
      price: 700,
    },
    {
      value: "Mumbai G.P.O.",
      label: "Mumbai G.P.O.",
      price: 700,
    },
  ],
  400002: [
    {
      value: "S. C. Court S.O.",
      label: "S. C. Court S.O.",
      price: 700,
    },
    {
      value: "Thakurdwar S.O",
      label: "Thakurdwar S.O",
      price: 700,
    },
    {
      value: "Kalbadevi H.O.",
      label: "Kalbadevi H.O.",
      price: 700,
    },
  ],
  400003: [
    {
      value: "B.P.Lane S.O",
      label: "B.P.Lane S.O",
      price: 600,
    },
    {
      value: "Mandvi S.O (Mumbai)",
      label: "Mandvi S.O (Mumbai)",
      price: 600,
    },
    {
      value: "Masjid S.O.",
      label: "Masjid S.O.",
      price: 600,
    },
    {
      value: "Null Bazar S.O",
      label: "Null Bazar S.O",
      price: 600,
    },
  ],
  400004: [
    {
      value: "Ambewadi S.O (Mumbai)",
      label: "Ambewadi S.O (Mumbai)",
      price: 600,
    },
    {
      value: "Charni Road S.O.",
      label: "Charni Road S.O.",
      price: 600,
    },
    {
      value: "Chaupati S.O",
      label: "Chaupati S.O",
      price: 600,
    },
    {
      value: "Girgaon S.O",
      label: "Girgaon S.O",
      price: 600,
    },
    {
      value: "Madhavbaug S.O",
      label: "Madhavbaug S.O",
      price: 600,
    },
    {
      value: "Opera House S.O.",
      label: "Opera House S.O.",
      price: 600,
    },
  ],
  400005: [
    {
      value: "Asvini S.O",
      label: "Asvini S.O",
      price: 1000,
    },
    {
      value: "Colaba S.O.",
      label: "Colaba S.O.",
      price: 800,
    },
    {
      value: "Holiday Camp S.O.",
      label: "Holiday Camp S.O.",
      price: 800,
    },
    {
      value: "V.W.T.C. S.O.",
      label: "V.W.T.C. S.O.",
      price: 800,
    },
    {
      value: "Colaba Bazar S.O",
      label: "Colaba Bazar S.O",
      price: 800,
    },
  ],

  400006: [
    {
      value: "Malabar Hill S.O.",
      label: "Malabar Hill S.O.",
      price: 1000,
    },
  ],
  400007: [
    {
      value: "Bharat Nagar S.O (Mumbai)",
      label: "Bharat Nagar S.O (Mumbai)",
      price: 800,
    },
    {
      value: "S V Marg S.O",
      label: "S V Marg S.O",
      price: 800,
    },
    {
      value: "Grant Road S.O.",
      label: "Grant Road S.O.",
      price: 600,
    },
    {
      value: "N.S.Patkar Marg S.O",
      label: "N.S.Patkar Marg S.O",
      price: 600,
    },
    {
      value: "Tardeo S.O.",
      label: "Tardeo S.O.",
      price: 600,
    },
  ],
  400008: [
    {
      value: "J.J.Hospital S.O",
      label: "J.J.Hospital S.O",
      price: 600,
    },
    {
      value: "Kamathipura S.O",
      label: "Kamathipura S.O",
      price: 600,
    },
    {
      value: "Falkland Road S.O.",
      label: "Falkland Road S.O.",
      price: 600,
    },
    {
      value: "M A Marg S.O",
      label: "M A Marg S.O",
      price: 600,
    },
    {
      value: "Mumbai Central H.O.",
      label: "Mumbai Central H.O.",
      price: 600,
    },
  ],
  400009: [
    {
      value: "Princess Dock S.O",
      label: "Princess Dock S.O",
      price: 600,
    },
    {
      value: "Noor Baug S.O.",
      label: "Noor Baug S.O.",
      price: 600,
    },
    {
      value: "Chinchbunder H.O",
      label: "Chinchbunder H.O",
      price: 600,
    },
  ],
  400010: [
    {
      value: "Mazgaon S.O",
      label: "Mazgaon S.O",
      price: 600,
    },
    {
      value: "Mazgaon Road S.O.",
      label: "Mazgaon Road S.O.",
      price: 600,
    },
    {
      value: "Mazgaon Dock S.O",
      label: "Mazgaon Dock S.O",
      price: 600,
    },
    {
      value: "V K Bhavan S.O.",
      label: "V K Bhavan S.O.",
      price: 600,
    },
    {
      value: "Dockyard Road S.O.",
      label: "Dockyard Road S.O.",
      price: 600,
    },
  ],
  400011: [
    {
      value: "Chinchpokli S.O",
      label: "Chinchpokli S.O",
      price: 600,
    },
    {
      value: "Haines Road S.O.",
      label: "Haines Road S.O.",
      price: 600,
    },
    {
      value: "Jacob Circle S.O.",
      label: "Jacob Circle S.O.",
      price: 600,
    },
    {
      value: "Agripada S.O",
      label: "Agripada S.O",
      price: 600,
    },
    {
      value: "B.P.C. Jacob Circle S.O.",
      label: "B.P.C. Jacob Circle S.O.",
      price: 600,
    },
  ],
  400012: [
    {
      value: "Parel S.O.",
      label: "Parel S.O.",
      price: 600,
    },
    {
      value: "Parel Naka S.O.",
      label: "Parel Naka S.O.",
      price: 600,
    },
    {
      value: "Haffkin Institute S.O",
      label: "Haffkin Institute S.O",
      price: 600,
    },
    {
      value: "Chamarbaug S.O",
      label: "Chamarbaug S.O",
      price: 600,
    },
    {
      value: "Lal Baug S.O.",
      label: "Lal Baug S.O.",
      price: 600,
    },
    {
      value: "Best Staff Quarters S.O.",
      label: "Best Staff Quarters S.O.",
      price: 600,
    },
    {
      value: "Parel Rly Work Shop S.O.",
      label: "Parel Rly Work Shop S.O.",
      price: 600,
    },
  ],
  400013: [
    {
      value: "Delisle Road S.O.",
      label: "Delisle Road S.O.",
      price: 600,
    },
  ],
  400014: [
    {
      value: "Dadar Colony S.O.",
      label: "Dadar Colony S.O.",
      price: 600,
    },
    {
      value: "Naigaon S.O (Mumbai)",
      label: "Naigaon S.O (Mumbai)",
      price: 600,
    },
    {
      value: "Dadar H.O.",
      label: "Dadar H.O.",
      price: 600,
    },
  ],
  400015: [
    {
      value: "Sewri S.O",
      label: "Sewri S.O",
      price: 600,
    },
  ],
  400016: [
    {
      value: "Kapad Bazar S.O",
      label: "Kapad Bazar S.O",
      price: 600,
    },
    {
      value: "Mahim East S.O.",
      label: "Mahim East S.O.",
      price: 600,
    },
    {
      value: "Mahim Bazar S.O",
      label: "Mahim Bazar S.O",
      price: 600,
    },
    {
      value: "Mori Road S.O.",
      label: "Mori Road S.O.",
      price: 600,
    },
    {
      value: "Mahim H.O.",
      label: "Mahim H.O.",
      price: 600,
    },
  ],
  400017: [
    {
      value: "Dharavi S.O.",
      label: "Dharavi S.O.",
      price: 600,
    },
    {
      value: "Dharavi Road S.O.",
      label: "Dharavi Road S.O.",
      price: 600,
    },
  ],
  400018: [
    {
      value: "Worli S.O.",
      label: "Worli S.O.",
      price: 600,
    },
    {
      value: "Worli Naka S.O.",
      label: "Worli Naka S.O.",
      price: 600,
    },
  ],
  400019: [
    {
      value: "Matunga S.O.",
      label: "Matunga S.O.",
      price: 600,
    },
  ],
  400020: [
    {
      value: "Central Building S.O.",
      label: "Central Building S.O.",
      price: 700,
    },
    {
      value: "Churchgate S.O.",
      label: "Churchgate S.O.",
      price: 700,
    },
    {
      value: "Marine Lines S.O",
      label: "Marine Lines S.O",
      price: 700,
    },
  ],
  400021: [
    {
      value: "Elephanta Caves Po B.O",
      label: "Elephanta Caves Po B.O",
      price: 2000,
    },
    {
      value: "Nariman Point S.O.",
      label: "Nariman Point S.O.",
      price: 800,
    },
    {
      value: "New Yogakshema S.O",
      label: "New Yogakshema S.O",
      price: 800,
    },
  ],
  400022: [
    {
      value: "Transit Camp S.O.",
      label: "Transit Camp S.O.",
      price: 600,
    },
    {
      value: "Raoli Camp S.O.",
      label: "Raoli Camp S.O.",
      price: 600,
    },
    {
      value: "Sion S.O",
      label: "Sion S.O",
      price: 600,
    },
    {
      value: "Chunabhatti S.O",
      label: "Chunabhatti S.O",
      price: 600,
    },
  ],
  400024: [
    {
      value: "Nehru Nagar S.O (Mumbai)",
      label: "Nehru Nagar S.O (Mumbai)",
      price: 600,
    },
  ],
  400025: [
    {
      value: "Prabhadevi S.O.",
      label: "Prabhadevi S.O.",
      price: 600,
    },
    {
      value: "New Prabhadevi Road S.O.",
      label: "New Prabhadevi Road S.O.",
      price: 600,
    },
  ],

  400026: [
    {
      value: "Cumballa Hill S.O.",
      label: "Cumballa Hill S.O.",
      price: 600,
    },
    {
      value: "Gowalia Tank S.O.",
      label: "Gowalia Tank S.O.",
      price: 600,
    },
    {
      value: "Dr Deshmukh Marg S.O",
      label: "Dr Deshmukh Marg S.O",
      price: 600,
    },
    {
      value: "Cumballa Sea Face S.O.",
      label: "Cumballa Sea Face S.O.",
      price: 600,
    },
  ],
  400027: [
    {
      value: "V J B Udyan S.O",
      label: "V J B Udyan S.O",
      price: 600,
    },
  ],
  400028: [
    {
      value: "Bhawani Shankar S.O",
      label: "Bhawani Shankar S.O",
      price: 600,
    },
    {
      value: "Bhawani Shankar Rd S.O",
      label: "Bhawani Shankar Rd S.O",
      price: 600,
    },
    {
      value: "Gokhale Road S.O. (Mumbai)",
      label: "Gokhale Road S.O. (Mumbai)",
      price: 600,
    },
    {
      value: "Ranade Road S.O.",
      label: "Ranade Road S.O.",
      price: 600,
    },
    {
      value: "S V S Marg S.O",
      label: "S V S Marg S.O",
      price: 600,
    },
    {
      value: "Shivaji Park S.O (Mumbai)",
      label: "Shivaji Park S.O (Mumbai)",
      price: 600,
    },
  ],
  400029: [
    {
      value: "A I Staff Colony S.O.",
      label: "A I Staff Colony S.O.",
      price: 700,
    },
    {
      value: "Santacruz P&t Colony S.O",
      label: "Santacruz P&t Colony S.O",
      price: 700,
    },
  ],
  400030: [
    {
      value: "Century Mill S.O",
      label: "Century Mill S.O",
      price: 600,
    },
    {
      value: "Worli Colony S.O.",
      label: "Worli Colony S.O.",
      price: 600,
    },
    {
      value: "Worli Police Camp S.O.",
      label: "Worli Police Camp S.O.",
      price: 600,
    },
    {
      value: "Worli Sea Face S.O.",
      label: "Worli Sea Face S.O.",
      price: 600,
    },
  ],
  400031: [
    {
      value: "Wadala S.O.",
      label: "Wadala S.O.",
      price: 700,
    },
    {
      value: "Wadala Rs S.O.",
      label: "Wadala Rs S.O.",
      price: 700,
    },
    {
      value: "Kidwai Nagar S.O (Mumbai)",
      label: "Kidwai Nagar S.O (Mumbai)",
      price: 600,
    },
  ],
  400032: [
    {
      value: "High Court Building S.O. (Mumbai)",
      label: "High Court Building S.O. (Mumbai)",
      price: 700,
    },
    {
      value: "Mantralaya S.O (Mumbai)",
      label: "Mantralaya S.O (Mumbai)",
      price: 700,
    },
    {
      value: "Secretariate S.O.",
      label: "Secretariate S.O.",
      price: 700,
    },
  ],
  400033: [
    {
      value: "Tank Road S.O.",
      label: "Tank Road S.O.",
      price: 600,
    },
    {
      value: "Kalachowki S.O",
      label: "Kalachowki S.O",
      price: 600,
    },
    {
      value: "Reay Road S.O.",
      label: "Reay Road S.O.",
      price: 600,
    },
    {
      value: "Cotton Exchange S.O.",
      label: "Cotton Exchange S.O.",
      price: 600,
    },
    {
      value: "L B S N E collage S.O",
      label: "L B S N E collage S.O",
      price: 600,
    },
  ],
  400034: [
    {
      value: "Tulsiwadi S.O",
      label: "Tulsiwadi S.O",
      price: 600,
    },
    {
      value: "Haji Ali S.O",
      label: "Haji Ali S.O",
      price: 600,
    },
  ],
  400035: [
    {
      value: "Rajbhavan S.O (Mumbai)",
      label: "Rajbhavan S.O (Mumbai)",
      price: 800,
    },
  ],
  400037: [
    {
      value: "Antop Hill S.O.",
      label: "Antop Hill S.O.",
      price: 600,
    },
    {
      value: "B P T Colony S.O.",
      label: "B P T Colony S.O.",
      price: 600,
    },
    {
      value: "C G S Colony S.O.",
      label: "C G S Colony S.O.",
      price: 600,
    },
    {
      value: "Wadala Truck Terminal S.O",
      label: "Wadala Truck Terminal S.O",
      price: 600,
    },
  ],
  400042: [
    {
      value: "Bhandup East S.O.",
      label: "Bhandup East S.O.",
      price: 800,
    },
  ],
  400043: [
    {
      value: "D.M. Colony S.O.",
      label: "D.M. Colony S.O.",
      price: 800,
    },
    {
      value: "Shivaji Nagar S.O (Mumbai)",
      label: "Shivaji Nagar S.O (Mumbai)",
      price: 800,
    },
  ],
  400049: [
    {
      value: "Juhu S.O.",
      label: "Juhu S.O.",
      price: 800,
    },
  ],
  400050: [
    {
      value: "Bandra West S.O.",
      label: "Bandra West S.O.",
      price: 700,
    },
  ],
  400051: [
    {
      value: "B.N. Bhavan S.O.",
      label: "B.N. Bhavan S.O.",
      price: 700,
    },
    {
      value: "Kherwadi S.O",
      label: "Kherwadi S.O",
      price: 700,
    },
    {
      value: "Bandra(East) S.O.",
      label: "Bandra(East) S.O.",
      price: 700,
    },
    {
      value: "Audit Bhavan S.O.",
      label: "Audit Bhavan S.O.",
      price: 700,
    },
  ],
  400052: [
    {
      value: "Danda S.O.",
      label: "Danda S.O.",
      price: 700,
    },
    {
      value: "Khar Colony S.O.",
      label: "Khar Colony S.O.",
      price: 700,
    },
    {
      value: "V.P. Road S.O.",
      label: "V.P. Road S.O.",
      price: 700,
    },
    {
      value: "Khar Delivery S.O.",
      label: "Khar Delivery S.O.",
      price: 700,
    },
  ],
  400053: [
    {
      value: "Azad Nagar S.O (Mumbai)",
      label: "Azad Nagar S.O (Mumbai)",
      price: 700,
    },
    {
      value: "Andheri H.O.",
      label: "Andheri H.O.",
      price: 700,
    },
  ],
  400054: [
    {
      value: "Santacruz Central S.O",
      label: "Santacruz Central S.O",
      price: 700,
    },
    {
      value: "Santacruz(West) S.O.",
      label: "Santacruz(West) S.O.",
      price: 700,
    },
  ],
  400055: [
    {
      value: "Vakola S.O",
      label: "Vakola S.O",
      price: 700,
    },
    {
      value: "Santacruz(East) S.O.",
      label: "Santacruz(East) S.O.",
      price: 700,
    },
  ],
  400056: [
    {
      value: "I.R.L.A. S.O.",
      label: "I.R.L.A. S.O.",
      price: 700,
    },
    {
      value: "Vileparle(West) S.O.",
      label: "Vileparle(West) S.O.",
      price: 800,
    },
  ],
  400057: [
    {
      value: "Hanuman Road S.O.",
      label: "Hanuman Road S.O.",
      price: 800,
    },
    {
      value: "Vileparle Railway Station S.O.",
      label: "Vileparle Railway Station S.O.",
      price: 800,
    },
    {
      value: "Vileeparle (East) S.O.",
      label: "Vileeparle (East) S.O.",
      price: 800,
    },
  ],
  400058: [
    {
      value: "H.M.P. School S.O.",
      label: "H.M.P. School S.O.",
      price: 800,
    },
    {
      value: "Andheri Railway Station S.O.",
      label: "Andheri Railway Station S.O.",
      price: 800,
    },
  ],
  400059: [
    {
      value: "Marol Bazar S.O",
      label: "Marol Bazar S.O",
      price: 900,
    },
    {
      value: "Marol Naka S.O.",
      label: "Marol Naka S.O.",
      price: 900,
    },
    {
      value: "J.B. Nagar S.O",
      label: "J.B. Nagar S.O",
      price: 900,
    },
  ],
  400060: [
    {
      value: "Jogeshwari East S.O.",
      label: "Jogeshwari East S.O.",
      price: 800,
    },
  ],
  400061: [
    {
      value: "Madh B.O",
      label: "Madh B.O",
      price: 1500,
    },

    {
      value: "Vesava S.O",
      label: "Vesava S.O",
      price: 1500,
    },
  ],
  400063: [
    {
      value: "Goregaon East S.O.",
      label: "Goregaon East S.O.",
      price: 800,
    },

    {
      value: "Sharma Estate S.O.",
      label: "Sharma Estate S.O.",
      price: 800,
    },
  ],
  400064: [
    {
      value: "Malad S.O.",
      label: "Malad S.O.",
      price: 800,
    },

    {
      value: "Malad West Dely S.O",
      label: "Malad West Dely S.O",
      price: 800,
    },
    {
      value: "Orlem S.O",
      label: "Orlem S.O",
      price: 1000,
    },
    {
      value: "Liberty Garden S.O.",
      label: "Liberty Garden S.O.",
      price: 800,
    },
  ],
  400065: [
    {
      value: "Aareymilk Colony S.O.",
      label: "Aareymilk Colony S.O.",
      price: 800,
    },
    {
      value: "S R P F Camp S.O.",
      label: "S R P F Camp S.O.",
      price: 800,
    },
    {
      value: "Nagari Niwara S.O",
      label: "Nagari Niwara S.O",
      price: 800,
    },
  ],
  400066: [
    {
      value: "Magthane B.O",
      label: "Magthane B.O",
      price: 800,
    },
    {
      value: "Borivali East S.O.",
      label: "Borivali East S.O.",
      price: 800,
    },
    {
      value: "Daulat Nagar S.O (Mumbai)",
      label: "Daulat Nagar S.O (Mumbai)",
      price: 800,
    },
    {
      value: "Rajendra Nagar S.O (Mumbai)",
      label: "Rajendra Nagar S.O (Mumbai)",
      price: 800,
    },
    {
      value: "S. K.Nagar S.O",
      label: "S. K.Nagar S.O",
      price: 800,
    },
  ],
  400067: [
    {
      value: "Kandivali West S.O.",
      label: "Kandivali West S.O.",
      price: 800,
    },
    {
      value: "Charkop S.O",
      label: "Charkop S.O",
      price: 1200,
    },
    {
      value: "Kandivali R.S. S.O.",
      label: "Kandivali R.S. S.O.",
      price: 800,
    },
  ],
  400068: [
    {
      value: "Ketkipada B.O",
      label: "Ketkipada B.O",
      price: 800,
    },
    {
      value: "Dahisar R.S. S.O.",
      label: "Dahisar R.S. S.O.",
      price: 1000,
    },
    {
      value: "Dahisar S.O.",
      label: "Dahisar S.O.",
      price: 1000,
    },
  ],
  400069: [
    {
      value: "Nagardas Road S.O.",
      label: "Nagardas Road S.O.",
      price: 800,
    },
    {
      value: "Andheri East S.O.",
      label: "Andheri East S.O.",
      price: 800,
    },
  ],
  400070: [
    {
      value: "Kurla North S.O.",
      label: "Kurla North S.O.",
      price: 600,
    },
    {
      value: "Kurla S.O.",
      label: "Kurla S.O.",
      price: 600,
    },
    {
      value: "Netajinagar S.O",
      label: "Netajinagar S.O",
      price: 600,
    },
  ],
  400071: [
    {
      value: "Chembur H.O.",
      label: "Chembur H.O.",
      price: 700,
    },
    {
      value: "Sindhi Society S.O.",
      label: "Sindhi Society S.O.",
      price: 700,
    },
  ],
  400072: [
    {
      value: "Vihar Road S.O.",
      label: "Vihar Road S.O.",
      price: 900,
    },
    {
      value: "Sakinaka S.O",
      label: "Sakinaka S.O",
      price: 900,
    },
  ],
  400074: [
    {
      value: "Chembur Extension S.O",
      label: "Chembur Extension S.O",
      price: 700,
    },
    {
      value: "F.C.I. S.O.",
      label: "F.C.I. S.O.",
      price: 700,
    },
    {
      value: "Mahul Road S.O.",
      label: "Mahul Road S.O.",
      price: 700,
    },
  ],
  400075: [
    {
      value: "Best Staff Colony S.O.",
      label: "Best Staff Colony S.O.",
      price: 700,
    },
    {
      value: "Pant Nagar S.O",
      label: "Pant Nagar S.O",
      price: 700,
    },
    {
      value: "R.A.Nagar S.O",
      label: "R.A.Nagar S.O",
      price: 700,
    },
  ],
  400076: [
    {
      value: "Powai Iit S.O",
      label: "Powai Iit S.O",
      price: 800,
    },
  ],
  400077: [
    {
      value: "Rajawadi S.O",
      label: "Rajawadi S.O",
      price: 800,
    },
  ],
  400078: [
    {
      value: "Bhandup West S.O.",
      label: "Bhandup West S.O.",
      price: 800,
    },
    {
      value: "Bhandup Ind. Estate S.O.",
      label: "Bhandup Ind. Estate S.O.",
      price: 800,
    },
    {
      value: "J.M. Road S.O.",
      label: "J.M. Road S.O.",
      price: 800,
    },
    {
      value: "P.H. Colony S.O.",
      label: "P.H. Colony S.O.",
      price: 800,
    },
    {
      value: "Usha Nagar S.O",
      label: "Usha Nagar S.O",
      price: 800,
    },
  ],
  400079: [
    {
      value: "Psm Colony S.O.",
      label: "Psm Colony S.O.",
      price: 800,
    },
    {
      value: "Vikhroli S.O.",
      label: "Vikhroli S.O.",
      price: 800,
    },
  ],
  400080: [
    {
      value: "Mulund West S.O.",
      label: "Mulund West S.O.",
      price: 800,
    },
    {
      value: "Mulund Dd Road S.O.",
      label: "Mulund Dd Road S.O.",
      price: 800,
    },
    {
      value: "Nahur S.O",
      label: "Nahur S.O",
      price: 800,
    },
    {
      value: "Nehru Road S.O (Mumbai)",
      label: "Nehru Road S.O (Mumbai)",
      price: 800,
    },
    {
      value: "S.B. Road S.O.",
      label: "S.B. Road S.O.",
      price: 800,
    },
  ],
  400081: [
    {
      value: "Mulund East S.O.",
      label: "Mulund East S.O.",
      price: 800,
    },
    {
      value: "Mhada Colony S.O.",
      label: "Mhada Colony S.O.",
      price: 800,
    },
  ],
  400082: [
    {
      value: "Bhandup Complex S.O.",
      label: "Bhandup Complex S.O.",
      price: 900,
    },
    {
      value: "Mulund Colony S.O.",
      label: "Mulund Colony S.O.",
      price: 900,
    },
  ],
  400083: [
    {
      value: "Kannamwar Nagar S.O",
      label: "Kannamwar Nagar S.O",
      price: 800,
    },
    {
      value: "Tagore Nagar S.O",
      label: "Tagore Nagar S.O",
      price: 800,
    },
  ],
  400084: [
    {
      value: "Barve Nagar S.O",
      label: "Barve Nagar S.O",
      price: 800,
    },
  ],
  400085: [
    {
      value: "B.A.R.C. S.O.",
      label: "B.A.R.C. S.O.",
      price: 1000,
    },
  ],
  400086: [
    {
      value: "Ghatkopar West S.O.",
      label: "Ghatkopar West S.O.",
      price: 800,
    },
    {
      value: "Rifle Range S.O",
      label: "Rifle Range S.O",
      price: 800,
    },
    {
      value: "Sahakar Bhavan S.O.",
      label: "Sahakar Bhavan S.O.",
      price: 800,
    },
  ],
  400087: [
    {
      value: "N.I.T.I.E. S.O.",
      label: "N.I.T.I.E. S.O.",
      price: 800,
    },
  ],
  400088: [
    {
      value: "Govandi S.O",
      label: "Govandi S.O",
      price: 800,
    },
    {
      value: "T.F.Donar S.O",
      label: "T.F.Donar S.O",
      price: 800,
    },
    {
      value: "Trombay S.O.",
      label: "Trombay S.O.",
      price: 1000,
    },
  ],
  400089: [
    {
      value: "Chembur Rs S.O.",
      label: "Chembur Rs S.O.",
      price: 800,
    },
    {
      value: "Tilak Nagar S.O (Mumbai)",
      label: "Tilak Nagar S.O (Mumbai)",
      price: 800,
    },
  ],
  400091: [
    {
      value: "Borivali H.O.",
      label: "Borivali H.O.",
      price: 900,
    },
  ],
  400092: [
    {
      value: "Borivali West S.O.",
      label: "Borivali West S.O.",
      price: 900,
    },
  ],
  400093: [
    {
      value: "Chakala Midc S.O",
      label: "Chakala Midc S.O",
      price: 1000,
    },
  ],
  400094: [
    {
      value: "Anushakti Nagar S.O",
      label: "Anushakti Nagar S.O",
      price: 1000,
    },
  ],
  400095: [
    {
      value: "Kharodi S.O",
      label: "Kharodi S.O",
      price: 1200,
    },
    {
      value: "Ins Hamla S.O",
      label: "Ins Hamla S.O",
      price: 1200,
    },
  ],
  400096: [
    {
      value: "Seepz S.O",
      label: "Seepz S.O",
      price: 1000,
    },
  ],
  400097: [
    {
      value: "Malad East S.O.",
      label: "Malad East S.O.",
      price: 900,
    },
    {
      value: "Rani Sati Marg S.O",
      label: "Rani Sati Marg S.O",
      price: 900,
    },
  ],
  400098: [
    {
      value: "Vidyanagari S.O",
      label: "Vidyanagari S.O",
      price: 800,
    },
  ],
  400099: [
    {
      value: "Sahargaon B.O",
      label: "Sahargaon B.O",
      price: 800,
    },
    {
      value: "Airport S.O. (Mumbai)",
      label: "Airport S.O. (Mumbai)",
      price: 800,
    },
    {
      value: "International Airport S.O.",
      label: "International Airport S.O.",
      price: 800,
    },
    {
      value: "Sahar P & T Colony S.O.",
      label: "Sahar P & T Colony S.O.",
      price: 800,
    },
  ],
  400101: [
    {
      value: "Kandivali East S.O.",
      label: "Kandivali East S.O.",
      price: 1000,
    },
    {
      value: "Kandivali East Extn Counter",
      label: "Kandivali East Extn Counter",
      price: 1000,
    },
  ],
  400102: [
    {
      value: "Jogeshwari West S.O.",
      label: "Jogeshwari West S.O.",
      price: 800,
    },
    {
      value: "Oshiwara S.O",
      label: "Oshiwara S.O",
      price: 900,
    },
  ],
  400103: [
    {
      value: "Mandapeshwar S.O",
      label: "Mandapeshwar S.O",
      price: 900,
    },
  ],
  400104: [
    {
      value: "Goregaon S.O. (Mumbai)",
      label: "Goregaon S.O. (Mumbai)",
      price: 800,
    },
    {
      value: "Motilal Nagar S.O",
      label: "Motilal Nagar S.O",
      price: 800,
    },
    {
      value: "Bangur Nagar S.O",
      label: "Bangur Nagar S.O",
      price: 800,
    },
    {
      value: "Goregaon R.S. S.O.",
      label: "Goregaon R.S. S.O.",
      price: 800,
    },
  ],
};

export const behaviouralTrainingOptions = [
  {
    value: "3 sessions",
  },
  {
    value: "4 sessions",
  },
  {
    value: "7 sessions",
  },
  {
    value: "11 sessions",
  },
];

export const SERVICE_OPTIONS = {
  VET: "vet",
  TRAINING: "pet_trainers",
  GROOMING: "pet_grooming",
  BOARDING: "pet_boarders",
  SITTING: "pet_sitters",
  WALKING: "dog_walking",
  INSURANCE: "dog_insurance",
  PATHOLOGY: "pathology",
};

export const TRAINING_OPTIONS = {
  behavioralTrainingThree: "Behavioral 3 sessions",
  behavioralTrainingSix: "Behavioral 6 sessions",
  behavioralTrainingTwelve: "Behavioral 12 sessions",
  firstTrainingTwelve: "First Training 12 sessions",
  firstTrainingSix: "First Training 6 sessions",
  firstTrainingThree: "First Training 3 sessions",
  reTrainingTwelve: "Retraining 12 sessions",
  reTrainingSix: "Retraining 6 sessions",
  reTrainingThree: "Retraining 3 sessions",
  obedienceTrainingThree: "Obedience 3 sessions",
  obedienceTrainingSix: "Obedience 6 sessions",
  obedienceTrainingTwelve: "Obedience 12 sessions",
};

export const SERVICES_TEXT = {
  vet: "Veterinarian",
  pet_trainers: "Training",
  pet_grooming: "Grooming",
  pet_boarders: "Boarding",
  pet_sitters: "Sitting",
  dog_walking: "Dog Walking",
  pathology: "Pathology",
  insurance :"Insurance"
};

export const SERVICES_TEXT_IMAGE = {
  vet: Veterinarian,
  pet_trainers: training,
  pet_grooming: Grooming,
  pet_boarders: Boarding,
  pet_sitters: Sitting,
  dog_walking: Walking,
  pathology: pathology,
  insurance: Insurance,
};

export const ServiceOptions = [
  {
    image: train,
    value: SERVICE_OPTIONS.TRAINING,
    title: "Training",
    advt: false
  },
  {
    image: groom,
    value: SERVICE_OPTIONS.GROOMING,
    title: "Grooming",
    advt: false
  },
  {
    image: board,
    value: SERVICE_OPTIONS.BOARDING,
    title: "Boarding",
    advt: false
  },
  {
    image: dogwalk,
    value: SERVICE_OPTIONS.WALKING,
    title: "Dog Walking",
    advt: false
  },
  {
    image: sit,
    value: SERVICE_OPTIONS.SITTING,
    title: "Sitting",
    advt: false
  },
  {
    image: vet,
    value: SERVICE_OPTIONS.VET,
    title: "Veterinarian",
    advt: false
  },
  {
    image: Insurance,
    value: SERVICE_OPTIONS.INSURANCE,
    title: "Pet Insurance",
    advt: true
  },
  {
    image: pathology,
    value: SERVICE_OPTIONS.PATHOLOGY,
    title: "Pathology",
    advt: false
  },
];

export const VetServicesOptions = [
  {
    image: vet,
    title: "Vaccination",
    value: "vaccination",
  },
  {
    image: board,
    title: "Deworming",
    value: "deworming",
  },
  {
    image: board,
    title: "Accident / Minor injury",
    value: "accidentInjury",
  },
  {
    image: groom,
    title: "General Checkup",
    value: "generalCheckup",
  },
  {
    image: board,
    title: "Follow up visit",
    value: "vistation",
  },
  {
    image: board,
    title: "ILL health",
    value: "illHealth",
  },
];

export const ClientVetService = [
  {
    image: vet,
    title: "Dentist",
    value: "dentist",
  },
  {
    image: vet,
    title: "Optician",
    value: "optician",
  },
];

export const INSURANCE_OPTIONS = [
  {
    id: 1,
    title: "FUTURE GENERALI TOTAL INSURANCE SOLUTIONS",
    image: LGLOGO,
    premiun: 488,
    typeOfInsurance: [
      {
        title: "Standard Coverage",
        plane: [
          "Surgery and Treatment",
          "Terminal ILLness Cover",
          "Pre-hospitalization and post-hospitalization Expenses",
          "Death and Funeral",
        ],
      },
      {
        title: "Add-On Coverage",
        plane: [
          "Third-Party Liability",
          "Emergency Pet Minding",
          "Lost and Stolen Cover",
          "Vertinary Consulting and Doctor on Call",
        ],
      },
    ],
  },
];

export const INSURANCE_TYPE = [
  {
    title: "Third Party Liability Cover",
    price: 10,
    value: "Third_Party_Liability_Cover",
  },
  {
    title: "Lost and Stolen Cover",
    price: 65,
    value: "Lost_and_Stolen_Cover",
  },
  {
    title: "Emergency Pet Minding",
    price: 20,
    value: "Emergency_Pet_Minding",
  },
  {
    title: "Vet Consultation & Doctor on Call",
    price: 32,
    value: "Vet_Consultation_&_Doctor_on_Call",
  },
];

export const VACCINATIONS_PROVIDED = [
  {
    title: "Rabies",
    value: "Rabies",
  },
  {
    title: "Leptospirosis",
    value: "Leptospirosis",
  },
  {
    title: "Distemper",
    value: "Distemper",
  },
  {
    title: "Parainfluenza",
    value: "Parainfluenza",
  },
  {
    title: "Hepatitis",
    value: "Hepatitis",
  },
  {
    title: "Parvovirus",
    value: "Parvovirus",
  },
  {
    title: "Adeno virus",
    value: "Adeno_virus",
  },
  {
    title: "Corona",
    value: "Corona",
  },
];

export const PATHOLOGY_OPTIONS = [
  {
    title: "Complete Blood Count + Parasites",
    detail:
      "Complete Blood Count ( CBC : Hb, RBC, WBC, Differential WBC, PCV, Platelet count, PCV, MCV, MCH, MCHC, Cell Abnormalities) + Parasites",
    value: "Complete Blood Count + Parasites",
    price: 400,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Blood smear examination for hemoprotozoons / blood parasites",
    detail: "Blood smear examination for hemoprotozoons / blood parasites",
    value: "Blood smear examination for hemoprotozoons / blood parasites",
    price: 400,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Reticulocyte Count with retic index and absolute reticulocyte count",
    detail: "Reticulocyte Count with retic index and absolute reticulocyte count",
    value: "Reticulocyte Count with retic index and absolute reticulocyte count",
    price: 400,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Erythrocyte Sedimentation Rate ( ESR )",
    detail: "Erythrocyte Sedimentation Rate ( ESR )",
    value: "Erythrocyte Sedimentation Rate ( ESR )",
    price: 100,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Platelet Count (Machine/manual)",
    detail: "Platelet Count (Machine/manual)",
    value: "Platelet Count (Machine/manual)",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Liver Function Tests (Bilirubin, SGOT, SGPT, SAP, Proteins, Albumin)",
    detail: "Liver Function Tests (Bilirubin, SGOT, SGPT, SAP, Proteins, Albumin)",
    value: "Liver Function Tests (Bilirubin, SGOT, SGPT, SAP, Proteins, Albumin)",
    price: 600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Bilirubin ( Total and Direct ) ",
    detail: "Serum Bilirubin ( Total and Direct )",
    value: "Serum Bilirubin ( Total and Direct )",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Glutamic Oxaloacetic Transaminase ( SGOT/AST)",
    detail: "Serum Glutamic Oxaloacetic Transaminase ( SGOT/AST)",
    value: "Serum Glutamic Oxaloacetic Transaminase ( SGOT/AST)",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Glutamic Pyruvic Transaminase (SGPT/ALT)",
    detail: "Serum Glutamic Pyruvic Transaminase (SGPT/ALT)",
    value: "Serum Glutamic Pyruvic Transaminase (SGPT/ALT)",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Alkaline Phosphatase (ALP)",
    detail: "Serum Alkaline Phosphatase (ALP)",
    value: "Serum Alkaline Phosphatase (ALP)",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Proteins (Total proteins, Albumin)",
    detail: "Serum Proteins (Total proteins, Albumin)",
    value: "Serum Proteins (Total proteins, Albumin)",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Uric Acid",
    detail: "Serum Uric Acid",
    value: "Serum Uric Acid",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "GGT (Gamma Glutamyl Transferase )",
    detail: "GGT (Gamma Glutamyl Transferase )",
    value: "GGT (Gamma Glutamyl Transferase )",
    price: 450,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Cholesterol",
    detail: "Serum Cholesterol",
    value: "Serum Cholesterol",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Triglycerides",
    detail: "Serum Triglycerides",
    value: "Serum Triglycerides",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Creatinine Phosphokinase (CPK)",
    detail: "Creatinine Phosphokinase (CPK)",
    value: "Creatinine Phosphokinase (CPK)",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Lactate Dehydrogenase (LDH)",
    detail: "Lactate Dehydrogenase (LDH)",
    value: "Lactate Dehydrogenase (LDH)",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Bile Acid – Total",
    detail: "Bile Acid – Total",
    value: "Bile Acid – Total",
    price: 1800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Blood Ammonia Level",
    detail: "Blood Ammonia Level",
    value: "Blood Ammonia Level",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Acid Phosphatase",
    detail: "Serum Acid Phosphatase",
    value: "Serum Acid Phosphatase",
    price: 700,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Kidney Function Tests : Blood Urea Nitrogen ( BUN ) ",
    detail: "Kidney Function Tests : Blood Urea Nitrogen ( BUN ) ",
    value: "Kidney Function Tests : Blood Urea Nitrogen ( BUN ) ",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Kidney Function Tests :  Creatinine",
    detail: "Kidney Function Tests :  Creatinine",
    value: "Kidney Function Tests :  Creatinine",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Phosphorous/ P",
    detail: "Serum Phosphorous/ P",
    value: "Serum Phosphorous/ P",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Calcium/ Ca",
    detail: "Serum Calcium/ Ca",
    value: "Serum Calcium/ Ca",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Electrolytes ( Sodium/Na , Potassium/K, Chlorides/Cl)",
    detail: "Serum Electrolytes ( Sodium/Na , Potassium/K, Chlorides/Cl)",
    value: "Serum Electrolytes ( Sodium/Na , Potassium/K, Chlorides/Cl)",
    price: 400,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urinalysis",
    detail: "Urinalysis",
    value: "Urinalysis",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urinary Proteins/Urine Creatinine ratio",
    detail: "Urinary Proteins/Urine Creatinine ratio",
    value: "Urinary Proteins/Urine Creatinine ratio",
    price: 500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urinary Proteins",
    detail: "Urinary Proteins",
    value: "Urinary Proteins",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urine Creatinine",
    detail: "Urine Creatinine",
    value: "Urine Creatinine",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urine Calcium",
    detail: "Urine Calcium",
    value: "Urine Calcium",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urine Phosphorous",
    detail: "Urine Phosphorous",
    value: "Urine Phosphorous",
    price: 300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urine Electrolytes",
    detail: "Urine Electrolytes",
    value: "Urine Electrolytes",
    price: 500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Pancreatic function Tests : Serum Amylase",
    detail: "Pancreatic function Tests : Serum Amylase",
    value: "Pancreatic function Tests : Serum Amylase",
    price: 500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Pancreatic function Tests : Serum Lipase",
    detail: "Pancreatic function Tests : Serum Lipase",
    value: "Pancreatic function Tests : Serum Lipase",
    price: 500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Blood Sugar",
    detail: "Blood Sugar",
    value: "Blood Sugar",
    price: 200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Canine / Feline ",
    detail: "Canine / Feline ",
    value: "Canine / Feline ",
    price: 2600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Specific Pancreatic Lipase (CPL)",
    detail: "Specific Pancreatic Lipase (CPL)",
    value: "Specific Pancreatic Lipase (CPL)",
    price: 2600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Fructosamine",
    detail: "Fructosamine",
    value: "Fructosamine",
    price: 1800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Stool Examination",
    detail: "Stool Examination",
    value: "Stool Examination",
    price: 400,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Skin Scrapings Examination – for mites & dermatophytes (KOH mounts)",
    detail: "Skin Scrapings Examination – for mites & dermatophytes (KOH mounts)",
    value: "Skin Scrapings Examination – for mites & dermatophytes (KOH mounts)",
    price: 400,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Vaginal cytology for assessment of stage of estrus",
    detail: "Vaginal cytology for assessment of stage of estrus",
    value: "Vaginal cytology for assessment of stage of estrus",
    price: 400,
    isPackage: false,
    isTest: true,
  },
  {
    title:
      "Impression smear cytology (skin/ear/others): KOH mount, Giemsa, Gram & Fungal stain",
    detail:
      "Impression smear cytology (skin/ear/others): KOH mount, Giemsa, Gram & Fungal stain",
    value:
      "Impression smear cytology (skin/ear/others): KOH mount, Giemsa, Gram & Fungal stain",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title:
      "Transudates / Exudates (Cell counts, Cytology, Proteins, Sugar, OBT, Rivalta)",
    detail:
      "Transudates / Exudates (Cell counts, Cytology, Proteins, Sugar, OBT, Rivalta)",
    value:
      "Transudates / Exudates (Cell counts, Cytology, Proteins, Sugar, OBT, Rivalta)",
    price: 1000,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urinary Calculi: Chemical Analysis",
    detail: "Urinary Calculi: Chemical Analysis",
    value: "Urinary Calculi: Chemical Analysis",
    price: 1500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Urine Cortisol",
    detail: "Urine Cortisol",
    value: "Urine Cortisol",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Phenobarbitone Level",
    detail: "Phenobarbitone Level",
    value: "Phenobarbitone Level",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum protein Electrophoresis",
    detail: "Serum protein Electrophoresis",
    value: "Serum protein Electrophoresis",
    price: 1800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Fine Needle Aspiration Cytology (FNAC)",
    detail: "Fine Needle Aspiration Cytology (FNAC)",
    value: "Fine Needle Aspiration Cytology (FNAC)",
    price: 800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Histopathology (Small Tissues up to 8 cm x 8 cm x 8 cm )",
    detail: "Histopathology (Small Tissues up to 8 cm x 8 cm x 8 cm )",
    value: "Histopathology (Small Tissues up to 8 cm x 8 cm x 8 cm )",
    price: 1500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Histopathology (Medium Tissues up to 15 cm x 15 cm x 15 cm)",
    detail: "Histopathology (Medium Tissues up to 15 cm x 15 cm x 15 cm)",
    value: "Histopathology (Medium Tissues up to 15 cm x 15 cm x 15 cm)",
    price: 2000,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Histopathology (Big Tissues: More than 15 cm x 15 cm x 15 cm)",
    detail: "Histopathology (Big Tissues: More than 15 cm x 15 cm x 15 cm)",
    value: "Histopathology (Big Tissues: More than 15 cm x 15 cm x 15 cm)",
    price: 3000,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Post Mortem Histopathology (Up to 6 organs)",
    detail: "Post Mortem Histopathology (Up to 6 organs)",
    value: "Post Mortem Histopathology (Up to 6 organs)",
    price: 4000,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Post Mortem Histopathology (Up to 10 organs)",
    detail: "Post Mortem Histopathology (Up to 10 organs)",
    value: "Post Mortem Histopathology (Up to 10 organs)",
    price: 6000,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Gram’s Stain",
    detail: "Gram’s Stain",
    value: "Gram’s Stain",
    price: 700,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Acid Fast Stain",
    detail: "Acid Fast Stain",
    value: "Acid Fast Stain",
    price: 700,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Alcian blue stain",
    detail: "Alcian blue stain",
    value: "Alcian blue stain",
    price: 700,
    isPackage: false,
    isTest: true,
  },
  {
    title:
      "Bacterial Culture (aerobic) , Organism identification & Antibiotic Sensitivity",
    detail:
      "Bacterial Culture (aerobic) , Organism identification & Antibiotic Sensitivity",
    value:
      "Bacterial Culture (aerobic) , Organism identification & Antibiotic Sensitivity",
    price: 1500,
    isPackage: false,
    isTest: true,
  },
  {
    title:
      "Bacterial Culture (anaerobic) , Organism identification & Antibiotic Sensitivity",
    detail:
      "Bacterial Culture (anaerobic) , Organism identification & Antibiotic Sensitivity",
    value:
      "Bacterial Culture (anaerobic) , Organism identification & Antibiotic Sensitivity",
    price: 2000,
    isPackage: false,
    isTest: true,
  },
  {
    title:
      "Blood bacterial Culture (aerobic), Organism identification & Antibiotic Sensitivity Tests",
    detail:
      "Blood bacterial Culture (aerobic), Organism identification & Antibiotic Sensitivity Tests",
    value:
      "Blood bacterial Culture (aerobic), Organism identification & Antibiotic Sensitivity Tests",
    price: 2000,
    isPackage: false,
    isTest: true,
  },
  {
    title:
      "Blood bacterial Culture (anaerobic), Organism identification & Antibiotic Sensitivity Tests",
    detail:
      "Blood bacterial Culture (anaerobic), Organism identification & Antibiotic Sensitivity Tests",
    value:
      "Blood bacterial Culture (anaerobic), Organism identification & Antibiotic Sensitivity Tests",
    price: 2200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Fungal Culture and Identification",
    detail: "Fungal Culture and Identification",
    value: "Fungal Culture and Identification",
    price: 1500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Fungal Culture and Identification & antibiotic sensitivity",
    detail: "Fungal Culture and Identification & antibiotic sensitivity",
    value: "Fungal Culture and Identification & antibiotic sensitivity",
    price: 2200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Insulin",
    detail: "Insulin",
    value: "Insulin",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Estrogen",
    detail: "Estrogen",
    value: "Estrogen",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Progesterone",
    detail: "Progesterone",
    value: "Progesterone",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Testosterone",
    detail: "Testosterone",
    value: "Testosterone",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Cortisol",
    detail: "Cortisol",
    value: "Cortisol",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Thyroid Function (T3, T4, TSH)",
    detail: "Thyroid Function (T3, T4, TSH)",
    value: "Thyroid Function (T3, T4, TSH)",
    price: 800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "T3",
    detail: "T3",
    value: "T3",
    price: 450,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Free T3",
    detail: "Free T3",
    value: "Free T3",
    price: 450,
    isPackage: false,
    isTest: true,
  },
  {
    title: "T4",
    detail: "T4",
    value: "T4",
    price: 450,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Free T4",
    detail: "Free T4",
    value: "Free T4",
    price: 450,
    isPackage: false,
    isTest: true,
  },
  {
    title: "TSH",
    detail: "TSH",
    value: "TSH",
    price: 450,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Parathyroid Hormone",
    detail: "Parathyroid Hormone",
    value: "Parathyroid Hormone",
    price: 1800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Prothrombin time (PT)",
    detail: "Prothrombin time (PT)",
    value: "Prothrombin time (PT)",
    price: 500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Activated partial Thromboplastin Time (APTT)",
    detail: "Activated partial Thromboplastin Time (APTT)",
    value: "Activated partial Thromboplastin Time (APTT)",
    price: 500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Fibrinogen",
    detail: "Fibrinogen",
    value: "Fibrinogen",
    price: 1200,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Fibrinogen Degradation Products (FDP)",
    detail: "Fibrinogen Degradation Products (FDP)",
    value: "Fibrinogen Degradation Products (FDP)",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Autoa gglutination studies at three different temperature levels",
    detail: "Autoa gglutination studies at three different temperature levels",
    value: "Autoa gglutination studies at three different temperature levels",
    price: 1000,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Blood Cross Match : Major and Minor at variable temperatures",
    detail: "Blood Cross Match : Major and Minor at variable temperatures",
    value: "Blood Cross Match : Major and Minor at variable temperatures",
    price: 1800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP 4 DX (Ehrlichia, Borrehlia, Anaplasma & Heartworm )",
    detail: "SNAP 4 DX (Ehrlichia, Borrehlia, Anaplasma & Heartworm )",
    value: "SNAP 4 DX (Ehrlichia, Borrehlia, Anaplasma & Heartworm )",
    price: 1500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP 3 DX (Ehrlichia, Babesi gibsoni, Anaplasma )",
    detail: "SNAP 3 DX (Ehrlichia, Babesi gibsoni, Anaplasma )",
    value: "SNAP 3 DX (Ehrlichia, Babesi gibsoni, Anaplasma )",
    price: 1800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP test for detection of Canine Distemper infection",
    detail: "SNAP test for detection of Canine Distemper infection",
    value: "SNAP test for detection of Canine Distemper infection",
    price: 1300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP test for detection of Canine Parvovirus infection",
    detail: "SNAP test for detection of Canine Parvovirus infection",
    value: "SNAP test for detection of Canine Parvovirus infection",
    price: 1300,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP test for detection of Feline Panleukopenia / Parvovirus infection",
    detail: "SNAP test for detection of Feline Panleukopenia / Parvovirus infection",
    value: "SNAP test for detection of Feline Panleukopenia / Parvovirus infection",
    price: 1800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP test for detection of Feline Immunodeficiency Virus infection",
    detail: "SNAP test for detection of Feline Immunodeficiency Virus infection",
    value: "SNAP test for detection of Feline Immunodeficiency Virus infection",
    price: 1500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP test for detection of Feline Leukaemia virus infection",
    detail: "SNAP test for detection of Feline Leukaemia virus infection",
    value: "SNAP test for detection of Feline Leukaemia virus infection",
    price: 1500,
    isPackage: false,
    isTest: true,
  },
  {
    title:
      "SNAP test for detection of Feline Immunodeficiency Virus & Feline Leukaemia virus infection (Combination test)",
    detail:
      "SNAP test for detection of Feline Immunodeficiency Virus & Feline Leukaemia virus infection (Combination test)",
    value:
      "SNAP test for detection of Feline Immunodeficiency Virus & Feline Leukaemia virus infection (Combination test)",
    price: 2400,
    isPackage: false,
    isTest: true,
  },
  {
    title: "SNAP test for detection of Toxoplasma antibodies",
    detail: "SNAP test for detection of Toxoplasma antibodies",
    value: "SNAP test for detection of Toxoplasma antibodies",
    price: 1300,
    isPackage: false,
    isTest: true,
  },

  {
    title: "PCR diagnosis of Bartonella",
    detail: "PCR diagnosis of Bartonella",
    value: "PCR diagnosis of Bartonella",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Tick born diseases: Babesia gibsoni",
    detail: "PCR diagnosis of Tick born diseases: Babesia gibsoni",
    value: "PCR diagnosis of Tick born diseases: Babesia gibsoni",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Tick born diseases: Babesia canis",
    detail: "PCR diagnosis of Tick born diseases: Babesia canis",
    value: "PCR diagnosis of Tick born diseases: Babesia canis",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Tick born diseases: Ehrlichia canis",
    detail: "PCR diagnosis of Tick born diseases: Ehrlichia canis",
    value: "PCR diagnosis of Tick born diseases: Ehrlichia canis",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Tick born diseases: Hepatozoon canis",
    detail: "PCR diagnosis of Tick born diseases: Hepatozoon canis",
    value: "PCR diagnosis of Tick born diseases: Hepatozoon canis",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Tick born diseases: Anaplasma",
    detail: "PCR diagnosis of Tick born diseases: Anaplasma",
    value: "PCR diagnosis of Tick born diseases: Anaplasma",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Feline Panleukopenia",
    detail: "PCR diagnosis of feline diseases: Feline Panleukopenia",
    value: "PCR diagnosis of feline diseases: Feline Panleukopenia",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Feline Leukaemia virus",
    detail: "PCR diagnosis of feline diseases: Feline Leukaemia virus",
    value: "PCR diagnosis of feline diseases: Feline Leukaemia virus",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Feline Immunodeficiency Virus",
    detail: "PCR diagnosis of feline diseases: Feline Immunodeficiency Virus",
    value: "PCR diagnosis of feline diseases: Feline Immunodeficiency Virus",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Feline Infectious Peritonitis",
    detail: "PCR diagnosis of feline diseases: Feline Infectious Peritonitis",
    value: "PCR diagnosis of feline diseases: Feline Infectious Peritonitis",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Feline Coronavirus",
    detail: "PCR diagnosis of feline diseases: Feline Coronavirus",
    value: "PCR diagnosis of feline diseases: Feline Coronavirus",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Feline Herpes Virus",
    detail: "PCR diagnosis of feline diseases: Feline Herpes Virus",
    value: "PCR diagnosis of feline diseases: Feline Herpes Virus",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Toxoplasma",
    detail: "PCR diagnosis of feline diseases: Toxoplasma",
    value: "PCR diagnosis of feline diseases: Toxoplasma",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Mycoplasma",
    detail: "PCR diagnosis of feline diseases: Mycoplasma",
    value: "PCR diagnosis of feline diseases: Mycoplasma",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Chlamydia felis",
    detail: "PCR diagnosis of feline diseases: Chlamydia felis",
    value: "PCR diagnosis of feline diseases: Chlamydia felis",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Hemobartonella",
    detail: "PCR diagnosis of feline diseases: Hemobartonella",
    value: "PCR diagnosis of feline diseases: Hemobartonella",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of feline diseases: Calcivirus",
    detail: "PCR diagnosis of feline diseases: Calcivirus",
    value: "PCR diagnosis of feline diseases: Calcivirus",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Toxoplasma IgM & IgG: Quantitative",
    detail: "Toxoplasma IgM & IgG: Quantitative",
    value: "Toxoplasma IgM & IgG: Quantitative",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Leptospira IgM : Quantitative",
    detail: "Leptospira IgM : Quantitative",
    value: "Leptospira IgM : Quantitative",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Leptospira IgG : Quantitative",
    detail: "Leptospira IgG : Quantitative",
    value: "Leptospira IgG : Quantitative",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis for Leptospira sp.",
    detail: "PCR diagnosis for Leptospira sp.",
    value: "PCR diagnosis for Leptospira sp.",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis for Toxoplasma sp",
    detail: "PCR diagnosis for  Toxoplasma sp",
    value: "PCR diagnosis for  Toxoplasma sp",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Iron Studies ( Iron + Transferin saturation + TIBC)",
    detail: "Serum Iron Studies ( Iron + Transferin saturation + TIBC)",
    value: "Serum Iron Studies ( Iron + Transferin saturation + TIBC)",
    price: 900,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Magnesium",
    detail: "Serum Magnesium",
    value: "Serum Magnesium",
    price: 700,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Serum Biocarbonates",
    detail: "Serum Biocarbonates",
    value: "Serum Biocarbonates",
    price: 700,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Zinc",
    detail: "Zinc",
    value: "Zinc",
    price: 2600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Lead",
    detail: "Lead",
    value: "Lead",
    price: 2600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Vitamin D3 (25 Hydroxy)",
    detail: "Vitamin D3 (25 Hydroxy)",
    value: "Vitamin D3 (25 Hydroxy)",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Vitamin B9 ( Folic Acid)",
    detail: "Vitamin B9 ( Folic Acid)",
    value: "Vitamin B9 ( Folic Acid)",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Vitamin B12 (Cyanocobalamine)",
    detail: "Vitamin B12 (Cyanocobalamine)",
    value: "Vitamin B12 (Cyanocobalamine)",
    price: 1600,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Vitamin B1 (Thiamin)",
    detail: "Vitamin B1 (Thiamin)",
    value: "Vitamin B1 (Thiamin)",
    price: 3800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Vitamin B2( Riboflavin)",
    detail: "Vitamin B2( Riboflavin)",
    value: "Vitamin B2( Riboflavin)",
    price: 3800,
    isPackage: false,
    isTest: true,
  },
  {
    title: "Vitamin B6 ( Pyridoxine)",
    detail: "Vitamin B6 ( Pyridoxine)",
    value: "Vitamin B6 ( Pyridoxine)",
    price: 3800,
    isPackage: false,
    isTest: true,
  },

  {
    title: "PCR diagnosis of Canine Distemper",
    detail: "PCR diagnosis of Canine Distemper",
    value: "PCR diagnosis of Canine Distemper",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Parvovirus",
    detail: "PCR diagnosis of Parvovirus",
    value: "PCR diagnosis of Parvovirus",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Brucellosis",
    detail: "PCR diagnosis of Brucellosis",
    value: "PCR diagnosis of Brucellosis",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Giardia",
    detail: "PCR diagnosis of Giardia",
    value: "PCR diagnosis of Giardia",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of Heartworm",
    detail: "PCR diagnosis of Heartworm",
    value: "PCR diagnosis of Heartworm",
    price: 4500,
    isPackage: false,
    isTest: true,
  },
  {
    title: "PCR diagnosis of  Bartonella",
    detail: "PCR diagnosis of  Bartonella",
    value: "PCR diagnosis of  Bartonella",
    price: 4500,
    isPackage: true,
    isTest: false,
  },
];

export const PATHOLOGY_PACKAGES = [
  {
    title: "Reno basic",
    detail:
      "Complete Blood Count ( CBC : Hb, RBC, WBC, Differential WBC, PCV, Platelet count, PCV, MCV, MCH, MCHC, Cell Abnormalities), Blood Parasites ,ESR,Liver Function Tests ( Bilirubin, SGOT, SGPT, SAP, Proteins, Uric Acid, GGT ) ,Renal profile ( BUN , Creatinine, Electrolytes , Calcium, Phosphorous ) ",
    value: "Reno basic",
    price: 1600,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Reno + Card",
    detail:
      "Complete Blood Count ( CBC : Hb, RBC, WBC, Differential WBC, PCV, Platelet count, PCV, MCV, MCH, MCHC, Cell Abnormalities),Blood Parasites,ESR,Liver Function Tests ( Bilirubin, SGOT, SGPT, SAP, Proteins, Uric Acid, GGT ) ,Renal profile ( BUN , Creatinine, Electrolytes , Calcium, Phosphorous ),Cardiac Function Tests ( CPK, LDH ),Lipid Profile ( Cholesterol, Triglycerides )",
    value: "Reno + Card",
    price: 2500,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Comprehensive",
    detail:
      "Complete Blood Count ( CBC : Hb, RBC, WBC, Differential WBC, PCV, Platelet count, PCV, MCV, MCH, MCHC, Cell Abnormalities), Blood Parasites , ESR, Liver Function Tests (Bilirubin, SGOT, SGPT, SAP, Proteins, Uric Acid, GGT), Renal profile (BUN, Creatinine, Electrolytes, Calcium, Phosphorous), Cardiac Function Tests (CPK, LDH), Lipid Profile ( Cholesterol, Triglycerides ), Pancreatic Function Tests ( Amylase, Lipase, Blood Sugar )",
    value: "Comprehensive",
    price: 3500,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Basic",
    detail:
      "Complete Blood Count ( CBC : Hb, RBC, WBC, Differential WBC, PCV, Platelet count, PCV, MCV, MCH, MCHC, Cell Abnormalities) ,Blood Parasites , Liver Function Tests ( Bilirubin, SGOT, SGPT, SAP, Proteins), Kidney Function Tests ( BUN, Creatinine)",
    value: "Basic",
    price: 1000,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Primary profile",
    detail:
      "Complete Blood Count ( CBC : Hb, RBC, WBC, Differential WBC, PCV, Platelet count, PCV, MCV, MCH, MCHC, Cell Abnormalities),Blood Parasites, SGPT, Creatinine",
    value: "Primary profile",
    price: 800,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Thyroid profile",
    detail: "Thyroid Function Profile( T3, T4, TSH, Free T3, Free T4)",
    value: "Thyroid profile",
    price: 1650,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Basic + Thyro",
    detail:
      "Complete Blood Count ( CBC : Hb, RBC, WBC, Differential WBC, PCV, Platelet count, PCV, MCV, MCH, MCHC, Cell Abnormalities) , Blood Parasites,Liver Function Tests ( Bilirubin, SGOT, SGPT, SAP, Proteins) ,Kidney Function Tests ( BUN , Creatinine) ,Thyroid Function Tests ( T3, T4, TSH)",
    value: "Basic + Thyro",
    price: 1500,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Comprehensive + Thyro",
    detail:
      "Complete Blood Count ( CBC : Hb, RBC, WBC, Differential WBC, PCV,Platelet count, PCV, MCV, MCH, MCHC, Cell Abnormalities),Blood Parasites ,ESR ,Liver Function Tests (Bilirubin, SGOT, SGPT, SAP, Proteins, Uric Acid, GGT) , Renal profile (BUN, Creatinine, Electrolytes, Calcium, Phosphorous) , Cardiac Function Tests (CPK, LDH), Lipid Profile ( Cholesterol, Triglycerides ) , Pancreatic Function Tests ( Amylase, Lipase, Blood Sugar ), Thyroid Function Profile( T3, T4, TSH)",
    value: "Comprehensive + Thyro",
    price: 4000,
    isPackage: true,
    isTest: false,
  },
  {
    title: "PCR diagnosis of Tick born diseases:",
    detail:
      "Combo Test inclusive of Babesia gibsoni, Babesia canis, Babesia vogeli, Babesia rossi, Ehrlichia canis, Hepatozoon canis, Anaplasma sp. , Trypanosoma evansi",
    value: "PCR diagnosis of Tick born diseases:",
    price: 12500,
    isPackage: true,
    isTest: false,
  },

  {
    title: "PCR diagnosis of feline diseases:",
    detail:
      "Combo Test inclusive of Feline Panleukopenia, Feline Leukaemia virus, Feline Immunodeficiency Virus, Feline Infectious Peritonitis, Feline Coronavirus, Feline Herpes Virus, Toxoplasma, Mycoplasma, Chlamydia felis,Hemobartonella, Calcivirus,",
    value: "PCR diagnosis of feline diseases: ",
    price: 12500,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Coagulation profile inclusive of",
    detail:
      "Prothrombin time (PT), Activated partial Thromboplastin Time (APTT), Fibrinogen, Fibrinogen Degradation Products (FDP)",
    value: "Coagulation profile inclusive of",
    price: 3200,
    isPackage: true,
    isTest: false,
  },
  {
    title: "Mineral profile:",
    detail:
      "Aluminium, Antimony, Arsenic, Baarium, Beryllium Bismuth, Cadmium, Caesium Chromium, Cobalt, Lead, Molybdenum, Manganese, Mercury, Nickel, Selenium, Silver, Strontium,, Thallium, TIN, Uranium, Vanadium,",
    value: "Mineral profile:",
    price: 8500,
    isPackage: false,
    isTest: true,
  },
];

export const GROOMING_OPTIONS = [
  {
    image: Paw,
    title: "Paw Hair Trimming",
    value: "pawTriming",
  },
  {
    image: EarCleaning,
    title: "Ear Cleaning",
    value: "earCleaning",
  },
  {
    image: EarCleaning,
    title: "Ear Hair Trimming",
    value: "earHairTriming",
  },
  {
    image: Nails,
    title: "Nail Trimming",
    value: "nailTrimming",
  },
];

export const profileOptions = [
  {
    image: Casper,
    title: "Casper",
    subtitle: "Pug | 3 year Old",
    gender: "male",
  },
  {
    image: Rosy,
    title: "Rosy",
    subtitle: "Persian | 5 year Old",
    gender: "male",
  },
];

export const TimeOptions = [
  {
    label: "07:00 am",
    value: 7,
    availability: true,
  },
  {
    label: "08:00 am",
    value: 8,
    availability: true,
  },
  {
    label: "09:00 am",
    value: 9,
    availability: true,
  },
  {
    label: "10:00 am",
    value: 10,
    availability: true,
  },
  {
    label: "11:00 am",
    value: 11,
    availability: true,
  },
  {
    label: "12:00 pm",
    value: 12,
    availability: true,
  },
  {
    label: "01:00 pm",
    value: 13,
    availability: true,
  },
  {
    label: "02:00 pm",
    value: 14,
    availability: true,
  },
  {
    label: "03:00 pm",
    value: 15,
    availability: true,
  },
  {
    label: "04:00 pm",
    value: 16,
    availability: true,
  },
  {
    label: "05:00 pm",
    value: 17,
    availability: true,
  },
  {
    label: "06:00 pm",
    value: 18,
    availability: true,
  },
  {
    label: "07:00 pm",
    value: 19,
    availability: true,
  },
  {
    label: "08:00 pm",
    value: 20,
    availability: true,
  },
  {
    label: "09:00 pm",
    value: 21,
    availability: true,
  },
  {
    label: "10:00 pm",
    value: 22,
    availability: true,
  },
  // {
  //   label: '11:00 pm',
  //   value: 23,
  //   availability: true,
  // },
];

export const TRAINING_TYPE = [
  { value: "reTraining", label: "Retraining" },
  { value: "behavioralTraining", label: "Special Behavioural" },
  { value: "obidienceTraining", label: "Obedience" },
];

export const SESSIONS = [
  { value: "Three", label: "1 month (12 Sessions)" },
  { value: "Six", label: "2 months (24 Sessions)" },
  { value: "Twelve", label: "3 months (36 Sessions)" },
  { value: "Forty-eight", label: "4 months (48 Sessions)" },
];

export const SESSIONS_OLDER = [
  { value: "Three", label: "3 Sessions" },
  { value: "Six", label: "6 Sessions" },
  { value: "Nine", label: "9 Sessions" },
  { value: "Twelve", label: "12 Sessions" },
  { value: "Twenty-four", label: "24 Sessions" },
  { value: "Thirty-six", label: "36 Sessions" },
  { value: "Forty-eight", label: "48 Sessions" },
];

export const WEIGHT_OPTIONS = [
  { value: "kg_0_1", label: "0 - 1 Kg" },
  { value: "kg_1_3", label: "1 - 3 Kg" },
  { value: "kg_3_5", label: "3 - 5 Kg" },
  { value: "kg_5_10", label: "5 - 10 Kg" },
  { value: "kg_10_20", label: "10 - 20 Kg" },
  { value: "kg_20_40", label: "20 - 40 Kg" },
  { value: "kg_40", label: "40+ Kg" },
];
export const AGE_OPTIONS = [...Array(50).keys()].map((i) => ({
  value: i,
  label: `${i} year`,
}));
export const MONTH_OPTIONS = [...Array(13).keys()].map((i) => ({
  value: i,
  label: `${i} month`,
}));

export const statusData = [
  {
    title: "All",
    value: "All",
  },

  {
    title: "Job completed",
    value: "Completed",
  },

  {
    title: "Cancelled by you",
    value: "canceled_by_seeker",
  },

  {
    title: "Cancelled by expert",
    value: "canceled_by_provider",
  },
  {
    title: "No show by expert",
    value: "no_show_provider",
  },
  {
    title: "No show by me",
    value: "no_show_seeker",
  },

  {
    title: "Expired",
    value: "Expired",
  },
];

export const SERVICE_STATUS = {
  accepting_offers: "Accepting Offers",
  max_offers_received: "Max Offers Received",
  job_completed: "Job Completed",
  job_started: "Job Started",
  offer_accepted: "Booked",
  time_expired: "Expired",
  offer_time_expired: "Expired",
  request_expired: "Expired",
  canceled_by_seeker: "Cancelled By Me",
  canceled_by_provider: "Cancelled By Expert",
  no_show_seeker: "No Show By Me",
  no_show_provider: "No Show By Expert",
};

export const serviceData = [
  {
    title: "Pathalogy",
    value: "pathology",
  },
  {
    title: "Veterinarian",
    value: "vet",
  },

  {
    title: "Dog walking",
    value: "dog_walking",
  },

  {
    title: "Trainer",
    value: "pet_trainers",
  },

  {
    title: "Grooming",
    value: "pet_grooming",
  },

  {
    title: "Sitting",
    value: "pet_sitters",
  },

  {
    title: "Boarding",
    value: "pet_boarders",
  },
];

export const DATE_OPTIONS = [
  { label: "Newest to Oldest", value: "asce" },
  { label: "Oldest to Newest", value: "dsce" },
];

export const REQ_PET_CARD_OPTIONS = [
  {
    image: Casper,
    name: "Casper",
    subText: "Pug  I  3 Year 5 Month",
    status: "No offer recieved!",
    type: "Grooming",
  },
  {
    image: Casper,
    name: "Casper",
    subText: "Pug  I  3 Year 5 Month",
    status: "Booked",
    type: "Grooming",
  },
  {
    image: Casper,
    name: "Casper",
    subText: "Pug  I  3 Year 5 Month",
    status: "2 Request recieved",
    type: "Grooming",
  },
  {
    image: Casper,
    name: "Casper",
    subText: "Pug  I  3 Year 5 Month",
    status: "Booked",
    type: "Grooming",
  },
];

export const REQ_PROFILE_CARD_OPTIONS = [
  {
    id: 1,
    image: Person1,
    name: "Sunita",
    rate: "₹ 1500",
    rating: "4.5",
    timeAgo: "3 hours ago",
    timeLeft: "1 hour 12 min",
    aboutMe:
      "I grew up with dogs. They give you happiness and they make you smile and laugh. I love dogs! We have a fenced backyard and I love to go for walks. Dogs are awesome and they should live a happy life.",
    images: [cat1, cat2, cat3, dog1, dog2, dog3],
    certificates: [cert1, cert2, cert3, cert4],
    experiences: "3 years",
    otherServices: ["Training", "Boarding"],
    awards: [cert1, cert2, cert3, cert4],
  },
  {
    id: 2,
    image: Person2,
    name: "Rajesh kumar",
    rate: "₹ 1500",
    rating: "4.5",
    timeAgo: "3 hours ago",
    timeLeft: "1 hour 12 min",
    aboutMe:
      "I grew up with dogs. They give you happiness and they make you smile and laugh. I love dogs! We have a fenced backyard and I love to go for walks. Dogs are awesome and they should live a happy life.",
    images: [cat1, cat2, cat3, dog1, dog2, dog3],
    certificates: [cert1, cert2, cert3, cert4],
    experiences: "3 years",
    otherServices: ["Training", "Boarding"],
    awards: [cert1, cert2, cert3, cert4],
  },
  {
    id: 3,
    image: Person1,
    name: "Sunita",
    rate: "₹ 1500",
    rating: "4.5",
    timeAgo: "3 hours ago",
    timeLeft: "1 hour 12 min",
    aboutMe:
      "I grew up with dogs. They give you happiness and they make you smile and laugh. I love dogs! We have a fenced backyard and I love to go for walks. Dogs are awesome and they should live a happy life.",
    images: [cat1, cat2, cat3, dog1, dog2, dog3],
    certificates: [cert1, cert2, cert3, cert4],
    experiences: "3 years",
    otherServices: ["Training", "Boarding"],
    awards: [cert1, cert2, cert3, cert4],
  },
];

export const WALK_OPTIONS = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
];

export const WALK_DURATION = [
  { value: "thirty", label: "30 min" },
  { value: "fortyFive", label: "45 Min" },
  { value: "oneHour", label: "1 Hour" },
];

export const BATHING_OPTIONS = [
  { value: "hygieneBath", label: "Hygiene bath" },
  { value: "tickTreatmentBath", label: "Tick Treatment bath" },
  { value: "miteTreatmentBath", label: "Mite treatment bath" },
];

export const HAIR_STYLE_OPTIONS = [
  { value: "zeroTriming", label: "Zero Trimming" },
  { value: "trimFullBodyHair", label: "Trim Full Body Hair" },
  { value: "hairStyling", label: "Hair Styling" },
];

export const DATE_TYPE_OPTIONS = [
  { value: 1, label: "All days of the week" },
  {
    value: 2,
    label: "Working days of the week",
  },
  { value: 3, label: "Weekend days" },
];

export const SERVICE_CONSTANTS = {
  business_start_time: "09:00:00",

  business_end_time: "18:00:00",

  make_offer_expiry_hours: "240",

  accept_offer_expiry_hours: "600",

  send_soft_notificatons: "30",

  minDateForRequest: 2,

  maxDateForRequest: 8,

  max_offers_alowed: 3,

  otpexpirytime: 10,

  bookingAmountPercentage: 10,

  charges: 0.1,
  cancel_request_cutoff_time: 30,

  trending_expert_limit: 10,

  jobs_completed_limit: 10,

  ongoing_job_limit: 2,

  time_to_start_job: 30,
};

export const authLinks = [
  { title: "About us", link: "https://pawpurrfect.co/about-us/" },
  { title: "Who back us", link: "https://pawpurrfect.co/who-backs-us/" },
  { title: "Services", link: "https://pawpurrfect.co/services/" },
  { title: "why us", link: "https://pawpurrfect.co/why-us/" },
  {
    title: "How to book?",
    link: "/",
    listElements: [
      {
        title: "For Pet Parents",
        link: "https://pawpurrfect.co/for-pet-parents/",
      },
      {
        title: "For Service Experts",
        link: "https://pawpurrfect.co/for-service-experts/",
      },
    ],
  },
  { title: "Testimonials", link: "https://pawpurrfect.co/testimonial/" },
  {
    title: "FAQ'S",
    link: "/",
    listElements: [
      {
        title: "Pet Parents",
        link: "https://pawpurrfect.co/pet-parents-faqs/",
      },
      {
        title: "Service Experts",
        link: "https://pawpurrfect.co/service-experts-faqs/",
      },
    ],
  },
  { title: "Blogs", link: "https://pawpurrfect.co/blog/" },
  { title: "Connect", link: "https://pawpurrfect.co/connect/" },
];

export const userLinks = [
  { title: "Dashboard", link: "/" },
  { title: "My Requests", link: "/my-requests" },
  { title: "My Pet Profiles", link: "/my-pet-profile" },
];

export const jobDetailTitle = {
  constOfSessions: "Cost Of Sessions",
  meetAndGreet: "Meet and Greet",
  thirty: "Thirty Minute Dog Walk",
  fortyFive: "Forty-five Minute Dog Walk",
  oneHour: "One Hour Dog Walk",
  oneDay: "One Day",
  twoHours: "Two hours Dog Walk",
  twelveHours: "Twelve hours",
  behavioralTrainingThree: "Behavioral Training Three",
  obidienceTrainingThree: "Obidience Training Three",
  firstTrainingTwelve: "First Training Twelve",
  behavioralTrainingNine: "Behavioral Training Nine",
  obidienceTrainingSix: "Obidience Training Six",
};
