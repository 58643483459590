import React from "react";
import PropTypes from "prop-types";
import { Modal, ServiceCard, GradientButton } from "../Components";

const SubmitModal = ({
  submitModalVisibility,
  setSubmitModalVisibility,
  name,
  previousExperts,
  expertId,
  setExpertId,
  newRequest,
  next,
}) => {
  return (
    <Modal visibilty={submitModalVisibility}>
      <h1
        className="text-right mt-5 lg:mr-5 mr-16  text-lg rounded-full cursor-pointer font-extrabold"
        onClick={setSubmitModalVisibility}
      >
        X
      </h1>

      <div className="relative pb-20 pt-10 px-10 lg:min-w-max flex-auto  rounded">
        <h1 className="text-lg font-semibold text-center">Repeat Booking</h1>
        <h5 className="bg-lightYellow col-span-2 mt-3 p-2 text-center rounded w-full text-sm">
          Experts that have previously provided service to {name}
        </h5>
        <h5 className=" col-span-2 mt-1 p-2 text-center w-full text-sm">
          The message will first go to the person chosen after that it will go to all the
          service providers.
        </h5>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-7 my-7">
          {previousExperts.map((item, i) => (
            <div key={i} onClick={() => setExpertId(item.air_table_id)}>
              <ServiceCard
                data={item}
                size={"big"}
                selected={expertId === item.air_table_id}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-center gap-4 ml-5 md:ml-0 my-7">
          <GradientButton title="NEW REQUEST" className="lg:mr-10" onPress={newRequest} />
          <GradientButton
            title="NEXT"
            onPress={next}
            className="mr-4"
            disabled={expertId === null}
          />
        </div>
      </div>
    </Modal>
  );
};

SubmitModal.defaultProps = {
  submitModalVisibility: false,
  setSubmitModalVisibility: () => {},
  name: "",
  previousExperts: [],
  expertId: null,
  setExpertId: () => {},
  newRequest: () => {},
  next: () => {},
};

SubmitModal.propTypes = {
  submitModalVisibility: PropTypes.string.isRequired,
  setSubmitModalVisibility: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  previousExperts: PropTypes.array.isRequired,
  expertId: PropTypes.string,
  setExpertId: PropTypes.func.isRequired,
  newRequest: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};
export default SubmitModal;
