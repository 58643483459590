import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, RequestReceived } from '../Components';
import ConfirmBooking from '../Components/RequestPageComponents/ConfirmBooking';

const Requests = () => {
  const [bookingDetails, setBookingDetails] = useState('');
  const [provider, setProvider] = useState('');
  const {
    state: { requestId },
  } = useLocation();

  // if (loading) {
  //   return <Loader />;
  // }

  return (
    <Container className='my-7'>
      {bookingDetails ? (
        <ConfirmBooking
          requestId={requestId}
          bookingDetails={bookingDetails}
          id={provider?.provider_doc_id}
        />
      ) : (
        <RequestReceived
          bookProvider={setBookingDetails}
          requestId={requestId}
          setProvider={setProvider}
        />
      )}
    </Container>
  );
};

export default Requests;
