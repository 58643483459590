import React from 'react';
import PropTypes from 'prop-types';

const Avatar = ({ letter }) => {
  return (
    <div
      style={{ minWidth: '2rem' }}
      className='flex w-8 h-8 rounded-full items-center justify-center bg-darkGreen font-bold text-white'
    >
      {letter}
    </div>
  );
};

Avatar.defaultProps = {
  letter: null,
};

Avatar.propTypes = {
  letter: PropTypes.string,
};

export default Avatar;
