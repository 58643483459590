import PropTypes from 'prop-types';
import React from 'react';
import { ChipCard } from '..';
import {
  ClientVetService,
  VetServicesOptions,
} from '../../Constants/Constants';

const VetOptions = ({ toggleData, type, options }) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-4 text-[19px]'>
      {(type === 'Home Visit' ? VetServicesOptions : ClientVetService).map(
        ({ image, title, value }) => (
          <ChipCard
            Icon={image}
            title={title}
            key={value}
            isSelected={options.includes(value)}
            fill={false}
            onPress={() => toggleData(value)}
            ticked={true}
          />
        )
      )}
    </div>
  );
};

VetOptions.defaultProps = {
  toggleData: () => {},
  options: [],
};

VetOptions.propTypes = {
  toggleData: PropTypes.func,
  type: PropTypes.string,
  options: PropTypes.array,
};

export default VetOptions;
