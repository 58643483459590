import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FILTER } from '../../Redux/types';

const TopOptions = () => {
  const { type } = useSelector(({ filter }) => filter);
  const dispatch = useDispatch();
  return (
    <div className='flex overflow-hidden w-72'>
      {['Ongoing', 'History'].map((item) => (
        <div className=' w-36' key={item}>
          <div
            className={`h-14 font-semibold cursor-pointer ${
              type === item
                ? 'text-white bg-primary'
                : 'border-borderColour border-1 border-solid text-veryLightDark'
            }  flex justify-center items-center ${
              item === 'Ongoing' ? 'rounded-l' : 'rounded-r'
            }`}
            onClick={() =>
              dispatch({ type: FILTER.UPDATE_TYPE, payload: item })
            }
          >
            {item}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TopOptions;
