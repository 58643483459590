import { COMPLETED_NEARBY_EXPERTS } from '../types';

const initialState = {
  completedExperts: [],
  loading: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COMPLETED_NEARBY_EXPERTS.FETCH_COMPLETED_NEARBY_EXPERTS:
      return { ...state, completedExperts: payload, loading: false };
    default:
      return state;
  }
};
