// import { getDownloadURL, uploadBytesResumable } from 'firebase/storage';
// import { petProfileRef } from '../firebase';

// export const uploadPicture = async (file) => {
//   return 'hello';
// };
import moment from 'moment';
import { toast } from 'react-toastify';
import { SERVICE_CONSTANTS } from '../Constants/Constants';

export const isEmpty = (obj = {}, optional = []) => {
  const items = Object.entries(obj);
  for (let i = 0; i < items.length; i++) {
    const [key, value] = items[i];
    if (!value && value !== 0 && !optional.includes(key)) {
      return false;
    }
  }
  return true;
};

export const distance = (lat1, lat2, lon1, lon2) => {
  lon1 = (lon1 * Math.PI) / 180;
  lon2 = (lon2 * Math.PI) / 180;
  lat1 = (lat1 * Math.PI) / 180;
  lat2 = (lat2 * Math.PI) / 180;
  let dlon = lon2 - lon1;
  let dlat = lat2 - lat1;
  let a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);
  let c = 2 * Math.asin(Math.sqrt(a));
  let r = 6371;
  return c * r;
};

export const isWithinBusinessHours = (time, beforeTime, afterTime) => {
  if (time.isSameOrAfter(beforeTime) && time.isSameOrBefore(afterTime)) {
    return true;
  } else {
    return false;
  }
};

export const getExpiryDate = (expiry_hours_to_add, date, days, hours_added) => {
  let beginningTime = moment(
    SERVICE_CONSTANTS.business_start_time,
    'h:mm'
  ).format('HH:mm');
  let endTime = moment(SERVICE_CONSTANTS.business_end_time, 'h:mm').format(
    'HH:mm'
  );

  let req_datetime = moment(date).format('HH:mm');

  let format = 'hh:mm';
  let time = moment(req_datetime, format),
    beforeTime = moment(beginningTime, format),
    afterTime = moment(endTime, format);

  if (isWithinBusinessHours(time, beforeTime, afterTime)) {
    let add_hours_to_expiry = !hours_added ? expiry_hours_to_add : 0;
    var exp_datetime = moment(date).add(add_hours_to_expiry, 'm');
    hours_added = true;
    var add_day_to_expiry;
    let exp_datetime_time = moment(exp_datetime, format);
    // console.log("exp_datetime==>",exp_datetime_time)
    if (isWithinBusinessHours(exp_datetime_time, beforeTime, afterTime)) {
      let final_exp = moment(exp_datetime).add(days, 'day').format('llll');
      // console.log("return expiry_hours_to_add", expiry_hours_to_add)
      // console.log("return exp_datetime isWithinBusinessHours", final_exp)
      return final_exp;
    } else {
      // console.log(" exp_datetime inot sWithinBusinessHours")
      if (exp_datetime_time.isAfter(afterTime)) {
        days = days + 1;
        let time_diff = exp_datetime.diff(afterTime, 'minutes');
        // console.log(" time_diff",time_diff)
        // console.log(" yes after BusinessHours")
        add_day_to_expiry = moment(
          SERVICE_CONSTANTS.business_start_time,
          'h:mm'
        );
        // console.log(" before adding mins",add_day_to_expiry)
        exp_datetime = moment(add_day_to_expiry).add(time_diff, 'm');
        let exp_datetime_time = moment(exp_datetime, format);
        // console.log(" after adding mins",exp_datetime_time)
        return getExpiryDate(
          expiry_hours_to_add,
          exp_datetime_time,
          days,
          hours_added
        );
      } else if (exp_datetime_time.isBefore(beforeTime)) {
        // console.log(" yes before BusinessHours")
        add_day_to_expiry = moment(
          SERVICE_CONSTANTS.business_start_time,
          'h:mm'
        );

        return getExpiryDate(
          expiry_hours_to_add,
          add_day_to_expiry,
          days,
          hours_added
        );
      }
    }
  } else {
    // console.log("not isWithinBusinessHours")
    if (time.isAfter(afterTime)) {
      days = days + 1;
      add_day_to_expiry = moment(SERVICE_CONSTANTS.business_start_time, 'h:mm');
      add_day_to_expiry = moment(add_day_to_expiry, format);
      return getExpiryDate(
        expiry_hours_to_add,
        add_day_to_expiry,
        days,
        hours_added
      );
    } else if (time.isBefore(afterTime)) {
      // console.log(" yes before BusinessHours")
      add_day_to_expiry = moment(SERVICE_CONSTANTS.business_start_time, 'h:mm');
      add_day_to_expiry = moment(add_day_to_expiry, format);
      return getExpiryDate(
        expiry_hours_to_add,
        add_day_to_expiry,
        days,
        hours_added
      );
    }
  }

  return date;
};

export const getDateArray = (startDate, endDate) => {
  if (!endDate) {
    return startDate;
  }
  const d1 = moment(endDate);
  const d2 = moment(startDate);
  const diff = d1.diff(d2, 'days');
  const array = [...new Array(diff + 1)].map((_, index) => {
    const date = d2.add(index === 0 ? 0 : 1, 'day');
    const newDate = date.toDate();
    return newDate;
  });

  return [array[0], array[array.length - 1]];
};

export const validateTimeStamp = (startTime, date) => {
  try {
    const serviceDate = new Date(date);

    serviceDate.setHours(startTime);
    const currentTime = new Date();
    const start = moment(serviceDate).format('X');
    const curr = moment(currentTime).format('X');

    const diff = start - curr;

    if (diff <= 180 * 60) {
      toast.error('Service cannot be less than 3 hours away from now!');
      return false;
    }
    return true;
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const formatGetExpiryDate = (dateTimeStr) => {
  if (typeof dateTimeStr === 'object') {
    const date = new Date(dateTimeStr);
    return date;
  }

  try {
    const [dateString, timeString] = dateTimeStr.split(','); // 21/04/2022, 13:41:41
    const [day, month, year] = dateString.replace(' ', '').split('/');
    const [hour, min, second] = timeString.replace(' ', '').split(':');
    const returnDate = new Date(year, +month - 1, day, hour, min, second);
    return returnDate;
  } catch (error) {
    console.log(error.message);
    return new Date().toLocaleString();
  }
};

export const getOfferStatus = (request_open_for_offers) => {
  return ['offer_accepted', 'job_started'].includes(request_open_for_offers)
    ? 'Accepted'
    : ['canceled_by_seeker', 'canceled_by_provider'].includes(
        request_open_for_offers
      )
    ? 'Cancelled'
    : ['offer_time_expired', 'request_expired', 'time_expired'].includes(
        request_open_for_offers
      )
    ? 'Expired'
    : ['completed', 'job_completed'].includes(request_open_for_offers)
    ? 'Completed'
    : request_open_for_offers === 'offer_accepted'
    ? 'Booked'
    : ['no_show_seeker', 'no_show_provider'].includes(request_open_for_offers)
    ? 'NoShow'
    : 'Pending';
};

export const getTimeLeft = (acceptOfferExpiryDate, type) => {
  let date = acceptOfferExpiryDate?.seconds
    ? new Date(acceptOfferExpiryDate.seconds * 1000)
    : new Date(acceptOfferExpiryDate);

  var eventTime = moment(date).unix();
  var currentTime = moment().unix();
  var diffTime = eventTime - currentTime; //better to handle this in Controller to avoid timezone problem
  var duration = moment.duration(diffTime * 1000, 'milliseconds');
  var interval = 60000;
  duration = moment.duration(
    duration.asMilliseconds() - interval,
    'milliseconds'
  );

  let d = moment.duration(duration).days();
  let h = moment.duration(duration).hours();
  let m = moment.duration(duration).minutes();

  if (h <= 0) {
    h = '00';
  }
  if (m < 0) {
    m = '00';
    return 'Expired';
  } else if (m < 10) {
    m = '0' + m;
  }
  if (d > 1) {
    d = d + ' days ';
  } else if (d <= 0) {
    d = '';
  } else {
    d = d + ' day ';
  }
  const res = `${d} ${h} hrs ${m} mins`;

  return res;
};
