import { MODAL } from '../types';

const initialState = {
  requestId: '',
  type: '',
  visibility: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case MODAL.SHOW_DISPUTE_MODAL:
    case MODAL.SHOW_REVIEW_MODAL:
      return { ...payload, visibility: true };

    case MODAL.TOGGLE_VISIBILITY:
      return {
        ...state,
        visibility: payload,
      };

    default:
      return state;
  }
};
