import React from 'react';
import ReactCalendar from 'react-calendar';
import PropTypes from 'prop-types';
import '../CSS/component.css';
import moment from 'moment';

const minDateForRequest = 2;
const maxDateForRequest = 91;

const Calender = ({ type, date, setDate, multipleDays }) => {
  return (
    <div>
      <ReactCalendar
        allowPartialRange
        className='border-0'
        onChange={setDate}
        value={date}
        minDate={
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          )
        }
        maxDate={
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() + maxDateForRequest - 2
          )
        }
        tileDisabled={({ date: currDate }) => {
          const day = currDate.getDay();

          if (type === 1) return true;

          if (multipleDays === 3) {
            if ([0, 6].includes(day)) {
              return false;
            }
            return true;
          }
          if (multipleDays === 2) {
            if ([0, 6].includes(day)) {
              return true;
            }
            return false;
          }
          // if (multipleDays === 'alternate') {
          //   const startDate = date.length ? date[0] : date;
          //   const d1 = moment(currDate);
          //   const d2 = moment(startDate);
          //   const diff = d1.diff(d2, 'days') + 2;
          //   console.log(diff);
          //   if (diff % 2) {
          //     return false;
          //   }
          //   return true;
          // }
          return false;
        }}
      />
    </div>
  );
};

Calender.defaultProps = {
  type: '',
};

Calender.propTypes = {
  type: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string]),
  setDate: PropTypes.func,
  multipleDays: PropTypes.string,
};

export default Calender;
