import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, to }) => {
  const dispatch = useDispatch();
  const { user, loading, redirect } = useSelector(({ auth }) => auth);

  const { email, displayName } = user || {};

  if (loading) {
    return <h1>loading</h1>;
  }

  if (!user) {
    return <Navigate to='/login' />;
  }

  if (
    (!email || !displayName) &&
    to !== '/add-user-details' &&
    to !== '/onboarding'
  ) {
    return <Navigate to='/add-user-details' />;
  }

  if (redirect) {
    return <Navigate to={redirect} />; 
  }

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.object.isRequired,
  to: PropTypes.string,
};

export default PrivateRoute;
