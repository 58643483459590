import React from 'react';
import PropTypes from 'prop-types';
import { TiTick } from 'react-icons/ti';

const GradientButton = ({
  className,
  title,
  size,
  onPress,
  type,
  disabled,
  active,
  done,
  grey,
}) => {
  return (
    <button
      onClick={onPress}
      type={type}
      disabled={disabled}
      className={`button flip-animation ${className} ${
        disabled ? 'disabled-btn' : ''
      } disabled:bg-black button-${size} ${
        !active ? 'button-bg' : 'bg-action'
      } main-button ${grey && 'grey-gradient'}`}
    >
      <div className='flex justify-center'>
        {done && (
          <TiTick
            className='border-2 mr-1 border-white bg-white border-solid rounded-full mt-px'
            size='20'
            fill='orange'
          />
        )}
        <p className='font-semibold text-white'>{title}</p>
      </div>
    </button>
  );
};

GradientButton.defaultProps = {
  size: 'md',
  type: 'button',
  onPress: () => {},
  disabled: false,
  grey: false,
};

GradientButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  type: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xs']),
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  done: PropTypes.bool,
  grey: PropTypes.bool,
};

export default GradientButton;
