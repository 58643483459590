import React from "react";
// import ReactDOM from "react-dom";
import ReactAvatarEditor from 'react-avatar-editor'
import PropTypes from "prop-types";

class Crop extends React.Component {
  state = {
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 0,
    preview: null,
    width: 200,
    height: 200,
  }

  handleNewImage = e => {
    this.props.uploadFile(e.target.files[0])
    this.props.setFile(e.target.files[0])
  }

  handleScale = e => {
    const scale = parseFloat(e.target.value)
    this.setState({ scale })
    this.onClickSave()
  }

  handlePositionChange = position => {
    this.setState({ position })
    this.onClickSave()
  }

  onClickSave = () => {
    if (this.editor) {

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const canvasScaled = this.editor.getImageScaledToCanvas().toDataURL()
      this.imageSave(canvasScaled)
    }
  }

  imageSave = (image) => {
    fetch(image).then(
      res => {
        res.blob().then(
          res => {
            const file = new File([res], 'pet.png', { type: 'image/png' })
            this.props.setFile(file)
          }
        )
      }
    )
  }

  setEditorRef = (editor) => (this.editor = editor)


  render() {
    return (
      <div>
        <div>
          <ReactAvatarEditor
            scale={parseFloat(this.state.scale)}
            width={this.state.width}
            height={this.state.height}
            position={this.state.position}
            onPositionChange={this.handlePositionChange}
            rotate={parseFloat(this.state.rotate)}
            borderRadius={this.state.width / (100 / this.state.borderRadius)}
            image={this.props.data}
            className="editor-canvas"
            ref={this.setEditorRef}
            border={3}
          />
          <input className="mt-1" id="upload" name="newImage" type="file" onChange={this.handleNewImage} />
        </div>
        <div className="mt-1 flex">
          <span>Zoom: </span>
          <input
            name="scale"
            type="range"
            className="ml-2"
            onChange={this.handleScale}
            min={this.state.allowZoomOut ? '0.1' : '1'}
            max="2"
            step="0.01"
            defaultValue="1"
          />
        </div>

      </div>
    )
  }
}

Crop.defaultProps = {
  setFile: () => {},
  data: ''
};

Crop.propTypes = {
  setFile: PropTypes.func,
  uploadFile: PropTypes.func,
  data: PropTypes.string
};

export default Crop;
