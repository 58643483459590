import React from "react";
import PropTypes from "prop-types";

const PathologyTestCardSmall = ({ detail, onChangeCapture, price, isChecked }) => {
  return (
    <div className="flex justify-between my-2 border-1 px-[20px]  border-[#ABB4BD] rounded-md lg:w-[383.83px] mb:w-[300px] h-[170px]">
      <div className="lg:w-[269.19px] mb:w-[230px] ">
        <p className="text-[14px] my-[15.98px] font-sans font-normal h-[45%] text-[#818181]">
          {detail}
        </p>
        <hr className="border-yellow w-[80%]" />
        <p className="text-darkGreen font-semibold text-[25px] font-sans mt-[15px]">
          Rs. {price}
        </p>
      </div>
      <div>
        <input
          type="checkbox"
          className="checkboxStyle2"
          defaultChecked={isChecked}
          // defaultChecked={checkboxChecking(item)}
          onChangeCapture={onChangeCapture}
        />
      </div>
    </div>
  );
};

PathologyTestCardSmall.propTypes = {
  detail: PropTypes.string,
  onChangeCapture: PropTypes.func,
  price: PropTypes.number,
  isChecked: PropTypes.bool,
};
PathologyTestCardSmall.defaultProps = {
  isChecked: false,
};
export default PathologyTestCardSmall;
