import React from "react";
import PropTypes from "prop-types";
import { Modal, GradientButton, PetProfileCard } from "../Components";

const InsuranceModel = ({
  dogInsuranceVisibility,
  petData,
  onPressConfirm,
  onPressBack,
  onPressNext,
  nextBtn,
}) => {
  return (
    <Modal visibilty={dogInsuranceVisibility}>
      <div className="lg:w-[830px] py-4 mb:w-full bg-white rounded-md">
        <div className="w-[90%] lg:m-10 mb:m-2 ">
          <PetProfileCard data={petData} noEdit />
        </div>
        <div className="flex flex-column justify-center gap-x-2 item-center text-[15px] font-sans">
          <input
            type="checkbox"
            onChangeCapture={onPressConfirm}
            className="checkboxStyle4"
            // className='w-[27.52px] h-[27.52px] accent-white '
          />
          Please confirm the above information for your pet
        </div>

        <div className="flex justify-center mt-8 gap-3">
          <GradientButton grey onPress={onPressBack} title="BACK" />
          <GradientButton grey={nextBtn} onPress={onPressNext} title="NEXT" />
        </div>
      </div>
    </Modal>
  );
};

InsuranceModel.propTypes = {
  dogInsuranceVisibility: PropTypes.bool.isRequired,
  petData: PropTypes.object.isRequired,
  onPressConfirm: PropTypes.func.isRequired,
  onPressBack: PropTypes.func.isRequired,
  onPressNext: PropTypes.func.isRequired,
  nextBtn: PropTypes.bool.isRequired,
};
export default InsuranceModel;
