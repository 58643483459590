import moment from "moment";

// const BUSINESS_START_TIME = "09:00:00"
// const BUSINESS_END_TIME = "18:00:00"
//
// export const getExpiryDate = (expiry_hours_to_add, date, days, hours_added) => {
//   // console.log("date",date)
//   // console.log("days==>==>", days)
//   let beginningTime = moment(BUSINESS_START_TIME, "h:mm").format("HH:mm")
//   let endTime = moment(BUSINESS_END_TIME, "h:mm").format("HH:mm");
//
//   let req_datetime = moment(date).format("HH:mm");
//
//   let format = 'hh:mm'
//   let time = moment(req_datetime, format),
//     beforeTime = moment(beginningTime, format),
//     afterTime = moment(endTime, format);
//
//   if (this.isWithinBusinessHours(time, beforeTime, afterTime)) {
//
//     //expiry_hours_to_add config is in minutes
//     let add_hours_to_expiry = (!hours_added) ? expiry_hours_to_add : 0;
//     var exp_datetime = moment(date).add(add_hours_to_expiry, 'm');
//     hours_added = true;
//     let exp_datetime_time = moment(exp_datetime, format);
//     // console.log("exp_datetime==>",exp_datetime_time)
//     if (this.isWithinBusinessHours(exp_datetime_time, beforeTime, afterTime)) {
//       let final_exp = moment(exp_datetime).add(days, 'day').format('llll')
//       // console.log("return expiry_hours_to_add", expiry_hours_to_add)
//       // console.log("return exp_datetime isWithinBusinessHours", final_exp)
//       return final_exp;
//     }
//     else {
//       // console.log(" exp_datetime inot sWithinBusinessHours")
//       if (exp_datetime_time.isAfter(afterTime)) {
//         days = days + 1;
//         let time_diff = exp_datetime.diff(afterTime, 'minutes')
//         // console.log(" time_diff",time_diff)
//         // console.log(" yes after BusinessHours")
//         var add_day_to_expiry = moment(BUSINESS_START_TIME, "h:mm");
//         // console.log(" before adding mins",add_day_to_expiry)
//         var exp_datetime = moment(add_day_to_expiry).add(time_diff, 'm');
//         let exp_datetime_time = moment(exp_datetime, format);
//         // console.log(" after adding mins",exp_datetime_time)
//         return this.getExpiryDate(expiry_hours_to_add, exp_datetime_time, days, hours_added)
//       }
//       else if (exp_datetime_time.isBefore(beforeTime)) {
//         // console.log(" yes before BusinessHours")
//         var add_day_to_expiry = moment(BUSINESS_START_TIME, "h:mm");
//         return this.getExpiryDate(expiry_hours_to_add, add_day_to_expiry, days, hours_added)
//       }
//     }
//   }
//   else {
//     // console.log("not isWithinBusinessHours")
//     if (time.isAfter(afterTime)) {
//       days = days + 1;
//       var add_day_to_expiry = moment(BUSINESS_START_TIME, "h:mm");
//       add_day_to_expiry = moment(add_day_to_expiry, format);
//       return this.getExpiryDate(expiry_hours_to_add, add_day_to_expiry, days, hours_added)
//
//     }
//     else if (time.isBefore(afterTime)) {
//       // console.log(" yes before BusinessHours")
//       var add_day_to_expiry = moment(BUSINESS_START_TIME, "h:mm");
//       add_day_to_expiry = moment(add_day_to_expiry, format);
//       return this.getExpiryDate(expiry_hours_to_add, add_day_to_expiry, days, hours_added)
//     }
//   }
//   return date;
// }
//
// export const isWithinBusinessHours = (time, beforeTime, afterTime) => {
//   if (time.isSameOrAfter(beforeTime) && time.isSameOrBefore(afterTime)) {
//     return true;
//   }
//   else {
//     return false;
//   }
// }
//

export const addRequiredDates =  (startDate, requiredDaysSelectedIndex, endDate) => {
  // requiredDaysSelectedIndex = label="All Days" value={1}, label="Weekdays" value={2}, label="Weekends" value={3}
  let dates = [];
  let time = moment(startDate);
  let end= moment(endDate);
  for (var m = moment(startDate); m.isSameOrBefore(end); m.add(1, 'days')) {
    if ((requiredDaysSelectedIndex === 2 && (m.isoWeekday() === 6 || m.isoWeekday() === 7)) || (requiredDaysSelectedIndex === 3 && m.isoWeekday() !== 6 && m.isoWeekday() !== 7)){
      console.log ("skipping",requiredDaysSelectedIndex,m, m.isoWeekday())
    }  else {
      let dateItem = m.set({h: time.hours(), m: time.minutes(), s: time.seconds()})
      let date= {
        id: Date.now(),
        date:moment(dateItem),
      }
      dates.push({
        has_request_cancelled: false,
        has_request_completed: false,
        requested_date_time: new Date(date.date),
      });
    }
  }
  return dates;
}
