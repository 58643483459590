import PropTypes from 'prop-types';
import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import Select, { components } from 'react-select';

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <GiHamburgerMenu fill='grey' />
    </components.DropdownIndicator>
  );
};

const InputSelect = ({
  Icon,
  placeholder,
  onChange,
  options,
  className,
  fullWidth,
  value,
  image,
  controlled,
}) => {
  return (
    <div
      className={`relative border-1 ${placeholder === '30 Min' ? 'ml-16' : null} lg:ml-[1px] rounded-sm ${
        fullWidth ? 'flex-grow' : ''
      } hover:border-action focus:border-action flex ${image && 'px-2'}`}
    >
      <div className='flex items-center'>
        {image && <img src={image} alt='' className='w-8 h-8' />}
      </div>
      <div className='flex-grow my-1 text-base'>
        <Select
          options={options}
          components={{ Placeholder, DropdownIndicator }}
          className={`${className} ${Icon ? 'ml-9' : ''}`}
          placeholder={placeholder}
          onChange={onChange}
          value={options.find((i) => i.value === value)}
          defaultValue={options.find((i) => i.value === value)}
          styles={{
            input: (provided, state) => ({
              ...provided,
              padding: 8,
            }),
            control: (base) => ({
              ...base,
              border: 0,
              boxShadow: 'none',
            }),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: 'rgba(121, 209, 187,0.15)',
              primary: '#79d1bb',
            },
          })}
        />
        {Icon && (
          <div className='absolute absolute-center-x left-3'>
            <Icon size={26} fill='#79d1bb' />
          </div>
        )}
      </div>
    </div>
  );
};

InputSelect.defaultProps = {
  Icon: null,
  placeholder: '',
  options: [],
  onChange: () => {},
  className: '',
  fullWidth: false,
  value: '',
  image: '',
  controlled: false,
};

InputSelect.propTypes = {
  Icon: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
  controlled: PropTypes.bool,
};

export default InputSelect;
