import { getAuth } from 'firebase/auth';
import {
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  collection
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { SERVICE_CONSTANTS } from '../../Constants/Constants';
import { db, serviceRequestRef } from '../../firebase';
import { CREATE_REQ } from '../types';

export const updateServiceRequest =
  ({ data, docid }, onComplete = () => {}) =>
  async (dispatch) => {
    // save expert rating
    try{
      const docRef = doc(db, "users_common", data.offered_provider_id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const expertData = docSnap.data()
        if (expertData.expert_avg_rating){
          data.booked_expert.expert_avg_rating = expertData.expert_avg_rating
        }
      }
    }catch (e) {
      console.log(e.message)
    }
    try {
      const serviceRequestRef = doc(db, 'service_request', docid);

      // Set the "capital" field of the city 'DC'
      await updateDoc(serviceRequestRef, data);
      console.log({ data });
      dispatch({ type: CREATE_REQ.UPDATE_REQUEST, payload: { data, docid } });
    } catch (error) {
      console.log(error.message);
    } finally {
      onComplete();
    }
  };

export const noShowByExpertAction =
  (request_id, onComplete = () => {}) =>
  async (dispatch) => {
    try {
      const docRef = doc(db, 'service_request', request_id);
      console.log(docRef);
      const noShowObj = {
        start_job_time: new Date(),
        request_open_for_offers: 'no_show_provider',
      };
      await updateDoc(docRef, noShowObj);
      dispatch({
        type: CREATE_REQ.UPDATE_REQUEST,
        payload: {
          docid: request_id,
          data: noShowObj,
        },
      });
      toast.success('No Show By Expert Success!');
      onComplete(true);
    } catch (e) {
      console.error(e);
    }
  };

export const verifyCompleteOTPAction =
  (request_id, otp, onComplete = () => {}) =>
  async (dispatch) => {
    try {
      const docRef = doc(db, 'service_request', request_id);
      const request = await getDoc(docRef);
      if(request.data().complete_job_otp === otp){
        const completeObj = {
          complete_job_time: new Date(),
          request_open_for_offers: 'completed',
        };
        await updateDoc(docRef, completeObj);
        dispatch({
          type: CREATE_REQ.UPDATE_REQUEST,
          payload: {
            docid: request_id,
            data: completeObj,
          },
        });
        onComplete(true);
      }
      else {
        toast.error('Invalid OTP')
      }
    } catch (e) {
      console.error(e);
      toast.error('Invalid OTP')
    }
  };

export const activeServiceIdAction =
  (request_id) =>
  (dispatch) => {
    dispatch({
      type: CREATE_REQ.ACTIVE_SERVICE,
      payload: request_id,
    });
  };

export const addServiceRequestToData =
  (allRequests = []) =>
  async (dispatch) => {
    if (allRequests.length === 0) return;
    const q2 = query(
      serviceRequestRef,
      where('docid', '==', '0VHTD6LAJVOdXS8mH8Ly')
    );

    // seeker_details
    // GK3uxIS7daPQPNaX3QnTq87emc23;
    const querySnapshot2 = await getDocs(q2);
    const result2 = [];
    querySnapshot2.forEach((doc) => {
      result2.push(doc.data());
    });

    const { providers_offers } = result2[0];
    const offerAddedRequest = allRequests.map((item) => ({
      ...item,
      providers_offers,
      offer_acceptance_status: 'accepted',
      has_offers: true,
    }));

    dispatch({ type: CREATE_REQ.FETCH_SERVICES, payload: offerAddedRequest });
  };

export const fetchUserServices = () => async (dispatch) => {
  try {
    const auth = getAuth();
    const q = query(
      serviceRequestRef,
      where('seeker_doc_id', '==', auth.currentUser.uid)
    );
    const querySnapshot = await getDocs(q);
    const result = [];
    querySnapshot.forEach((doc) => {
      result.push(doc.data());
    });

    dispatch({
      type: CREATE_REQ.FETCH_SERVICES,
      payload: [...result].sort(
        (a, b) => b.request_datetimestamp - a.request_datetimestamp
      ),
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const sortByDate = (type) => (dispatch, getState) => {
  const {
    service: { services },
  } = getState();
  dispatch({
    type: CREATE_REQ.FETCH_SERVICES,
    payload: [...services].sort((a, b) => {
      if (type === 'asce') {
        return b.request_datetimestamp - a.request_datetimestamp;
      } else {
        return a.request_datetimestamp - b.request_datetimestamp;
      }
    }),
  });
};

export const cancelServiceRequest =
  (docid, onComplete = () => {}) =>
  async (dispatch) => {
    try {
      const docRef = await doc(db, 'service_request', docid);

      const updateObj = {
        request_open_for_offers: 'canceled_by_seeker',
      };
      await updateDoc(docRef, updateObj);
      dispatch({
        type: CREATE_REQ.UPDATE_REQUEST,
        payload: {
          docid,
          data: updateObj,
        },
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      onComplete();
    }
  };

export const rejectServiceOffer =
  (request_id, provider_doc_id, onComplete = () => {}) =>
  async (dispatch) => {
    try {
      const docRef = await doc(db, 'service_request', request_id);
      const docSnap = await getDoc(docRef);
      // CODE FROM REACT NATIVE APP
      const reqDataa = docSnap.data();
      let offers_count = reqDataa.offer_received_count;
      offers_count = offers_count - 1;
      let provider_offers = reqDataa.providers_offers;

      let updateObj = {
        offer_received_count: offers_count,
        has_offers: offers_count > 0 ? true : false,
      };
      if (parseInt(SERVICE_CONSTANTS.max_offers_alowed) > offers_count) {
        updateObj['request_open_for_offers'] = 'accepting_offers';
      }
      provider_offers.forEach(function (offer, index) {
        // console.log("offer===>", offer);
        if (offer.provider_doc_id == provider_doc_id) {
          provider_offers[index]['status'] = 'rejected';
        }
      });
      updateObj['providers_offers'] = provider_offers;
      await updateDoc(docRef, updateObj);
      dispatch({
        type: CREATE_REQ.UPDATE_REQUEST,
        payload: {
          docid: request_id,
          data: updateObj,
        },
      });
      console.log('Updated successfully');
      onComplete(true);
    } catch (error) {
      console.log(error.message);
      onComplete(false);
    }
  };
