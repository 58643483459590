import React from 'react';
import PropTypes from 'prop-types';

const Textarea = ({ onChange, placeholder, required }) => {
  return (
    <textarea
      className='  form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                mt-4
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
      id='exampleFormControlTextarea1'
      rows='3'
      placeholder={placeholder}
      required={required}
      onChange={onChange}
    ></textarea>
  );
};

Textarea.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default Textarea;
