import { combineReducers } from "redux";
import auth from "./authReducers";
import addPet from "./addPetReducer";
import createReq from "./createReqReducer";
import pet from "./petReducer";
import service from "./serviceReducer";
import filter from "./serviceFilterReducer";
import trendingExpert from "./trendingExpertReducers";
import completedExperts from "./completedNearbyExpertsReducers";
import modalReducer from "./modalReducer";
import notification from "./notificationReducer";
import pathologyTopButtonReducer from "./pathologyTopButtonReducer";
import petInsurance from "./petInsuranceReducer";
import petPathology from "./petPathologyReducer";

const rootReducer = combineReducers({
  petInsurance,
  auth,
  addPet,
  createReq,
  pet,
  service,
  filter,
  trendingExpert,
  completedExperts,
  modalReducer,
  notification,
  pathologyTopButtonReducer,
  petPathology,
});

export default rootReducer;
