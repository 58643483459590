import React from 'react';
import PropTypes from 'prop-types';

const TextButton = ({ title, onClick }) => {
  return (
    <div className='cursor-pointer' onClick={onClick}>
      <p className=' text-action font-semibold text-base px-1'>{title}</p>
    </div>
  );
};

TextButton.defaultProps = {
  onClick: () => {},
};

TextButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
};

export default TextButton;
