import React from "react";
import PropTypes from "prop-types";

const PathologyPackageCardSmall = ({
  title,
  detail,
  price,
  onChangeCapture,
  isChecked,
}) => {
  return (
    <div className="flex flex-col my-2 justify-between lg:w-[350.53px] mb:w-[300px]  border-1 border-[#C0C0C0] rounded-md">
      <div className="p-2">
        <p className="font-sans text-[20px] font-semibold my-4">{title}</p>
        <hr className="border-yellow w-[80%]"></hr>
        <p className="font-sans text-[14px] font-normal text-[#818181] mt-2">{detail}</p>
      </div>

      <div className=" flex justify-between lg:w-[350.13px] mb:w-full h-[64.09px] border-t-1 border-[#C0C0C0] ">
        <p className="text-[25px] text-[#1EAF9C] font-semibold font-sans mt-2 px-2">
          Rs. {price}
        </p>
        <input
          type="checkbox"
          className="checkboxStyle3"
          defaultChecked={isChecked}
          onChangeCapture={onChangeCapture}
        />
      </div>
    </div>
  );
};

PathologyPackageCardSmall.propTypes = {
  title: PropTypes.string,
  detail: PropTypes.string,
  onChangeCapture: PropTypes.func,
  price: PropTypes.number,
  isChecked: PropTypes.bool,
};
PathologyPackageCardSmall.defaultProps = {
  isChecked: false,
};
export default PathologyPackageCardSmall;
