/* eslint-disable no-undef */
import GoogleMapReact from 'google-map-react';
import React, { useState } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { IoLocationOutline } from 'react-icons/io5';
import { MdOutlineLocationCity } from 'react-icons/md';
import { VscLocation } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, distance } from '../../utils/utils';
import { Link } from 'react-router-dom';
import { GradientButton, Modal } from '..';
import {
  decrementStep,
  incrementStep,
  setPetLocation,
} from '../../Redux/actions/addPetActions';
import Input from '../Input';
import LocationConfirm from '../LocationConfirm';

const SectionThree = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [locationConfirm, setLocationConfirm] = useState(false);
  const [notify, setNotify] = useState(true);

  const { address, latitude, longitude, building } = useSelector(
    ({ addPet }) => addPet
  );

  const [formData, setFormData] = useState({
    address,
    latitude,
    longitude,
    building,
  });

  const dispatch = useDispatch();

  const updateLocation = () => {
    dispatch(
      setPetLocation({
        latitude: formData.latitude,
        longitude: formData.longitude,
        address: formData.address,
        building: formData.building,
      })
    );
    dispatch(incrementStep());
  };

  const handleLocationSelect = ({ formatted_address, geometry }) => {
    setFormData({
      ...formData,
      address: formatted_address,
      latitude: geometry.location.lat(),
      longitude: geometry.location.lng(),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      distance(19.07609, formData.latitude, 72.877426, formData.longitude) >
        300 &&
      formData.address !== ''
    ) {
      setModalVisibility(true);
    } else {
      setLocationConfirm(true);
    }
  };

  return (
    <div className='pb-6 mx-auto'>
      <Modal visibilty={modalVisibility}>
        <div className='relative p-6 flex-auto'>
          <p className='font-semibold text-7xl flex justify-center'>
            <IoLocationOutline fill='#79d1bb' />
          </p>
          <div className='my-4'>
            <p className='text-dark text-md leading-relaxed text-center'>
              Thank you for showing your interest.
            </p>
            <p className='text-dark text-md leading-relaxed text-center'>
              We are however not operational outside Mumbai.
            </p>
          </div>
          <div className='form-check flex justify-center items-center'>
            <input
              className='mr-1 cursor-pointer w-5 h-5'
              type='checkbox'
              value=''
              id='flexCheckDefault'
              checked={notify}
              onChange={() => setNotify(!notify)}
            />
            <label
              className='form-check-label text-base inline-block text-gray-800'
              htmlFor='flexCheckDefault'
            >
              Notify me when PawPurrfect is active in my city.
            </label>
          </div>
        </div>
        <div className='flex items-center justify-center p-6 rounded-b'>
            <GradientButton onPress={() => {
              setModalVisibility(false)
              setFormData({
                latitude: '',
                longitude: '',
                address: '',
                building: ''
              })
              document.getElementById('address_location').value = ''
            }} title='BACK' />
        </div>
      </Modal>
      <h4 className='text-lg'>
        <b>Your pet location</b>
      </h4>
      <div className='grid md:grid-cols-2 gap-5'>
        <div className='mt-5'>
          <div style={{ height: '40vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_MAP_KEY,
              }}
              defaultCenter={{ lat: 19.076, lng: 72.8777 }}
              defaultZoom={14}
              center={{
                lat: formData.latitude || 19.076,
                lng: formData.longitude || 72.8777,
              }}
            >
              {formData.latitude && formData.longitude && (
                <FaMapMarkerAlt
                  lat={formData.latitude}
                  lng={formData.longitude}
                  size={26}
                  fill={'#F49F02'}
                  text={formData.address}
                />
              )}
            </GoogleMapReact>
          </div>
        </div>

        <form className='mt-5 grid grid-rows-5' onSubmit={handleSubmit}>
          <div
            className={`flex border-1 overflow-hidden my-3 first:mt-0 font-number rounded-md last:mb-0 relative`}
          >
            <div className='absolute absolute-center-x left-3'>
              <VscLocation size={26} fill='#79d1bb' />
            </div>
            <ReactGoogleAutocomplete
              apiKey={process.env.REACT_APP_MAP_KEY}
              options={{
                componentRestrictions: { country: "in" },
                types: ['establishment']
              }}
              onPlaceSelected={handleLocationSelect}
              id={'address_location'}
              className='pl-12 w-full'
              defaultValue={address}
            />
          </div>
          <Input
            required
            type='text'
            placeholder='Flat/Building/Street'
            Icon={MdOutlineLocationCity}
            onChange={(e) =>
              setFormData({ ...formData, building: e.target.value })
            }
            value={formData.building}
          />

          <div className='mt-6 flex justify-end'>
            <GradientButton
              title='Back'
              className='mr-4'
              grey
              onPress={() => {
                dispatch(decrementStep());
              }}
            />
            <GradientButton
              title='REVEIW & SUBMIT'
              disabled={!isEmpty(formData)}
              type='submit'
            />
          </div>
        </form>
      </div>
      <LocationConfirm
        visibility={locationConfirm}
        setVisibility={setLocationConfirm}
        onConfirm={updateLocation}
      />
    </div>
  );
};

export default SectionThree;
