import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputSelect, RadioButton } from '..';
import {WALK_DURATION, WALK_OPTIONS} from '../../Constants/Constants';

const DogWalkingOptions = ({ setData }) => {
  const [formData, setFormData] = useState({
    petWalk: true,
    duration: 'thirty',
    walkNo: 0,
  });

  useEffect(() => {
    setData(formData);
  }, [formData]);

  return (
    <div className='mt-7'>
      <div className='grid lg:grid-cols-2 sm:grid-cols-1 gap-4'>
        <div className='flex gap-4'>
          <h1 className='mt-3'>Duration of walk? </h1>
          <InputSelect
            options={WALK_DURATION}
            placeholder={'30 Min'}
            value={formData.duration}
            onChange={(e) =>
              setFormData({ ...formData, duration: e.value })
            }
          />
        </div>
        <div className='flex gap-4'>
          <h1 className='mt-3'>How many walks per day?</h1>
          <InputSelect
            options={WALK_OPTIONS}
            placeholder={formData.walkNo}
            value={formData.petWalk}
            onChange={(e) =>
              setFormData({ ...formData, walkNo: e.value })
            }
          />
        </div>
      </div>
    </div>
  );
};

DogWalkingOptions.defaultProps = {
  setData: () => {},
};

DogWalkingOptions.propTypes = {
  setData: PropTypes.func,
};

export default DogWalkingOptions;
