import React from 'react';
import Flag from '../Assets/icons/flag.png';
import PropTypes from 'prop-types';

const MobileNumberInput = ({ value, onChange }) => {
  return (
    <div className='border-1 flex rounded-md overflow-hidden mt-3 mb-2 font-number'>
      <div className='flex border-r-1 justify-center items-center px-2'>
        <img src={Flag} alt='india' className='w-12 mr-2' />
        <p>+91</p>
      </div>
      <input
        value={value}
        onChange={onChange}
        required
        autoFocus
        type='number'
        className='w-full py-3 pl-3'
      />
    </div>
  );
};

MobileNumberInput.defaultProps = {
  value: '',
  onChange: () => {},
};

MobileNumberInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default MobileNumberInput;
