import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GradientButton, Modal } from '..';
import congrats from '../../Assets/icons/congratsmodalimage.png';
import {
  addPet,
  clearAddPetState,
  editPet,
  resetStep,
} from '../../Redux/actions/addPetActions';
import Container from '../Container';
import PetProfileCard from '../PetProfileCard';

const ReviewPetProfile = ({ editId }) => {
  const petDetails = useSelector(({ addPet }) => addPet);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [visibility, setVisibility] = useState(false);

  const navigate = useNavigate();

  const handleNavigateToHome = () => {
    dispatch(clearAddPetState());
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const resultCallback = (success) => {
      setLoading(false);
      if (success) {
        setVisibility(true);
      }
    };
    setLoading(true);

    if (editId) {
      dispatch(editPet(editId, resultCallback));
    } else {
      dispatch(addPet(resultCallback));
    }
  };

  return (
    <>
      <Container>
        <Modal visibilty={visibility}>
          <div className='relative p-6 px-8 flex-auto max-w-sm'>
            <p className='font-semibold text-7xl flex justify-center'>
              <img src={congrats} alt='' className='w-24 h-24' />
            </p>
            <div className='my-4'>
              <p className='text-dark text-md leading-relaxed text-center'>
                Congratulations!
              </p>
              <p className='text-dark text-md leading-relaxed text-center'>
                {editId
                  ? 'You have successfully edited your pet.'
                  : 'You have successfully registered your pet.'}
              </p>
            </div>
          </div>
          <div className='flex items-center justify-center p-6 rounded-b'>
            <GradientButton
              title='BACK TO DASHBOARD'
              onPress={handleNavigateToHome}
            />
          </div>
        </Modal>
        <div className='md:px-24 justify-center'>
          <h4 className='text-lg'>
            <b>Review your pet profile</b>
          </h4>
          {/* <h5>Lorem ipsum dolor sit amet, consectetur adipiscing.</h5> */}
          <PetProfileCard noEdit data={petDetails} className=' my-4' />
          <div className='flex justify-end'>
            <h2
              className='mt-3 mr-7 cursor-pointer'
              onClick={() => {
                dispatch(resetStep());
              }}
            >
              <b>EDIT PROFILE</b>
            </h2>
            <GradientButton
              disabled={loading}
              onPress={handleSubmit}
              title='SUBMIT'
            />
          </div>
        </div>
      </Container>
    </>
  );
};

ReviewPetProfile.defaultProps = {
  editId: null,
};

ReviewPetProfile.propTypes = {
  editId: PropTypes.string,
};

export default ReviewPetProfile;
