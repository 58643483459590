import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '../Components/Container';
import { Button, Card, CardActions, CardContent, TextField, Typography, Box } from '@material-ui/core';
import { useNavigate } from 'react-router';
import {setRedirect, deleteOrRestoreUser} from '../Redux/actions/authActions';
import { USER } from "../Redux/types";

const UserDeleteRequest = () => {
  const { user, seeker_object } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setRedirect(null));
  }, []);

  if (!user) {
    return (
      <div className='py-4'>
        <Container>
          <Box sx={{ maxWidth: 700, mx: 'auto' }}>
              <Card  className='text-center'>
                <CardContent>
                  <Typography variant="h5" component="h1" gutterBottom>
                    User Account Delete Request
                  </Typography>
                    <br/>
                    <Typography variant="body1">
                      This request form is for deleting data associated with both Pawpurrfect App wrt following links:
                      </Typography>
                      <br/>
                      <a className='link' href='https://play.google.com/store/apps/details?id=com.pawpurrfectseekers'>Pawpurrfect Seekers App</a> <br />
                      <a href='https://play.google.com/store/apps/details?id=com.pawpurrfectproviders'>Pawpurrfect Providers App</a><br />
                      <br/>
                      <Typography variant="body1">
                      Important: Once we confirm with you, all your account data will be permanently erased and cannot be recovered.
                        Please enter your email address to request the deletion of your account. You will receive a confirmation email when your account is deleted.
                    </Typography>
                    
                </CardContent>
                <CardActions>
                  <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', mx: 2}}>
                    <Button size="large" color="primary" onClick={() => {
                      navigate('/login?redirect_to=/user-delete-request')
                    }}>
                        Request Deletion
                    </Button>
                  </Box>
                </CardActions>
              </Card>
          </Box>
        </Container>
      </div>
    );
  }


  if (seeker_object?.account_deleted){
    return (
      <div className='py-4'>
          <Container>
            <Box sx={{ maxWidth: 700, mx: 'auto' }}>
                <Card  className='text-center'>
                  <CardContent>
                    <Typography variant="h5" component="h1" gutterBottom>
                      User Account Delete Request
                    </Typography>
                      <br/>
                      <Typography variant="body1">
                        Please click on button below to delete all of your data in following applications.
                        </Typography>
                        <br/>
                        <a className='link' href='https://play.google.com/store/apps/details?id=com.pawpurrfectseekers'>Pawpurrfect Seekers App</a> <br />
                        <a href='https://play.google.com/store/apps/details?id=com.pawpurrfectproviders'>Pawpurrfect Providers App</a><br />
                        <br/>
                        <Typography variant="body1">
                        your account data will be permanently erased and cannot be recovered after {new Date(seeker_object.account_deletion_time.seconds * 1000).toLocaleString()}.
                      </Typography>
                      
                  </CardContent>
                  <CardActions>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', mx: 2}}>
                    <Button size="large" variant='contained' color="error" onClick={ () => {
                      let data = {account_deleted: false, account_deletion_time: null}
                      deleteOrRestoreUser(data)
                      dispatch({ type: USER.SET_USER_QUERY, payload: { ...user, ...data} });
                    }}>
                        Recover My Account
                    </Button>
                    </Box>
                  </CardActions>
                </Card>
            </Box>
          </Container>
      </div>
    )
  }

  return (
    <div className='py-4'>
        <Container>
          <Box sx={{ maxWidth: 700, mx: 'auto' }}>
              <Card  className='text-center'>
                <CardContent>
                  <Typography variant="h5" component="h1" gutterBottom>
                    User Account Delete Request
                  </Typography>
                    <br/>
                    <Typography variant="body1">
                      Please click on button below to delete all of your data in following applications.
                      </Typography>
                      <br/>
                      <a className='link' href='https://play.google.com/store/apps/details?id=com.pawpurrfectseekers'>Pawpurrfect Seekers App</a> <br />
                      <a href='https://play.google.com/store/apps/details?id=com.pawpurrfectproviders'>Pawpurrfect Providers App</a><br />
                      <br/>
                      <Typography variant="body1">
                      After you click on the link, your account data will be permanently erased and cannot be recovered after 30 days.
                    </Typography>
                    
                </CardContent>
                <CardActions>
                  <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', mx: 2}}>
                    <Button size="large" variant='contained' color="error" onClick={ () => {
                      let data = {account_deleted: true, account_deletion_time: new Date((new Date()).getTime() + (30 * 24 * 60 * 60 * 1000))}
                      deleteOrRestoreUser(data)
                      dispatch({ type: USER.SET_USER_QUERY, payload: { ...user, ...data} });
                    }}>
                        Delete My Account
                    </Button>
                  </Box>
                </CardActions>
              </Card>
          </Box>
        </Container>
    </div>
  )
};

export default UserDeleteRequest;
