import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import sadFace from '../../Assets/sadFace.png';
import tick from '../../Assets/Tick.jpg';
import { GradientButton, Modal } from '../../Components';
import { updateServiceRequest } from '../../Redux/actions/serviceActions';
import RadioButton from '../RadioButton';
import Textarea from '../Textarea';

const ReportModal = ({ reportModal, setReportModal }) => {
  const [problem, setProblem] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [problemDesc, setProblemDesc] = useState('');
  const [disputeRaised, setDisputeRaised] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleReportJob = () => {
    if (!problem) {
      toast.warn('Please select a reason!');
      return;
    }
    setLoading(true);
    dispatch(
      updateServiceRequest(
        { docid: reportModal, data: { report: true } },
        () => {
          setLoading(false);
          setDisputeRaised(true);
          // toast.success('Dispute request send successfully');
          // setReportModal(false);
        }
      )
    );
  };
  const backToMyReq = () => {
    setReportModal(false);
    setDisputeRaised(false);
  };
  return (
    <Modal visibilty={reportModal}>
      {disputeRaised ? (
        <div className='relative py-20 px-10 rounded'>
          <div className='flex justify-center'>
            <img src={tick} alt='' srcSet='' />
          </div>
          <div className='text-lg pt-7 text-center'>
            Your Dispute has been Registered!
          </div>
          <div className='grid grid-cols-4 mt-10'>
            <div></div>
            <div className='col-span-2 mt-4 text-center rounded'>
              <Link to='/my-requests'>
                <GradientButton
                  title='BACK TO MY REQUESTS'
                  onPress={backToMyReq}
                />
              </Link>
            </div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className='relative py-20 px-10 rounded'>
          <div className='flex justify-center'>
            <img src={sadFace} alt='sad' srcSet='' />
          </div>
          <div className='my-4'>
            <p className='text-dark text-md font-semibold leading-relaxed text-center'>
              Let us know what issue you faced with this Service/Expert
            </p>
          </div>
          <h1 className='mb-3'>Select a reason</h1>
          <div className='grid grid-cols-1 md:grid-cols-2'>
            <RadioButton
              name='ReportReason'
              id='ReportReason1'
              title='Job not satisfactory'
              checked={problem === 'Job not satisfactory'}
              onPress={() => setProblem('Job not satisfactory')}
            />
            <RadioButton
              name='ReportReason'
              id='ReportReason2'
              title='Expert not punctual'
              checked={problem === 'Expert not punctual'}
              onPress={() => setProblem('Expert not punctual')}
            />
            <RadioButton
              name='ReportReason'
              id='ReportReason3'
              title='Expert was not well equipped'
              checked={problem === 'Expert was not well equipped'}
              onPress={() => setProblem('Expert was not well equipped')}
            />
            <RadioButton
              name='ReportReason'
              id='ReportReason4'
              title='Others'
              checked={problem === 'Others'}
              onPress={() => setProblem('Others')}
            />
          </div>
          <h3 className='col-span-2 mt-3 text-sm'>
            Why do you feel that way? <span className='text-sm'>(Optional)</span>
          </h3>
          <Textarea
            placeholder='Write a review'
            onChange={(e) => setProblemDesc(e.target.value)}
          />
          <div className='grid grid-cols-2 gap-2 mt-4'>
            <GradientButton
              title='SUBMIT'
              disabled={loading}
              onPress={handleReportJob}
            />
            <GradientButton
              title='CANCEL'
              onPress={() => setReportModal(false)}
              grey
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

ReportModal.defaultProps = {
  reportModal: false,
  setReportModal: () => {},
};

ReportModal.propTypes = {
  reportModal: PropTypes.bool,
  setReportModal: PropTypes.func,
};

export default ReportModal;
