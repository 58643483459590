import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebase";

//@ Function to get download from firebase storage
//> Param: ref - path  of upload image (type: String)

export const getImageUrl = async (imageRef) => {
  const petProfileRef = ref(storage, imageRef);
  return await getDownloadURL(petProfileRef);
};
