import React from 'react';
import Modal from './HOC/Modal';
import PropTypes from 'prop-types';
import GradientButton from './GradientButton';

const Alert = ({ visibility, setVisibility, onConfirm, onCancel, text }) => {
  return (
    <Modal visibilty={visibility}>
      <div className='relative p-4 flex-auto max-w-sm'>
        <div className='my-2'>
          <p className='text-dark text-base font-semibold leading-relaxed text-center'>
            {text}
          </p>
        </div>
      </div>
      <div className='flex items-center justify-center mb-4 rounded-b'>
        <GradientButton
          title='OK'
          onPress={() => {
            setVisibility(false);
            onConfirm();
          }}
          size='sm'
        />
        {onCancel && (
          <>
            <div className='w-4' />
            <GradientButton
              title='Cancel'
              onPress={() => {
                setVisibility(false);
              }}
              size='sm'
              grey
            />
          </>
        )}
      </div>
    </Modal>
  );
};

Alert.propTypes = {
  visibility: PropTypes.bool,
  setVisibility: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Alert;
