/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import '../CSS/carousel.css'; // requires a loader
import { Carousel as GridCarousel } from 'react-responsive-carousel';
// import { useSelector } from 'react-redux';

const Carousel = ({
  className,
  renderChild,
  items,
  numColumns,
  onItemClick,
}) => {
  // const { services } = useSelector(({ service }) => service);
  const renderArray = useMemo(() => {
    let res = [];

    // don't show if already request placed
    // services.map((item) => {
    //   res.push(item.petId);
    // });
    // items = items.filter((item) => !res.includes(item.id));

    res = [];

    items.forEach((item, i) => {
      if (i % 2 === 0) {
        res.push([item]);
      } else {
        const prev = res.pop();
        res.push([prev[0], item]);
      }
    });
    return res;
  }, [items.length]);

  return (
    <GridCarousel
      showStatus={false}
      showThumbs={false}
      emulateTouch
      className={`${className} `}
    >
      {renderArray.map((item, i) => {
        return (
          <div className={`grid grid-cols-${numColumns} gap-3`} key={i}>
            {item.map((data) => (
              <div key={data.id} onClick={() => onItemClick(data)}>
                {renderChild(data)}
              </div>
            ))}
          </div>
        );
      })}
    </GridCarousel>
  );
};

Carousel.defaultProps = {
  className: '',
  numColumns: 1,
  onItemClick: () => {},
};

Carousel.propTypes = {
  className: PropTypes.string,
  renderChild: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  numColumns: PropTypes.number,
  onItemClick: PropTypes.func,
};

export default Carousel;
