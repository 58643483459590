import React from 'react';
import PropTypes from 'prop-types';

const Input = (props) => {
  const { Icon } = props;
  return (
    <div
      className={`flex border-1 overflow-hidden my-3 first:mt-0 font-number rounded-md last:mb-0 relative hover:border-action focus:border-action`}
    >
      <div className='absolute absolute-center-x left-3'>
        <Icon size={26} fill='#79d1bb' />
      </div>
      <input {...props} className={`py-3 pl-12 w-full`} />
    </div>
  );
};

Input.defaultProps = {
  Icon: () => {},
};
Input.propTypes = {
  Icon: PropTypes.func,
};

export default Input;
