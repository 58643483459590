import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const AddPetCard = () => {
  return (
    
    <div className=' justify-center lg:mt-0 mt-3'> 
      <Link to='/add-pet' className='flex flex-row'>
        <div className='w-8 h-8 ml-3 lg:mt-[-4px] mt-1 mt-[-6px] border-1 border-solid bg-grey rounded-full flex items-center justify-center cursor-pointer'>
          <FaPlus size={14} fill='#79d1bb' />
        </div>
        <p className='text-base font-bold ml-1 '>Add Pet</p>
      </Link>
    </div>
  );
};

export default AddPetCard;
