import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import sadCat from "../Assets/sadcat.png";
import { getAuth } from "firebase/auth";
// import Carousel from 'carousel-react-rcdev'
import {
  AddPetCard,
  BoardingOptions,
  Calender,
  Carousel,
  ChipCard,
  Container,
  DateOptions,
  DogWalkingOptions,
  GradientButton,
  GroomingOptions,
  InputSelect,
  PetCard,
  RadioButton,
  SittingOptions,
  Textarea,
  TrainingOptions,
  VetOptions,
  InsuranceCard,
  SearchInput,
  PathologyPackageCard,
  PathologyTestCard,
} from "../Components";
import {
  SubmitModal,
  CancelRequestModal,
  InvalidInsuranceModel,
  InvalidAgeInsuranceModel,
  InsuranceModel,
} from "../Modal";
import TopOptionsPathology from "../Components/RequestPageComponents/TopOptionsPathology";
import {
  ServiceOptions,
  SERVICES_TEXT,
  SERVICE_OPTIONS,
  INSURANCE_OPTIONS,
  TimeOptions,
} from "../Constants/Constants";
import { setCreateReq } from "../Redux/actions/createReqAction";
import { addServices } from "../Redux/actions/petPathologyActions";
import { getDateArray, getOfferStatus, isEmpty, validateTimeStamp } from "../utils/utils";
import store from "../Redux/store";


const initialState = {
  selectedPet: "",
  selectedService: "",
  date: "",
  endDate: "",
  serviceDatas: [],
  userRequirements: {},
  optionalRequirements: "",
  visitType: "Home Visit",
  walkDuration: "",
  disabledDays: [],
};

const isInTimeSlot = ([start, end], value) => {
  try {
    if (value === start) {
      return true;
    }
    if (value === end) {
      return false;
    }
    if (value > start && value < end) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export let selectedPetForInsurance;
export let selectedDateForPathology;
export let selectedTimeForPathology;
export let selectedPackage;

const CreateRequestPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();

  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    ...initialState,
    selectedService: location?.state?.type || "",
  });
  const [insuranceData, setInsuranceData] = useState({
    insuranceCompany: "",
    insuranceCompanyImage: "",
  });
  const [selectedPathologyService, setSelectedPathologyService] = useState([]);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const { selectedPet, selectedService, date, serviceDatas, visitType, endDate } =
    formData;

  //Modal States
  const [modalVisibility, setModalVisibility] = useState(false);
  const [invalidInsuranceVisibility, setInvalidInsuranceVisibility] = useState(false);
  const [invalidAgeInsuranceVisibility, setInvalidAgeInsuranceVisibility] =
    useState(false);
  const [dogInsuranceVisibility, setDogInsuranceVisibility] = useState(false);
  const [multipleDays, setMultipleDays] = useState(1);
  const [submitmodalVisibility, setSubmitmodalVisibility] = useState(false);
  const [expertId, setExpertId] = useState(null);
  const [pet, setPet] = useState("");

  const {
    pet: { pets },
  } = useSelector(({ pet }) => ({ pet }));
  const { services } = useSelector(({ service }) => service);
  const pathologyConstData = useSelector((state) => state.petPathology);
  const PATHOLOGY_PACKAGES = pathologyConstData.pathologyPackage;
  const PATHOLOGY_OPTIONS = pathologyConstData.pathologyTest;
  let completedServices = services.filter((item) => {
    if (getOfferStatus(item.request_open_for_offers) === "Completed") return true;
    return false;
  });
  let previousExperts = completedServices.map((item) => {
    return item.booked_expert;
  });
  previousExperts = [
    ...new Map(previousExperts.map((item) => [item["air_table_id"], item])).values(),
  ];

  let repeatData = null;

  const removeDuplicateFromService = () => {
    formData.serviceDatas = selectedPathologyService;
    dispatch(addServices(selectedPathologyService));
  };

  useEffect(() => {
    repeatData = completedServices.find((item) => {
      return item.booked_expert.air_table_id === expertId;
    })?.offered_provider_id;
  }, [expertId]);

  const handleCreateRequest = () => {
    if (!validateTimeStamp(startTime, date)) {
      return;
    }
    if (
      [SERVICE_OPTIONS.VET, SERVICE_OPTIONS.GROOMING].includes(selectedService) &&
      serviceDatas.length === 0
    ) {
      toast.warn("No services selected");
      return;
    }

    const serviceData = {
      petId: formData.selectedPet,
      service: formData.selectedService,
      date,
      startTime,
      endTime,
      endDate,
      multipleSelect,
      multipleDays,
      userRequirements: formData.userRequirements,
      serviceDatas: formData.serviceDatas,
      optionalRequirements: formData.optionalRequirements,
      visitType,
    };

    const handleNewRequest = () => {
      serviceData.reqType = "new";
      serviceData.repeatData = null;
      dispatch(
        setCreateReq(serviceData, (success) => {
          if (success) {
            navigate("/congrats", {
              state: { service: SERVICES_TEXT[formData.selectedService] },
            });
            setFormData(initialState);
            setStartTime();
            setEndTime("");
          }
        })
      );
    };

    // eslint-disable-next-line no-unused-vars
    const next = () => {
      serviceData.reqType = "repeat";
      serviceData.repeatData = [repeatData];
      dispatch(
        setCreateReq(serviceData, (success) => {
          if (success) {
            navigate("/congrats", {
              state: { service: SERVICES_TEXT[formData.selectedService] },
            });
            setFormData(initialState);
            setStartTime();
            setEndTime("");
          }
        })
      );
    };

    if (previousExperts.length > 0) setSubmitmodalVisibility(true);
    else handleNewRequest();

    return {
      handleNewRequest: handleNewRequest,
      next: next,
    };
  };

  const multipleSelect =
    selectedService === SERVICE_OPTIONS.SITTING ||
    selectedService === SERVICE_OPTIONS.BOARDING ||
    selectedService === SERVICE_OPTIONS.WALKING;

  const [nextBtn, setNextBtn] = useState(true);
  useEffect(() => {
    const petDet = pets.find((item) => {
      return item.id === formData.selectedPet;
    });
    setPet(petDet);
  }, [formData.selectedPet]);

  return (
    <Container className="py-4 md:py-8">
      {/**
       * Modal for selecting the expert
       * If already have a previous expert
       */}
      <SubmitModal
        submitModalVisibility={submitmodalVisibility}
        setSubmitModalVisibility={() => setSubmitmodalVisibility(false)}
        name={pet?.name}
        previousExperts={previousExperts}
        expertId={expertId}
        setExpertId={setExpertId}
        newRequest={() => handleCreateRequest().handleNewRequest()}
        next={() => handleCreateRequest().next()}
      />
      {/*
           - Cancel Request Model
           - Open when user click on cancel request button
      */}
      <CancelRequestModal
        modalVisibility={modalVisibility}
        imageURl={sadCat}
        onPressYes={() => navigate("/")}
        onPressNo={() => setModalVisibility(false)}
      />
      {/*
           - Invalid Insurance Model
           - Open when pet insurance is not available or we don't provide insurance for that pet
      */}
      <InvalidInsuranceModel
        invalidInsuranceVisibility={invalidInsuranceVisibility}
        onPressBack={() => setInvalidInsuranceVisibility(false)}
      />
      <InvalidAgeInsuranceModel
        // invalidInsuranceVisibility={invalidInsuranceVisibility}
        invalidInsuranceVisibility={invalidAgeInsuranceVisibility}
        onPressBack={() => setInvalidAgeInsuranceVisibility(false)}
      />

      {/*
            - Insurance Confirmation Model
    */}
      <InsuranceModel
        dogInsuranceVisibility={dogInsuranceVisibility}
        petData={pet}
        onPressConfirm={() => setNextBtn(!nextBtn)}
        onPressBack={() => (setNextBtn(true), setDogInsuranceVisibility(false))}
        onPressNext={() => {
          !nextBtn;
          //redirect to new url in new tab
          // eslint-disable-next-line no-undef
          window.open(
            `${"http://localhost:3001"}/pet-insurance/${selectedPet}`,
            "_blank"
          );
        }}
        nextBtn={nextBtn}
      />
      <div className="md:flex sm:grid sm:grid-cols-1 justify-between md:pb-3">
        <h4 className="text-lg text-center">
          <b>Create Request</b>
        </h4>
        <div className="flex justify-center mt-2 md:mt-0">
          <GradientButton
            onPress={() => setModalVisibility(true)}
            title="CANCEL REQUEST"
          />
        </div>
      </div>
      <div className="grid gap-5 gap-y-3">
        <div className="grid md:grid-cols-2 gap-5">
          <div>
            <div className="flex justify-between">
              {selectedService !== SERVICE_OPTIONS.INSURANCE ? (
                <h6 className="text-center md:text-left mt-2 md:mt-0 text-justify lg:w-[400px] w-[200px]">
                  Select your pet that needs medical assistance
                </h6>
              ) : (
                <h6 className="text-center md:text-left mt-2 md:mt-0 text-justify lg:w-[400px] w-[200px]">
                  Select your pet for whom you want insurance
                </h6>
              )}
              <AddPetCard />
            </div>

            {selectedService === SERVICE_OPTIONS.INSURANCE && (
              <h3 className="bg-lightYellow mt-[13.16px] rounded-md pt-[8.61px] font-roboto text-center text-[13.09px] font-normal h-[36.49px] lg:w-[350px] mb:w-full">
                Currently insurance partners only offer policies for dogs
              </h3>
            )}

            <div className="grid grid-cols-2 mt-4 gap-3">
              {pets.length < 3 ? (
                pets.map((item) => (
                  <div
                    onClick={() => {
                      setFormData({ ...formData, selectedPet: item.docid }),
                        setInsuranceData({
                          insuranceCompany: "",
                          insuranceCompanyImage: "",
                        });
                    }}
                    key={item.id}
                  >
                    <PetCard
                      item={item}
                      selected={selectedPet === item.docid}
                      className="mb-12 "
                    />
                  </div>
                ))
              ) : (
                <Carousel
                  className="col-span-3"
                  items={pets}
                  numColumns={2}
                  onItemClick={(data) => (
                    setFormData({ ...formData, selectedPet: data.docid }),
                    setInsuranceData({ insuranceCompany: "", insuranceCompanyImage: "" })
                  )}
                  renderChild={(item) => (
                    <PetCard
                      item={item}
                      key={item.id}
                      selected={selectedPet === item.docid}
                      className="mb-12 "
                    />
                  )}
                />
              )}
            </div>

            <div className="hidden">
              {(selectedPetForInsurance = formData.selectedPet)}
            </div>
          </div>

          <div>
            <h6>Select a service</h6>
            <div className="grid grid-cols-2 mt-4 gap-3">
              {ServiceOptions.map(({ image, title, value ,advt }) => (
                <ChipCard
                  title={title}
                  disabled={!selectedPet}
                  isPetSelected={selectedPet == "" ? false : true}
                  key={title}
                  isSelected={value === formData.selectedService}
                  fill={false}
                  Icon={image}
                  advt={advt}
                  onPress={() =>
                  {
                    console.log("value", value);
                    if(value === "dog_insurance"){
                      window.open(`${process.env.REACT_APP_INSURANCE_WEBAPP}/${auth.currentUser.uid}`, '_blank');
                    }
                    else{
                      setFormData({
                        ...formData,
                        selectedService: value,
                        serviceDatas: [],
                        date: "",
                        endDate: "",
                      })
                    }
                  }
                  }
                />
              ))}
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-5">
          <div>
            {selectedService === SERVICE_OPTIONS.WALKING && (
              <div>
                <h6 className="mt-3">Select duration walk</h6>
                <div className="flex gap-4 mt-[-10px]">
                  {
                    <DogWalkingOptions
                      setData={(e) => {
                        const userRequirements = {
                          petWalk: e.petWalk,
                          per_day_allotment: e.walkNo,
                        };
                        const serviceDatas = ["oneDay", e.duration];
                        setFormData({ ...formData, serviceDatas, userRequirements });
                      }}
                    />
                  }
                </div>
              </div>
            )}
            {selectedService === SERVICE_OPTIONS.TRAINING ? (
              <TrainingOptions
                setData={({ type, sessions }) => {
                  setFormData({ ...formData, serviceDatas: [`${type}${sessions}`] });
                }}
              />
            ) : null}
            {/* //TODO: What is multipleSelect */}

            {multipleSelect && (
              <DateOptions
                startDate={date}
                endDate={endDate}
                setMultipleDate={setMultipleDays}
                multipleDays={multipleDays}
                setStartDate={(date) =>
                  setFormData({
                    ...formData,
                    date,
                    endDate: !endDate ? endDate : date,
                  })
                }
                setEndDate={(endDate) => {
                  if (!date) {
                    toast.error("Select a start date");
                    return;
                  }
                  setFormData({
                    ...formData,
                    endDate,
                  });
                }}
              />
            )}

            {/* Displaying the selected date calender   
                Except for the Pet Insurance S 
            */}
            {selectedService !== SERVICE_OPTIONS.INSURANCE ? (
              <>
                <h6>Select your preferred date</h6>
                <div className="bg-lightYellow mt-3 p-2 rounded w-50 text-sm">
                  You can make a request upto 90 days in advance
                </div>
                <Calender
                  type={selectedService}
                  multipleDays={multipleDays}
                  date={getDateArray(date, endDate)}
                  setDate={(date) => {
                    if (!multipleSelect) {
                      setFormData({
                        ...formData,
                        date,
                        endDate: date,
                      }),
                        (selectedDateForPathology = date);
                    } else {
                      toast.warn("Please select date from calendar!");
                    }
                  }}
                />
              </>
            ) : (
              //  - If Pet Insurance is selected then displaying the Insurance Companies

              <div>
               
              </div>
            )}
          </div>

          {/* - Displaying the selected date calender 
              - Except for the Pet Insurance 
          */}
          {selectedService !== SERVICE_OPTIONS.INSURANCE && (
            <div>
              <h6>Select your preferred time</h6>

              {/*
              - If the selected service is Vet Or Grooming 
              - Displaying Text of 1 hr duration
              ------ Else
              - If the selected service is Training
              - Displaying Text of 35-40 hr duration
            */}
              {selectedService === SERVICE_OPTIONS.VET ||
              selectedService == SERVICE_OPTIONS.GROOMING ? (
                <div className="bg-lightYellow mt-3 p-2 rounded w-50 text-sm">
                  Each Time Slot is of 1 hour duration
                </div>
              ) : selectedService === SERVICE_OPTIONS.TRAINING ? (
                <div className="bg-lightYellow mt-3 p-2 rounded w-50 text-sm">
                  Each Training Session is of 35-40 min duration
                </div>
              ) : null}

              {/* 
               - Displaying the Time Slots Input Field 
               - To Pick Between Two Time Slots 
            */}

              {multipleSelect && (
                <div className="grid grid-cols-2 mt-3 gap-3">
                  <InputSelect
                    placeholder="Start Time"
                    className="font-number"
                    options={TimeOptions}
                    controlled
                    value={startTime}
                    onChange={(e) => {
                      if (validateTimeStamp(e.value, date)) {
                        setStartTime(e.value);
                        if (!endTime || e.value >= endTime) {
                          setEndTime(e.value + 1);
                        }
                      }
                    }}
                  />
                  {
                    // End Time Select Input Field
                    // Not Available for walking Service
                    selectedService === SERVICE_OPTIONS.WALKING ? null : (
                      <InputSelect
                        placeholder="End Time"
                        options={[
                          ...TimeOptions,
                          {
                            label: "11:00 PM",
                            value: 23,
                            availability: true,
                          },
                        ].filter(({ value }) => value > startTime)}
                        disabled={!startTime}
                        className="font-number"
                        value={endTime}
                        controlled
                        onChange={(e) => {
                          if (validateTimeStamp(e.value, date)) {
                            setEndTime(e.value);
                          }
                        }}
                      />
                    )
                  }
                </div>
              )}
              {
                // Time Slots Calender
              }
              <div className="grid grid-cols-3 gap-3 mt-4">
                {TimeOptions.map(({ value, label, availability }) => (
                  <ChipCard
                    title={label}
                    key={label}
                    fill
                    isSelected={
                      multipleSelect
                        ? isInTimeSlot([startTime, endTime], value)
                        : startTime === value
                    }
                    onPress={() => {
                      if (multipleSelect) {
                        toast.warn("Please select time from dropdown!");
                        return;
                      }
                      if (!validateTimeStamp(value, date)) {
                        return;
                      }

                      if (multipleSelect) {
                        if (!startTime && value !== startTime) {
                          setStartTime(value);
                          setEndTime(value + 1);
                        } else {
                          setEndTime(value);
                        }
                      } else {
                        setStartTime(value);
                        setEndTime(null);
                        selectedTimeForPathology = value;
                      }
                    }}
                    disabled={!date || !availability}
                  />
                ))}
              </div>
            </div>
          )}
          {
            // - Displaying the Pet Insurance Companies
            // - If the Pet Insurance is selected
          }
          {insuranceData.insuranceCompany !== "" &&
            selectedService === SERVICE_OPTIONS.INSURANCE && (
              <div className="border-1 border-[#32b6a5] rounded-md p-4">
                <img
                  src={insuranceData.insuranceCompanyImage}
                  className="w-[34%] ml-[33%]"
                />
                <div className="flex flex-col lg:flex-row justify-between">
                  {INSURANCE_OPTIONS.map((item) =>
                    item.typeOfInsurance.map((plane, index) => (
                      <InsuranceCard
                        key={index}
                        title={plane.title}
                        plane={plane.plane}
                      />
                    ))
                  )}
                </div>
              </div>
            )}
        </div>

        <div className="grid grid-cols-1 gap-5">
          {selectedService === SERVICE_OPTIONS.VET && (
            <div>
              <h6>Select services</h6>
              <div className="flex gap-4 mt-4">
                <RadioButton
                  name="createReq"
                  id="home"
                  disabled={!selectedPet}
                  key={"Home Visit"}
                  title={"Home Visit"}
                  checked={visitType === "Home Visit"}
                  onPress={() =>
                    setFormData({
                      ...formData,
                      serviceDatas: [],
                      visitType: "Home Visit",
                    })
                  }
                />
              </div>
            </div>
          )}
          {selectedService === SERVICE_OPTIONS.VET ? (
            <VetOptions
              type={visitType}
              options={serviceDatas}
              toggleData={(data) => {
                setFormData((prev) => ({
                  ...prev,
                  serviceDatas: serviceDatas.includes(data)
                    ? serviceDatas.filter((item) => item !== data)
                    : [...serviceDatas, data],
                }));
              }}
            />
          ) : selectedService === SERVICE_OPTIONS.BOARDING ? (
            <BoardingOptions
              setData={(e) => {
                const userRequirements = {
                  pickService: e.pickService,
                  selfFeed: e.selfFeed,
                  petWalk: e.petWalk,
                  per_day_allotment: e.walkNo,
                };
                const serviceDatas = ["oneDay"];
                setFormData({ ...formData, serviceDatas, userRequirements });
              }}
            />
          ) : selectedService === SERVICE_OPTIONS.GROOMING ? (
            <GroomingOptions
              setData={(e) => setFormData({ ...formData, serviceDatas: e })}
            />
          ) : selectedService === SERVICE_OPTIONS.SITTING ? (
            <SittingOptions
              setData={(e) => {
                const userRequirements = {
                  petWalk: e.petWalk,
                  per_day_allotment: e.walkNo,
                };
                const serviceDatas = ["oneDay"];
                setFormData({ ...formData, serviceDatas, userRequirements });
              }}
            />
          ) : null}
        </div>
        {
          //TODO: Search Input Not Working
        }
        {selectedService === SERVICE_OPTIONS.PATHOLOGY && (
          <div>
            <h6>Select your preferred test / test packages</h6>
            <div className="bg-lightYellow mt-3 p-2 rounded lg:w-[460px] mb:w-full text-sm">
              Tested already present in a package cannot be selected individually
            </div>
            <div className="flex flex-wrap justify-between">
              <TopOptionsPathology />
              <SearchInput value={search} onChange={setSearch} />
            </div>
            <div className="flex justify-end">
              <GradientButton
                className="h-[58.8px] mt-5"
                title="Next"
                disabled={selectedPathologyService.length === 0}
                onPress={() => (
                  removeDuplicateFromService(),
                  !selectedTimeForPathology
                    ? toast.warn("Please Select Date & Time")
                    : navigate("/pet-pathology")
                )}
              />
            </div>
            {(store.getState().pathologyTopButtonReducer.type === "Packages" ||
              store.getState().pathologyTopButtonReducer.type === "All") && (
              <div className="mt-[-70px]">
                <h6 className="font-semibold font-roboto text-[25px] my-8">Packages</h6>
                <hr className="flex flex-col justify-between border-[2px] border-[#7CCBBF] mb-6 bg-[#7CCBBF]" />
                <div className="flex flex-wrap gap-2 gap-y-5">
                  {PATHOLOGY_PACKAGES.filter(
                    (item) =>
                      item.title.toLowerCase().includes(search.toLowerCase()) ||
                      item.detail.toLowerCase().includes(search.toLowerCase())
                  ).map((item, index) => (
                    <PathologyPackageCard
                      key={index}
                      title={item.title}
                      detail={item.detail}
                      price={item.price}
                      isChecked={
                        selectedPathologyService?.findIndex(
                          (el) => el.name === item.title
                        ) !== -1
                          ? true
                          : false
                      }
                      onChangeCapture={() => {
                        index = selectedPathologyService.findIndex(
                          (x) => x.name === item.title
                        );
                        if (index > -1) {
                          setSelectedPathologyService(
                            selectedPathologyService.filter((x) => x.name !== item.title)
                          );
                        } else {
                          setSelectedPathologyService([
                            ...selectedPathologyService,
                            {
                              name: item.title,
                              detail: item.detail,
                              price: item.price,
                              isPackage: item.isPackage,
                              isTest: item.isTest,
                            },
                          ]);
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
            {(store.getState().pathologyTopButtonReducer.type === "Tests" ||
              store.getState().pathologyTopButtonReducer.type === "All") && (
              <div
                className={`${
                  store.getState().pathologyTopButtonReducer.type === "All"
                    ? "mt-[0px]"
                    : "mb:mt-[-95px] lg:mt-[-70px]"
                }`}
              >
                <h6
                  className={`font-semibold font-roboto text-[25px] my-8 mb:w-[130px] lg:w-[250px]`}
                >
                  Individual Tests
                </h6>
                <hr className="border-[2px] border-[#7CCBBF] mb-6 bg-[#7CCBBF]" />
                <div className="flex flex-wrap gap-2 gap-y-5 overflow-hidden overflow-hidden overflow-scroll overflow-x-hidden h-[650px] pb-5 ">
                  {PATHOLOGY_OPTIONS.filter(
                    (item) =>
                      item.title.toLowerCase().includes(search.toLowerCase()) ||
                      item.detail.toLowerCase().includes(search.toLowerCase())
                  ).map((item, index) => (
                    <PathologyTestCard
                      key={index}
                      detail={item.detail}
                      price={item.price}
                      isChecked={
                        selectedPathologyService?.findIndex(
                          (el) => el.name === item.title
                        ) !== -1
                          ? true
                          : false
                      }
                      onChangeCapture={() => {
                        index = selectedPathologyService.findIndex(
                          (x) => x.name === item.title
                        );
                        if (index > -1) {
                          setSelectedPathologyService(
                            selectedPathologyService.filter((x) => x.name !== item.title)
                          );
                        } else {
                          setSelectedPathologyService([
                            ...selectedPathologyService,
                            {
                              name: item.title,
                              detail: item.detail,
                              price: item.price,
                              isPackage: item.isPackage,
                              isTest: item.isTest,
                            },
                          ]);
                        }
                      }}
                    />
                  ))}
                </div>
                <hr className="border-[2px] border-[#7CCBBF] mb-6 bg-[#7CCBBF]" />
              </div>
            )}
          </div>
        )}
        {
          // TODO: Refactor this code
          // - Remove useLess Code
          // - Displaying TextArea
        }
        {(selectedService == SERVICE_OPTIONS.TRAINING ||
          selectedService == SERVICE_OPTIONS.VET) && (
          <div className="mt-3">
            <h6 className="text-base">
              Any other services or specific requests?{" "}
              <span className="text-sm">(Optional)</span>
            </h6>
            <Textarea
              placeholder="Enter message"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  optionalRequirements: e.target.value,
                })
              }
            />
          </div>
        )}
        {
          // Grooming
          // - TextArea
        }
        {selectedService === SERVICE_OPTIONS.GROOMING && (
          <div className="mt-3 grid sm:grid-cols-1 md:grid-cols-2 md:gap-7">
            <div>
              <h6 className="text-base">
                Any other serv or specific requests?{" "}
                <span className="text-sm">(Optional)</span>
              </h6>
              <Textarea
                placeholder="Enter message"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    optionalRequirements: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <h6 className="text-base">
                Any specific requests? <span className="text-sm">(Optional)</span>{" "}
              </h6>
              <Textarea
                placeholder="Enter message"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    optionalRequirements: e.target.value,
                  })
                }
              />
            </div>
          </div>
        )}
        <div className="flex justify-center">
          {selectedService !== SERVICE_OPTIONS.INSURANCE ? (
            selectedService !== SERVICE_OPTIONS.PATHOLOGY && (
              <GradientButton
                title="PLACE REQUEST"
                onPress={handleCreateRequest}
                disabled={
                  !isEmpty({ ...formData, startTime }, [
                    "endTime",
                    "optionalRequirements",
                    "visitType",
                    "walkDuration",
                  ])
                }
              />
            )
          ) : (
            <>
              <GradientButton
                title="BACK"
                onPress={() => {
                  navigate("/");
                }}
                className={"mr-2 bg-blue-300 text-blue-500"}
                // disabled={
                //   !isEmpty({ ...formData, startTime }, ['endTime', 'optionalRequirements', 'visitType', 'walkDuration'])
                // }
              />
              <GradientButton
                title="NEXT"
                onPress={() => {
                  if (
                    selectedService == SERVICE_OPTIONS.INSURANCE &&
                    (pet.type == "dog" || pet.type == "Dog")
                  ) {
                    let age = Number(pet.age) * 12 + Number(pet.months);
                    if (age > 6) {
                      setDogInsuranceVisibility(true);
                    }
                    else{
                      setInvalidAgeInsuranceVisibility(true);
                    }
                  } else {
                    setInvalidInsuranceVisibility(true);
                  }
                }}
                className={"ml-2"}
              />
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default CreateRequestPage;
