import React from "react";
import PropTypes from "prop-types";

const PathologyPackageCard = ({ title, detail, price, onChangeCapture, isChecked }) => {
  return (
    <div className="flex flex-col justify-between lg:w-[273.53px] mb:w-full border-1 border-[#C0C0C0] rounded-md">
      <div className="p-2">
        <p className="font-sans text-[18px] font-semibold my-4">{title}</p>
        <hr className="border-yellow w-[80%]"></hr>
        <p className="font-sans text-[14px] font-normal text-[#818181] mt-2">{detail}</p>
      </div>

      <div className=" flex justify-between lg:w-[271.13px] mb:w-full h-[64.09px] border-t-1 border-[#C0C0C0] ">
        <p className="text-[25px] text-[#1EAF9C] font-semibold font-sans mt-2 px-2">
          Rs. {price}
        </p>
        <input
          type="checkbox"
          className="checkboxStyle3"
          defaultChecked={isChecked}
          // className='w-[34.77px] h-[34.77px] mt-3 mr-3'
          onChangeCapture={onChangeCapture}
        />
      </div>
    </div>
  );
};

PathologyPackageCard.propTypes = {
  title: PropTypes.string,
  detail: PropTypes.string,
  onChangeCapture: PropTypes.func,
  price: PropTypes.number,
  isChecked: PropTypes.bool,
};
PathologyPackageCard.defaultProps = {
  isChecked: false,
};
export default PathologyPackageCard;
