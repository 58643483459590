import PropTypes from 'prop-types';
import React from 'react';
import { GradientButton } from '..';

const TopButtons = ({ sec, type }) => {
  if (type === 'edit') sec += 1;
  return (
    <div
      className={`grid ${
        type === 'edit' ? 'md:grid-cols-2' : 'md:grid-cols-3'
      } gap-4 sm:grid-cols-1`}
    >
      <GradientButton
        title='PET TYPE'
        className={`rounded-none h-10 text-sm md:text-base ${
          type === 'edit' && 'hidden md:hidden'
        } ${(sec === 1 || sec === 2) && 'hidden md:block'}`}
        size={'xs'}
        active={sec === 0 ? true : false}
        done={sec === 1 || sec === 2 ? true : false}
      />
      <GradientButton
        title='PET DETAILS'
        className={`rounded-none h-10 text-sm md:text-base ${
          (sec === 0 || sec === 2) && 'hidden md:block'
        }`}
        size={'xs'}
        disabled={sec === 0 ? true : false}
        active={sec === 1 ? true : false}
        done={sec === 2 ? true : false}
      />
      <GradientButton
        title='PET LOCATION'
        className={`rounded-none h-10 text-sm md:text-base ${
          (sec === 0 || sec === 1) && 'hidden md:block'
        }`}
        size={'xs'}
        disabled={sec === 1 || sec === 0 ? true : false}
        active={sec === 2 ? true : false}
      />
    </div>
  );
};

TopButtons.defaultProps = {
  type: null,
};

TopButtons.propTypes = {
  sec: PropTypes.number,
  type: PropTypes.string,
};

export default TopButtons;
