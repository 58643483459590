import { getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { providerDetailsRef } from "../../firebase";
import { TRENDING_EXPERTS } from "../types";

export const fetchTrendingExperts = () => async (dispatch) => {
  try {
    const q = query(
      providerDetailsRef,
      where("expert_job_completed_count", ">", 0),
      orderBy("expert_job_completed_count", "desc"),
      orderBy("expert_avg_rating", "desc"),
      limit(limit)
    );
    const querySnapshot = await getDocs(q);
    const result = [];
    querySnapshot.forEach((doc) => {
      result.push(doc.data());
    });

    // console.log(result);

    dispatch({
      type: TRENDING_EXPERTS.FETCH_TRENDING_EXPERTS,
      payload: result,
    });
  } catch (e) {
    console.error(e);
  }
};
