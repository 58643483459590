import PropTypes from 'prop-types';
import React from 'react';

const PetButtons = ({ className, title, Icon, selectedPet, onPress }) => {
  return (
    <button
      onClick={onPress}
      type='button'
      className={`py-2 ${className} border-solid border-2  ${
        selectedPet === title ? ' border-action' : 'bg-white'
      } `}
    >
      <div className='flex ml-4 mb-1'>
        <img src={Icon} alt='' className={`${title === 'Ferret' ? 'w-9 ml-[-4px] ': 'w-6'} ${title === 'Pig' ? 'mt-[10px] w-7' : null}`} />
        <p className={`font-semibold text-lightDark text-md mt-1 ml-3`}>
          {title}
        </p>
      </div>
    </button>
  );
};

PetButtons.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  Icon: PropTypes.string,
  selectedPet: PropTypes.string,
  onPress: PropTypes.func,
};

export default PetButtons;
