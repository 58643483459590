import React from "react";
import PropTypes from "prop-types";
import { MdOutlineTimer } from "react-icons/md";
import { getTimeLeft } from "../utils/utils";

const NoOfferReceived = ({ expireTime }) => {
  return (
    <div className="flex items-center justify-center my-24 w-full">
      <div className="bg-primary p-4 px-8 rounded-md">
        <h2 className="text-md text-white font-semibold mb-1">
          No Offer received
        </h2>
        <div className="flex items-center">
          <p className="text-base font-bold text-white">Expires</p>
          <MdOutlineTimer fill="white" className="mx-1" />
          <p className="text-base text-white font-number">
            {getTimeLeft(expireTime, "expiry")}
          </p>
        </div>
      </div>
    </div>
  );
};

NoOfferReceived.propTypes = {
  expireTime: PropTypes.object,
};

export default NoOfferReceived;
