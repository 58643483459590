import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GradientButton } from "..";
import bird from "../../Assets/icons/bird.svg";
import cat from "../../Assets/icons/cat.svg";
import dog from "../../Assets/icons/dog.svg";
import ferret from "../../Assets/icons/ferret.svg";
import gpig from "../../Assets/icons/guinea_pig.png";
import others from "../../Assets/icons/others.svg";
import pig from "../../Assets/icons/pig.svg";
import rabbit from "../../Assets/icons/rabbit.svg";
import reptiles from "../../Assets/icons/reptile.svg";
import { incrementStep, setPetType } from "../../Redux/actions/addPetActions";
import PetButtons from "./PetButtons";

const SectionOne = () => {
  const { type } = useSelector(({ addPet }) => addPet);
  const [selectedPet, setSelectedPet] = useState(type);
  const dispatch = useDispatch();
  const petOptions = [
    { icon: dog, title: "Dog" },
    { icon: cat, title: "Cat" },
    { icon: bird, title: "Birds" },
    { icon: rabbit, title: "Rabbit" },
    { icon: gpig, title: "Guinea Pig" },
    { icon: pig, title: "Pig" },
    { icon: ferret, title: "Ferret" },
    { icon: reptiles, title: "Reptiles" },
    { icon: others, title: "Others" },
  ];
  console.log(selectedPet);

  return (
    <div className="pb-6 w-full">
      <h4 className="text-lg font-bold">Select your pet type</h4>
      <h5 className="mb-6">
        {/* Lorem ipsum dolor sit amet, consectetur adipiscing. */}
      </h5>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4">
        {petOptions.map(({ title, icon }) => (
          <PetButtons
            title={title}
            className={"rounded-md"}
            key={title}
            Icon={icon}
            onPress={() => setSelectedPet(title)}
            selectedPet={selectedPet}
          />
        ))}
      </div>
      <div className="flex justify-center md:justify-end mt-6">
        <GradientButton
          title="NEXT"
          onPress={() => {
            dispatch(setPetType({ type: selectedPet.toLowerCase() }));
            dispatch(incrementStep());
          }}
          disabled={selectedPet ? false : true}
        />
      </div>
    </div>
  );
};

export default SectionOne;
