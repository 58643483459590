import { getDocs, limit, query, where } from 'firebase/firestore';
import { auth, petsRef } from '../../firebase';
import { COMPLETED_NEARBY_EXPERTS } from '../types';

export const fetchCompletedExperts = () => async (dispatch) => {
  try {
    const q = query(
      petsRef,
      where('owners', 'array-contains', auth.currentUser.uid),
      where('status', '==', 'submitted'),
      limit(1)
    );

    const querySnapshot = await getDocs(q);
    const result = [];
    querySnapshot.forEach((doc) => {
      result.push(doc.data());
    });

    dispatch({
      type: COMPLETED_NEARBY_EXPERTS.FETCH_COMPLETED_NEARBY_EXPERTS,
      payload: result,
    });
  } catch (e) {
    console.error(e);
  }
};
