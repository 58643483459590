import React from 'react';
import { Container, Logo } from '../Components';
import GooglePlay from '../Assets/google-play.png';
import AppStore from '../Assets/app-store.png';
import Youtube from '../Assets/youtube.png';
import Facebook from '../Assets/facebook.png';
import Instagram from '../Assets/instagram.png';
import { MdOutlinePhone, MdOutlineEmail, MdLocationPin } from 'react-icons/md';
import Whatsapp from '../Assets/whatsapp.png';

const QUICK_LINKS = [
  { title: 'Services', link: 'https://pawpurrfect.co/services/' },
  { title: 'Why us?', link: 'https://pawpurrfect.co/why-us/' },
  { title: 'For Pet Parents', link: 'https://pawpurrfect.co/for-pet-parents/' },
  {
    title: 'For Service Experts',
    link: 'https://pawpurrfect.co/for-service-experts/',
  },
  { title: 'Terms & Conditions', link: 'https://pawpurrfect.co/terms-of-use/' },
  { title: 'Privacy Policy', link: 'https://pawpurrfect.co/privacy-policy/' },
];

const CONNECT_OPTIONS = [
  {
    title: '6B, 161A, Clan House, near Acres Club, Chembur 400071',
    id: '1',
    Icon: MdLocationPin,
  },
  {
    title: '+91 8169145227',
    id: '2',
    Icon: MdOutlinePhone,
  },
  {
    title: 'petparentsupport@pawpurrfect.co',
    id: '3',
    Icon: MdOutlineEmail,
  },
];

const Footer = () => {
  return (
    <div className='font-roboto'>
      <Container
        className='bg-[#f4f4f4] py-4'
        wrapperClassName='flex flex-col md:flex-row px-4 md:px-0'
      >
        <div className='flex-1'>
          <a href='https://pawpurrfect.co/' target='_blank' rel='noreferrer'>
            <Logo size='md' />
          </a>
          <p className=' mt-3 text-[16px]'>
            PawPurrfect is a first of its kind e-marketplace for best petcare
            services in India. We connect you to trusted professionals for all
            your pet’s veterinarian, training, grooming, walking, sitting and
            boarding needs. Book now and find the right help for your pets in
            the comfort of your home.
          </p>
        </div>
        <div className='flex-1'>
          <h4 className='text-dark font-medium text-[20px] mb-1 md:mb-6 mt-4 md:ml-16'>
            QUICK LINKS
          </h4>
          {QUICK_LINKS.map((item) => (
            <p
              className='my-1 md:ml-16 cursor-pointer text-[16px] mt-2'
              key={item.title}
            >
              <a href={item.link} target='_blank' rel='noreferrer'>
                {item.title}
              </a>
            </p>
          ))}
        </div>
        <div className='flex-1'>
          <h4 className='text-dark font-medium text-[20px] mb-1 md:mb-6 mt-4'>
            CONNECT
          </h4>
          {CONNECT_OPTIONS.map(({ id, Icon, title }) => (
            <div key={id} className='flex items-center mt-2'>
              <Icon size={24} className='mr-3' />
              <p className='my-1 text-[16px]'>{title}</p>
            </div>
          ))}
        
        <div className='flex mt-6'>
        <a href="https://www.facebook.com/mypawpurrfectlife/" target="_blank" rel="noopener noreferrer">
          <img src={Facebook}
            className='cursor-pointer rounded-[50%] mr-2 h-[40px]'
            alt='Facebook' />
        </a>
        <a href="https://www.instagram.com/mypawpurrfectlife/?igshid=10vnfv5pkxkr8" target="_blank" rel="noopener noreferrer">
          <img src={Instagram}
            className='rounded-[50%] mr-2 h-[40px]'
            alt='Instagram' />
        </a>
        <a href="https://www.youtube.com/channel/UCrc_Alqabt3bOweurnuFrrg" target="_blank" rel="noopener noreferrer">
          <img src={Youtube}
            className='cursor-pointer rounded-[50%] mr-2 h-[40px]'
            alt='Youtube' />
        </a>
        </div>
        </div>
      </Container>
      <Container className='bg-[#f7ba07] py-4 text-center'>
        <p className=' text-dark  mx-2 md:mx-0 text-[16px]'>
          As a marketplace, we have service providers attached to us. Hence we
          do not have any outlets. We only have a corporate office where no
          services are delivered. Please refer to Terms and Conditions for more
          details
        </p>
      </Container>
      <Container
        className='bg-[#79d1bb] py-4'
        wrapperClassName='flex flex-row justify-between relative px-2 md:px-0'
      >
        <div className='flex flex-row justify-between w-full'>
          <h6 className='max-w-sm text-dark text-base'>
            All Rights Reserved. Copyright 2021. Shodashi Sutras Private Limited
          </h6>
          <div className='flex flex-col md:flex-row'>
          <a href="https://apps.apple.com/us/app/pawpurrfect/id1592071281">
            <img src={AppStore}
            className='cursor-pointer'
             alt='app-store' />
             </a>
            <a href="https://play.google.com/store/apps/details?id=com.pawpurrfectseekers">
            <img
              src={GooglePlay}
              className='cursor-pointer md:mr-4 mb-1 md:mb-0'
              alt='google-play'/> 
            </a>    
          </div>
          <a href="https://wa.me/8169145227?text=urlencodedtext "> 
          <img
            src={Whatsapp}
            className=' cursor-pointer hidden md:flex w-12 h-12 shadow'
            alt='whatsapp'/>
          </a>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
