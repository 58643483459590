import PropTypes from "prop-types";
import React, { useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import { toast } from "react-toastify";
import { GradientButton, Modal, Textarea } from "..";
import Like from "../../Assets/Like.png";
import { updateServiceRequest } from "../../Redux/actions/serviceActions";

const ReviewModal = ({ requestId, modalVisibility, setModalVisibility }) => {
  const [status, setStatus] = useState("");
  const [rating, setRating] = useState(5);
  const [loading, setLoading] = useState(false);
  const { activeServiceId } = useSelector(({ service }) => service);

  const [review, setReview] = useState();
  const dispatch = useDispatch();
  const submitReview = (e) => {
    e.preventDefault();
    const docid = requestId || activeServiceId

    if (!rating) {
      toast.warn("Please add a rating in order to add a review!");
      return;
    }
    setLoading(true);
    let data = {
      rating_by_seeker: rating,
      review_by_seeker: review,
    };
    dispatch(
      updateServiceRequest({ data, docid }, () => {
        setLoading(false);
        toast.success("Review added successfully!");
        setModalVisibility(false);
      })
    );
  };

  const cancelReview = () => {
    setModalVisibility(false);
    setRating("");
    setReview("");
    setLoading(false);
  };

  return (
    <Modal visibilty={modalVisibility}>
      <div className="relative py-10 px-10 rounded">
        <div className="flex justify-center">
          <img src={Like} alt="sad" srcSet="" height={50} width={50} />
        </div>
        <div className="my-4">
          <h1 className="text-dark text-md font-semibold leading-relaxed text-center">
            Your review is value able for us
          </h1>
        </div>
        <div className=" my-4 flex justify-center mt-3">
          <button
              className=" col-span-2 m-2 text-xl text-yellow cursor-pointer"
            onClick={() => {
              setRating(1);
              setStatus("Awfull");
            }}
            onMouseLeave={() => {
              rating > 1 ? setRating(rating) : setRating(1);
            }}
          >
            {rating >= 1 ? (
              <AiFillStar size="3rem" />
            ) : (
              <AiOutlineStar size="3rem" />
            )}
          </button>
          <button
            className="col-span-2 m-2 text-xl text-yellow cursor-pointer"
            onClick={() => {
              setRating(2);
              setStatus("Not Satisfied");
            }}
          >
            {rating >= 2 ? (
              <AiFillStar size="3rem" />
            ) : (
              <AiOutlineStar size="3rem" />
            )}
          </button>
          <button
            className="col-span-2 m-2 text-xl text-yellow cursor-pointer"
            onClick={() => {
              setRating(3);
              setStatus("Average");
            }}
          >
            {rating >= 3 ? (
              <AiFillStar size="3rem" />
            ) : (
              <AiOutlineStar size="3rem" />
            )}
          </button>
          <button
            className="col-span-2 m-2 text-xl text-yellow cursor-pointer"
            onClick={() => {
              setRating(4);
              setStatus("Good");
            }}
          >
            {rating >= 4 ? (
              <AiFillStar size="3rem" />
            ) : (
              <AiOutlineStar size="3rem" />
            )}
          </button>
          <button
            className="col-span-2 m-2 text-xl text-yellow cursor-pointer"
            onClick={() => {
              setRating(5);
              setStatus("Very Satisfied");
            }}
          >
            {rating === 5 ? (
              <AiFillStar size="3rem" />
            ) : (
              <AiOutlineStar size="3rem" />
            )}
          </button>
        </div>
        <div className="my-4 text-center text-lg">
          <h3
            className={`col-span-2 mt-3 p-2 text-sm ${
              rating === 0 && "text-transparent"
            }`}
          >
            {rating !== 0 ? status : "Not Rated"}
          </h3>
        </div>
        <form onSubmit={submitReview} >
          <h3 className="col-span-2 mt-3 text-sm">
            Why do you feel that way? <span className='text-sm'>(Optional)</span>
          </h3>
          <Textarea
            placeholder="Write a review"
            onChange={(e) => setReview(e.target.value)}
            //required
          />
          <div className="flex justify-center mt-4 gap-3">
            <GradientButton disabled={loading} title="SUBMIT" type="submit" />
            <GradientButton title="CANCEL" grey onPress={cancelReview} />
          </div>
        </form>
      </div>
    </Modal>
  );
};

ReviewModal.defaultProps = {
  modalVisibility: false,
  setModalVisibility: () => {},
  requestId: null
};

ReviewModal.propTypes = {
  modalVisibility: PropTypes.bool,
  setModalVisibility: PropTypes.func,
  requestId: PropTypes.string,
};

export default ReviewModal;
