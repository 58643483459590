import React from "react";
import PropTypes from "prop-types";
import { Modal, GradientButton } from "../Components";

const InvalidInsuranceModel = ({ invalidInsuranceVisibility, onPressBack }) => {
  return (
    <Modal visibilty={invalidInsuranceVisibility}>
      <div className="relative py-12 lg:px-28 flex-auto rounded">
        <div className="my-4">
          <p className="text-dark text-md font-semibold leading-relaxed text-center">
            Invalid Request!
          </p>
          <p className="text-dark text-md leading-relaxed text-center">
            Currently insurance partners only offer policies for dogs
          </p>
        </div>
        <div className="flex justify-center mt-8 gap-3">
          <GradientButton onPress={onPressBack} title="BACK" />
        </div>
      </div>
    </Modal>
  );
};
InvalidInsuranceModel.propTypes = {
  invalidInsuranceVisibility: PropTypes.bool.isRequired,
  onPressBack: PropTypes.func.isRequired,
};

export default InvalidInsuranceModel;
