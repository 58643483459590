/* eslint-disable no-undef */
import { initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { collection, getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, ref, connectStorageEmulator } from "firebase/storage";
import { getAuth, connectAuthEmulator } from "firebase/auth";

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
// connectFirestoreEmulator(db, 'localhost', 8080);
// connectAuthEmulator(auth, "http://localhost:9099");
// connectStorageEmulator(storage, "localhost", 9199);
export const petProfileRef = ref(storage, "profile_image");
export const providerDetailsRef = collection(db, "provider_details");
export const serviceRequestRef = collection(db, "service_request");
export const petsRef = collection(db, "pets");
export const petInsuranceRef = collection(db, "petInsurance");
export const seekerDetailsRef = collection(db, "seeker_details");
export const notificationsRef = collection(db, "notifications");
export const providerServicesRef = collection(db, "provider_services");

export default app;
