import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DATE_OPTIONS, serviceData, statusData } from '../Constants/Constants';
import { sortByDate } from '../Redux/actions/serviceActions';
import { FILTER } from '../Redux/types';
import InputSelect from './InputSelect';
import RadioButton from './RadioButton';

const FilterOptions = () => {
  const dispatch = useDispatch();
  const {
    date,
    services: selectedServices,
    status,
  } = useSelector(({ filter }) => filter);
  return (
    <div className='grid grid-cols-1 mt-4'>
      <div>
        <h1 className='font-semibold p-4 text-md'>Status</h1>
      </div>
      <form className='px-4 grid grid-cols-1'>
        {statusData.map(({ title, value }) => (
          <RadioButton
            type='radio'
            key={title}
            name='flexRadioDefault'
            id='flexRadioDefault'
            checked={status === value}
            onPress={() =>
              dispatch({
                type: FILTER.UPDATE_STATUS,
                payload: value,
              })
            }
            title={title}
          />
        ))}
        <h1 className='font-semibold py-2 text-md'>Sort by date</h1>
        <div>
          <InputSelect
            options={DATE_OPTIONS}
            placeholder='Date'
            value={date}
            onChange={(e) => dispatch(sortByDate(e.value))}
          />
        </div>
        <h1 className='font-semibold pt-4 pb-3 text-md'>Filter by service</h1>
        <RadioButton
          type='radio'
          name='flexRadioDefault2'
          id='flexRadioDefault1'
          title='All'
          checked={selectedServices === 'All'}
          onPress={() =>
            dispatch({
              type: FILTER.UPDATE_SERVICES,
              payload: 'All',
            })
          }
        />
        {serviceData.map(({ title, value }) => (
          <RadioButton
            key={title}
            title={title}
            type='radio'
            name='flexRadioDefault2'
            id='flexRadioDefault1'
            checked={selectedServices === value}
            onPress={() =>
              dispatch({
                type: FILTER.UPDATE_SERVICES,
                payload: value,
              })
            }
          />
        ))}
      </form>
    </div>
  );
};

export default FilterOptions;
