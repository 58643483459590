import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { TiTick } from "react-icons/ti";
import { useDispatch } from "react-redux";
import star from "../../Assets/icons/star.png";
import { rejectServiceOffer } from "../../Redux/actions/serviceActions";
import LazyImage from "../LazyImage";
import { RejectPopUp } from "../index";
import { useEffect } from "react";

const RequestProfileCard = ({
  details,
  showDetails,
  stage,
  expiryDate,
  requestId,
  petDetails,
}) => {
  const [rejected, setRejected] = useState(details.status === "rejected");
  const [modalVisibility, setModalVisibility] = useState(false);
  const [sureReject, setSureReject] = useState(false);
  const dispatch = useDispatch();
  const [view, setView] = useState();
  const [loading, setLoading] = useState(false);
  const {
    provider_obj: { profile_picture, name },
    service_quote: { total },
    provider_doc_id,
    created_on,
    provider_obj: { uid },
  } = details;
  const { name: petName, breed, gender, age } = petDetails;

  useEffect(() => {
    setRejected(details.status === "rejected");
  }, [details.status]);

  useEffect(() => {
    setRejected(rejected);
  }, []);

  const handleRejectOffer = () => {
    setModalVisibility(true);
  };

  useEffect(() => {
    if (sureReject) {
      setLoading(true);
      dispatch(
        rejectServiceOffer(requestId, uid, (success) => {
          setLoading(false);
          if (success) {
            setRejected(true);
            setModalVisibility(false);
          }
        })
      );
    }
  }, [sureReject]);

  let tempTime = created_on.toDate();
  const TIME_AGO = moment(tempTime).fromNow();

  const handleDetails = () => {
    setView(provider_doc_id);
    showDetails();
  };

  return (
    <>
      <RejectPopUp
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
        reject={setSureReject}
      />
      <div
        className={`${
          rejected && "bg-profileCardDark"
        } border-1 border-solid p-1 md:p-3 rounded-md grid grid-cols-12 gap-4 ${
          stage !== 3 && view !== provider_doc_id
            ? "border-borderColour"
            : "border-darkGreen"
        }`}
      >
        <div className="col-span-3">
          <LazyImage
            src={profile_picture}
            alt="person1"
            className="w-full rounded-full aspect-square object-cover"
          />
        </div>
        <div
          className={`grid grid-cols-1 ${
            stage === 1 ? "col-span-9" : "col-span-5"
          }`}
        >
          <div className="grid grid-cols-2">
            <p className="text-lg font-semibold capitalize">{name}</p>
            <p
              className={`${
                stage !== 1 && "hidden"
              } text-lg font-semibold capitalize flex justify-end text-darkGreen font-number`}
            >
              &#x20b9; {total}
            </p>
          </div>

          <div className="text-base flex font-number">
            <p>Rating:</p>
            <img src={star} alt="star" className="w-4 h-4 ml-2 mr-1" />{" "}
            <p>4.5</p>
          </div>
          {/* <p className='text-xs font-medium single-line my-1'>
            {`${petName} | ${gender} | ${breed} | ${age} years old`}
          </p> */}
          <h5 className="text-base flex font-number">{TIME_AGO}</h5>
          <div
            className={`grid ${rejected ? "grid-cols-1" : "grid-cols-2"} ${
              stage !== 1 && "hidden"
            }`}
          >
            <div
              className={`text-base flex items-center ${
                rejected ? "justify-end" : "justify-start"
              }`}
            >
              <p className="text-base">Time Left:</p>
              <p className="text-darkGreen text-base ml-1 font-number">
                {expiryDate}
              </p>
            </div>
            <div
              className={`${
                rejected && "hidden"
              } text-base flex justify-end gap-1`}
            >
              <div
                className={`text-sm rounded-sm text-white bg-darkGreen w-full font-semibold flex justify-center items-center cursor-pointer ${
                  loading && "pointer-events-none"
                }`}
                onClick={() => handleDetails()}
              >
                View
              </div>
              <div
                className={`text-sm rounded-sm text-white bg-dark w-full font-semibold flex justify-center items-center cursor-pointer ${
                  loading && "pointer-events-none"
                }`}
                onClick={handleRejectOffer}
              >
                Reject
              </div>
            </div>
          </div>
        </div>
        {stage === 3 && (
          <div className="col-span-3 flex justify-center items-center">
            <TiTick
              fill="#f7bc48"
              className="lg:mr-[-120px] mr-[-40%] text-7xl border-8 border-solid border-yellow rounded-full"
            />
          </div>
        )}
      </div>
    </>
  );
};

RequestProfileCard.defaultProps = {
  details: {},
  stage: null,
  showDetails: () => {},
  expiryDate: "",
  petDetails: {},
};

RequestProfileCard.propTypes = {
  details: PropTypes.object,
  stage: PropTypes.number,
  showDetails: PropTypes.func,
  expiryDate: PropTypes.string,
  requestId: PropTypes.string.isRequired,
  petDetails: PropTypes.object,
};

export default RequestProfileCard;
