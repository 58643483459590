import React from "react";
import PropTypes from "prop-types";

import searchIcon from "../Assets/searchIcon.png";

const SearchInput = ({ value, onChange }) => {
  return (
    <div className="flex border-1 border-[#ABB4BD] w-[330px] h-[45px] p-2 rounded-md">
      <input
        type="text"
        placeholder="Search for Tests / Packages"
        className="w-[285px]"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <button>
        <img src={searchIcon} className="w-6" />
      </button>
    </div>
  );
};

SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default SearchInput;
