import { getAuth } from "firebase/auth";
import { addDoc, doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { toast } from "react-toastify";
import { db, petsRef, storage } from "../../firebase";
import geoHash from "../../utils/geohash";
import { ADD_PET, PETS } from "../types";

export const setPetType = (payload) => (dispatch) => {
  dispatch({ type: ADD_PET.ADD_TYPE, payload });
};

export const setPetDetails = (payload, completeOn = () => {}) => async (dispatch) => {
  const imageObject = payload.image;

  dispatch({ type: ADD_PET.ADD_DETAILS, payload });

  const imageName = `${Date.now()}-${imageObject.name}`;
  if (imageObject?.name) {
    const petProfileRef = ref(storage, `petProfile/${imageName}`);
    const uploadTask = uploadBytesResumable(petProfileRef, imageObject);
    delete payload.image;
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        console.log(error.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          dispatch({
            type: ADD_PET.SET_IMAGE_URL,
            payload: { downloadURL, name: `petProfile/${imageName}` },
          });
          completeOn(true)
        }).catch(() => completeOn(false));
      }
    );
  }
  else{
    completeOn(true)
  }
};

export const setPetLocation = (payload) => (dispatch) => {
  const { latitude, longitude, address, building } = payload;
  const geohash = geoHash.encode(latitude, longitude, "4");

  const neighbours_geohash = geoHash.getNeighbouringHash(geohash);
  const data = {
    location_address: `${address}`,
    geohash,
    building: building,
    neighbours_geohash,
    location_latitude: latitude,
    location_longitude: longitude,
  };

  dispatch({
    type: ADD_PET.ADD_LOCATION,
    payload: data,
  });
};

export const incrementStep = () => (dispatch) => {
  dispatch({ type: ADD_PET.INCREMENT_STEP });
};
export const decrementStep = () => (dispatch) => {
  dispatch({ type: ADD_PET.DECREMENT_STEP });
};

export const resetStep = () => (dispatch) => {
  dispatch({ type: ADD_PET.RESET_STEP });
};

export const clearAddPetState = () => (dispatch) => {
  dispatch({ type: ADD_PET.CLEAR_DATA });
};

export const addPet =
  (onComplete = () => {}) =>
  async (dispatch, getState) => {
    try {
      const auth = getAuth();
      const data = getState().addPet;
      const petDetails = {
        creator_id: auth.currentUser.uid,
        created_date: new Date().toLocaleString(),
        updated_date: new Date().toLocaleString(),
        pet_steps: {
          pet_type: true,
          pet_details: true,
          pet_location: true,
          profile_picture: true,
        },
        status: "submitted",
        owners: [auth.currentUser.uid],
        ...data,
      };
      delete petDetails.step;

      const docRef = await addDoc(petsRef, petDetails);

      // toast.success('Pet added successfully!');

      updateDoc(docRef, { id: docRef.id, docid: docRef.id });

      dispatch({
        type: PETS.ADD_PET,
        payload: { ...petDetails, id: docRef.id, docid: docRef.id },
      });

      onComplete(true);
    } catch (error) {
      console.log(error.message);
      onComplete(false);
    }
  };

export const editPet =
  (id, onComplete = () => {}) =>
  async (dispatch, getState) => {
    try {
      const data = getState().addPet;
      const petDetails = {
        updated_date: new Date().toLocaleString(),
        ...data,
      };

      const docRef = doc(db, "pets", id);
      delete petDetails.step;
      delete petDetails.type;
      await updateDoc(docRef, petDetails);
      // toast.success('Pet edited successfully!');
      dispatch({
        type: PETS.EDIT_PET,
        payload: { ...petDetails, docid: id },
      });
      onComplete(true);
    } catch (error) {
      console.log(error.message);
      onComplete(false);
    }
  };
