import { CREATE_REQ } from "../types";

const initialState = {
  step: 0,
  pet: "",
  service: "",
  time: "",
  services: [],
  visitType: "",
  optionalReq: "",
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_REQ.CREATE_REQ:
      return {
        ...state,
        ...payload,
      };
    case CREATE_REQ.INCREMENT_STEP:
      return {
        ...state,
        step: state.step + 1,
      };
    case CREATE_REQ.CLEAR_STEP:
      return initialState;
    default:
      return state;
  }
};
