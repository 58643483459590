export const USER = {
  SET_USER: "SET_USER",
  REMOVE_USER: "REMOVE_USER",
  SET_SEEKER_DATA: "SET_SEEKER_DATA",
  SET_USER_QUERY: "SET_USER_QUERY",
  SET_REDIRECT: "SET_REDIRECT_URL"
};

export const ADD_PET = {
  ADD_TYPE: "ADD_TYPE",
  ADD_DETAILS: "ADD_DETAILS",
  ADD_LOCATION: "ADD_LOCATION",
  INCREMENT_STEP: "INCREMENT_STEP",
  DECREMENT_STEP: "DECREMENT_STEP",
  RESET_STEP: "RESET_STEP",
  SET_IMAGE_URL: "SET_IMAGE_URL",
  CLEAR_DATA: "CLEAR_DATA",
};

export const PETS = {
  ADD_PET: "ADD_PET",
  EDIT_PET: "EDIT_PET",
  FETCH_PET: "FETCH_PET",
};

export const CREATE_REQ = {
  CREATE_REQ: "CREATE_REQ",
  UPDATE_REQUEST: "UPDATE_REQUEST",
  FETCH_SERVICES: "CREATE_REQ_FETCH_SERVICES",
  ADD_REQUEST: "CREATE_REQ_ADD_REQUEST",
  INCREMENT_STEP: "CREATE_REQ_INCREMENT_STEP",
  CLEAR_STEP: "CREATE_REQ_CLEAR_STEP",
  ACTIVE_SERVICE: "ACTIVE_SERVICE",
};

export const FILTER = {
  UPDATE_TYPE: "UPDATE_TYPE",
  UPDATE_DATE: "UPDATE_DATE",
  UPDATE_STATUS: "UPDATE_STATUS",
  UPDATE_SERVICES: "UPDATE_SERVICES",
};

export const PATHOLOGY_TOPBUTTON = {
  UPDATE_TYPE: "UPDATE_TYPE",
};

export const TRENDING_EXPERTS = {
  FETCH_TRENDING_EXPERTS: "FETCH_TRENDING_EXPERTS",
};

export const COMPLETED_NEARBY_EXPERTS = {
  FETCH_COMPLETED_NEARBY_EXPERTS: "FETCH_COMPLETED_NEARBY_EXPERTS",
};

export const MODAL = {
  SHOW_REVIEW_MODAL: "SHOW_REVIEW_MODAL",
  SHOW_DISPUTE_MODAL: "SHOW_DISPUTE_MODAL",
  TOGGLE_VISIBILITY: "TOGGLE_VISIBILITY",
};

export const NOTIFICATION = {
  FETCH_SUCCESS: "FETCH_NOTIFICATION_SUCCESS",
  FETCH_FAILED: "FETCH_NOTIFICATION_FAILED",
  MARK_AS_READ: "MARK_AS_READ",
};

export const PET_INSURANCE = {
  CREATE_INSURANCE: "CREATE_INSURANCE",
};
