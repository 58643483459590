import { getDocuments } from "../../Functions/GetDocuments";
// import { PATHOLOGY_OPTIONS, PATHOLOGY_PACKAGES } from "../../Constants/Constants";
// import { setDoc, doc } from "firebase/firestore";
// import { db } from "../../firebase";

export const addServices = (services) => async (dispatch) => {
  dispatch({ type: "ADD_SERVICES", payload: services });
};

export const removeServices = (services) => async (dispatch) => {
  dispatch({ type: "REMOVE_SERVICE", payload: services });
};

export const addMoreServices = (services) => async (dispatch) => {
  dispatch({ type: "ADD_MORE_SERVICES", payload: services });
};

export const getPathologyPackageTest = () => async (dispatch) => {
  // await setDoc(doc(db, "constant", "pathology_package"), {
  //   packages: PATHOLOGY_PACKAGES,
  // });
  // await setDoc(doc(db, "constant", "pathology_test"), {
  //   test: PATHOLOGY_OPTIONS,
  // });


  const packageList = await getDocuments("constant", "pathology_package");
  const testList = await getDocuments("constant", "pathology_test");
  dispatch({
    type: "GET_PATHOLOGY_PACKAGE_TEST",
    payload: {
      pathologyPackage: packageList,
      pathologyTest: testList,
    },
  });
};
