import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
// import ReactPaginate from 'react-paginate';
import {
  Drawer,
  ReportModal,
  ReqPetCard,
  ReviewModal,
  SideMenu,
  TopOptions,
} from "..";
import { getOfferStatus } from "../../utils/utils";
// import DashboardCarousel from "../DashboardCarousel";
import PaginatedItems from "../Pagination";
import FilterOptions from "../FilterOptions";
import NoRecordsFound from "../NoRecordsFound";

const MyRequest = ({ showSidebar, setShowSidebar }) => {
  const {
    services: selectedServices,
    type,
    status,
  } = useSelector(({ filter }) => filter);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [reportModal, setReportModal] = useState(false);

  const { services } = useSelector(({ service }) => service);

  const servicesToShow = services.filter((item) => {
    if (
      type === "Ongoing" &&
      ["Expired", "Completed", "Cancelled", "Other", "NoShow"].includes(
        getOfferStatus(item.request_open_for_offers)
      )
    ) {
      return false;
    }

    if (
      type === "History" &&
      !["Expired", "Completed", "Cancelled", "Other", "NoShow"].includes(
        getOfferStatus(item.request_open_for_offers)
      )
    ) {
      return false;
    }

    return true;
  });

  var renderedRequests = servicesToShow
    .filter((item) => {
      if (status === "All") {
        return true;
      }
      if (
        status === item.request_open_for_offers ||
        status === getOfferStatus(item.request_open_for_offers)
      ) {
        return true;
      }
      return false;
    })
    .filter((item) => {
      if (selectedServices === "All") {
        return true;
      }
      if (selectedServices === item.user_requirement?.service_type) {
        return true;
      }
      return false;
    });

  services.map((service) => {
    if (window.innerWidth > 640) service["size"] = "medium";
    else service["size"] = "small";
  });

  let otherDatas = {
    setModalVisibility,
    setReportModal,
    page: "myrequest",
  };

  return (
    <>
      <ReportModal reportModal={reportModal} setReportModal={setReportModal} />
      <ReviewModal
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
      />
      <div className="grid lg:grid-cols-12 grid-cols-1 gap-5">
        <div className="sm:col-span-12 lg:col-span-3 hidden lg:block">
          <SideMenu />
        </div>
        <div className="grid grid-cols-1 sm:col-span-12 lg:col-span-3 lg:hidden">
          <Drawer showSidebar={showSidebar} setShowSidebar={setShowSidebar}>
            <div
              className={`top-0 right-0 w-[100vw] bg-white px-7 text-black 
              fixed h-auto z-40  ease-in-out duration-300 
              ${showSidebar ? "translate-x-0 " : "translate-x-full"}`}
            >
              <FilterOptions />
            </div>
          </Drawer>
        </div>
        <div className="sm:col-span-12 lg:col-span-9">
          <div className="flex items-center justify-between">
            <TopOptions />
            <h4
              className="lg:hidden flex justify-end text-action font-semibold"
              onClick={() => setShowSidebar(!showSidebar)}
            >
              Filter by
            </h4>
          </div>
          {!renderedRequests?.length ? (
            <NoRecordsFound />
          ) : (
            <PaginatedItems
              items={renderedRequests}
              otherDatas={otherDatas}
            />

            // <DashboardCarousel
            //   items={renderedRequests}
            //   Component={ReqPetCard}
            //   className="grid lg:grid-cols-2 xs:grid-cols-1 mt-7 gap-7 mb-10"
            //   otherDatas={otherDatas}
            //   numItems={8}
            // />
          )}
        </div>
      </div>
    </>
  );
};

MyRequest.defaultProps = {
  showSidebar: false,
  setShowSidebar: () => {},
};

MyRequest.propTypes = {
  showSidebar: PropTypes.bool,
  setShowSidebar: PropTypes.func,
};

export default MyRequest;
