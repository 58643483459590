import { getAuth } from 'firebase/auth';
import { doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { db, petsRef, storage } from '../../firebase';
import { PETS } from '../types';

export const fetchUserPets = () => async (dispatch) => {
  try {
    const auth = getAuth();
    const q = query(
      petsRef,
      where('owners', 'array-contains', auth.currentUser.uid),
      where('status', '==', 'submitted')
    );
    const querySnapshot = await getDocs(q);
    const result = [];
    querySnapshot.forEach((doc) => {
      result.push(doc.data());
    });

    dispatch({ type: PETS.FETCH_PET, payload: result });
    // Check for image tag

    if (result.length > 0) {
      const petWithoutImage = result.filter((item) => !item.image);
      if (petWithoutImage?.length > 0) {
        updatePetImages(petWithoutImage)(dispatch);
      }
    }
  } catch (error) {
    console.log(error.message);
  }
};

const updatePetImages =
  (petArray = []) =>
  async (dispatch) => {
    try {
      const downloadURLArray = await Promise.all(
        petArray.map(async (item) => {
          const petPhotoRef = ref(storage, item.photo);
          const downloadUrl = await getDownloadURL(petPhotoRef);
          const docRef = doc(db, 'pets', item.docid);
          await updateDoc(docRef, { image: downloadUrl });
          dispatch({
            type: PETS.EDIT_PET,
            payload: {
              image: downloadUrl,
              docid: item.docid,
            },
          });
        })
      );
      console.log({ downloadURLArray });
    } catch (error) {
      console.log(error.message);
    }
  };
