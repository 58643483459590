import { CREATE_REQ } from '../types';

const initialState = {
  services: [],
  activeServiceId: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_REQ.ADD_REQUEST:
      return { ...state, services: [payload, ...state.services] };
    case CREATE_REQ.UPDATE_REQUEST:
      return {
        ...state,
        services: state.services.map((item) => {
          if (item.docid === payload.docid) {
            return {
              ...item,
              ...payload.data,
            };
          }
          return item;
        }),
      };

    case CREATE_REQ.FETCH_SERVICES:
      return {
        ...state,
        services: payload,
      };
    case CREATE_REQ.ACTIVE_SERVICE:
      return {
        ...state,
        activeServiceId: payload,
      };
    default:
      return state;
  }
};
