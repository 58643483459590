import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebase";

//@ Function to upload image to firebase storage
//> Param: imageObject - image file (type: Object)
//> Param: collectionName - path to upload image (type: String)

export const imageUpload = async (collectionName, imageObject) => {
  const imageName = `${imageObject.name}`;
  const petProfileRef = ref(storage, `${collectionName}/${imageName}`);
  const uploadTask = await uploadBytesResumable(petProfileRef, imageObject);

  return await getDownloadURL(uploadTask.ref);
};
