import { getAuth, updateEmail, updateProfile } from 'firebase/auth';
import React, {useEffect, useState} from 'react';
import { GoMail } from 'react-icons/go';
import { MdOutlineAccountCircle, MdPhoneInTalk } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MobileDog from '../Assets/mobile-dog.png';
import { Container, GradientButton, Input } from '../Components';
import { setUser, updateUser } from '../Redux/actions/authActions';
// import { auth } from '../firebase';

const AddDetailsSCreen = () => {
  const dispatch = useDispatch();
  const {user, seeker_object, user_query} = useSelector(({ auth }) => auth);
  const [formData, setFormData] = useState({ name: '', email: '' });
  const [loading, setLoading] = useState(false);
  const { name, email } = formData;
  const navigate = useNavigate();

  useEffect(
    ()=> {
      setFormData({
        ...formData,
        name: seeker_object?.name || user_query?.name,
        email: seeker_object?.email || user_query?.email
      })
    },
    [seeker_object, user_query]
  )

  // const handleLogout = () => {
  //   auth.signOut();
  //   dispatch(removeUser());
  //   navigate('/login');
  // };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    let auth1 = getAuth();

    try {
      await updateProfile(auth1.currentUser, { displayName: name });
      let auth2 = getAuth();
      await updateEmail(auth2.currentUser, email);
      await updateUser({
        name,
        email,
      });
      dispatch(setUser({ ...user, displayName: name, email }));
      navigate("/onboarding");
    } catch (error) {
      toast.error(error.message);
      // handleLogout();
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className='py-2'>
      <div className='grid md:grid-cols-2 gap-4'>
        <div className='flex flex-col pt-12 md:pl-36'>
          <h4 className='text-lg font-semibold'>Tell us about you</h4>
          {/* <p className='text-base text-light'>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p> */}

          <form onSubmit={handleProfileUpdate} className="mt-5">
            <Input
              required
              type="text"
              value={name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              placeholder="Name"
              Icon={MdOutlineAccountCircle}
            />
            <Input
              required
              value={email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              type="email"
              placeholder="Email"
              Icon={GoMail}
            />
            <Input
              required
              disabled
              placeholder={user?.phoneNumber}
              Icon={MdPhoneInTalk}
            />
            <GradientButton
              disabled={loading}
              type="submit"
              className="mt-12"
              title="CONFIRM"
            />
          </form>
        </div>
        <div className="hidden md:flex justify-center pt-12">
          <img src={MobileDog} alt="dog" className="section-dog" />
        </div>
      </div>
    </Container>
  );
};

export default AddDetailsSCreen;
