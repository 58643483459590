import React from "react";
import { BsEye } from "react-icons/bs";
import ReactInfiniteScroller from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, GradientButton, NotificationCard } from "../Components";
import {
  fetchMoreNotification,
  markNotificationAsSeen,
} from "../Redux/actions/notificationsActions";

const NotificationsPage = () => {
  const navigate = useNavigate();
  const {
    notification: { items, hasMore },
  } = useSelector(({ notification }) => ({
    notification,
  }));

  const dispatch = useDispatch();

  const markAllRead = async () => {
    try {
      const promises = items
        .filter((item) => !item.seen)
        .map((item) => dispatch(markNotificationAsSeen(item)));
      await Promise.all(promises);
    } catch (error) {
      console.log(error.message);
    }
  };

  const loadMore = () => {
    dispatch(fetchMoreNotification());
  };

  return (
    <Container className="py-8" wrapperClassName="">
      <div className="flex items-center justify-between pb-6">
        <h4 className=" text-lg">Notifications</h4>
        <GradientButton
          onPress={() => navigate("/create-request")}
          title="CREATE A REQUEST"
        />
      </div>
      <div className=" flex flex-row">
        <div
          onClick={markAllRead}
          className=" ml-auto px-2 rounded-md py-2 border-1 border-t-gray-50 flex flex-row items-center cursor-pointer"
        >
          <BsEye className="mr-2" />
          <p className="text-base">Mark All read</p>
        </div>
      </div>
      {items?.length > 0 && (
        <ReactInfiniteScroller
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <div className="loader" key={0}>
              Loading ...
            </div>
          }
        >
          {items.map((item) => (
            <NotificationCard key={item.docid} item={item} />
          ))}
        </ReactInfiniteScroller>
      )}
    </Container>
  );
};

export default NotificationsPage;
