import React, {useEffect, useState} from 'react';
import OtpInput from 'react18-input-otp';
import GradientButton from './GradientButton';
import MobileNumberInput from './MobileNumberInput';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { toast } from 'react-toastify';
import { auth } from '../firebase';
import { AiTwotoneEdit } from 'react-icons/ai';
import {useSelector} from "react-redux";

// eslint-disable-next-line
const LoginForm = () => {
  const user_query = useSelector(({ auth }) => auth.user_query);

  const [formData, setFormData] = useState({
    number: '',
    otp: '',
    step: 1,
    countryCode: '+91',
  });
  const [loading, setLoading] = useState(false);
  const { number, otp, step, countryCode } = formData;

  useEffect(
    ()=> {
      setFormData({
        ...formData,
        number: user_query?.phone,
      })
    },
    [user_query]
  )

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: () => {
          //console.log('recaptcha solved');
        },
        'expired-callback': () => {},
      },
      auth
    );
  };

  const continueWithMobileNumber = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      await generateRecaptcha();

      const phoneNumber = `${countryCode}${number}`;
      let appVerifier = window.recaptchaVerifier;

      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setFormData({
            ...formData,
            step: 2,
          });
        })
        .catch((err) => console.log(err.message))
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log({ error: error.message });
      setLoading(false);
    }
  };

  const verifyOTP = () => {
    let confirmationResult = window.confirmationResult;
    setLoading(true);
    confirmationResult
      .confirm(otp)
      .then((result) => {
        const user = result.user;
      })
      .catch((error) => {
        console.log({ error: error.message });
        toast.error('Invalid verification code');
        setFormData({
          ...formData,
          otp: '',
        });

      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resendOtp = () => {
    const phoneNumber = `${countryCode}${number}`;
    setFormData({
      ...formData,
      otp: '',
    });

    let appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        toast.info('OTP sent')
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      {step === 1 ? (
        <>
          <h4 className='text-lg uppercase font-semibold flex justify-center md:justify-start'>
            Login/Signup
          </h4>
          <h5 className='text-md mt-8 mb-1 flex justify-center md:justify-start'>
            Welcome to Pawpurrfect
          </h5>
          <p className='text-base text-light flex justify-center md:justify-start'>
            Login to your account to see your manage
          </p>
          <form onSubmit={continueWithMobileNumber}>
            <MobileNumberInput
              value={number}
              onChange={(e) => {
                if (e.target.value.length > 10) {
                  return;
                }
                setFormData({ ...formData, number: e.target.value });
              }}
            />
            <p className='text-xs flex justify-center md:justify-start'>
              An OTP will send to your mobile number for verification
            </p>
            <div className='flex justify-center md:justify-start'>
              <GradientButton
                type='submit'
                className='mt-12 mb-20 md:mb-0'
                title='CONTINUE'
                disabled={loading || number?.length !== 10}
              />
            </div>
          </form>
        </>
      ) : (
        <>
          <h4 className='text-lg uppercase font-semibold flex justify-center md:justify-start'>
            Login/Signup
          </h4>
          <h5 className='text-md mt-8 mb-1 flex justify-center md:justify-start'>
            Hi There!
          </h5>
          <p className='text-base text-light md:text-left text-center font-number'>
            Please enter the 6 digit verification code we have sent you on +91
            {number}
            <AiTwotoneEdit
              onClick={() => {
                setFormData({ ...formData, step: 1 });
              }}
              className='inline ml-2 cursor-pointer'
              fill='#1EAF9C'
            />
          </p>
          <OtpInput
            value={otp}
            shouldAutoFocus
            onChange={(otp) => setFormData({ ...formData, otp })}
            numInputs={6}
            placeholder='------'
            inputStyle=' font-medium font-number border-1 text-lg text-base rounded-md mx-4 otp-input first:ml-0'
            containerStyle='my-4'
          />
          <p className='text-base text-light flex justify-center md:justify-start'>
            Didn’t receive the OTP?{' '}
            <span
              onClick={resendOtp}
              className='ml-1 uppercase text-action cursor-pointer font-medium'
            >
              RESEND OTP
            </span>
          </p>
          <div className='flex justify-center md:justify-start'>
            <GradientButton
              disabled={loading || otp?.length !== 6}
              type='submit'
              onPress={verifyOTP}
              className='mb-24 md:mb-0 mt-12'
              title='VERIFY'
            />
          </div>
        </>
      )}
      <div id='recaptcha-container' className='mt-4'></div>
    </div>
  );
};

export default LoginForm;
