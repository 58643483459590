import React from 'react';
import FilterOptions from '../FilterOptions';

const SideMenu = () => {
  return (
    <>
      <div className='bg-darkGreen h-14 flex items-center rounded-t-lg'>
        <h1 className='text-white font-semibold ml-4'>Filter by</h1>
      </div>
      <div className='grid grid-cols-1 border-1 border-borderColour rounded-b-lg'>
        <FilterOptions />
      </div>
    </>
  );
};

export default SideMenu;
