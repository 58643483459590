import 'animate.css'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import WOW from 'wowjs'
import Boarding from '../Assets/icons/boarding.png'
import Grooming from '../Assets/icons/grooming.png'
import Paw from '../Assets/icons/Paw.png'
import Sitting from '../Assets/icons/Sitting.png'
import Training from '../Assets/icons/training.png'
import User from '../Assets/icons/user.png'
import Veterinarian from '../Assets/icons/vaterinarian.png'
import Walking from '../Assets/icons/walking.png'
import pathology from '../Assets/icons/pathology.png'
import DogInsurance from '../Assets/icons/DogInsurance.png'

import ADVT from '../Assets/advt.png'

import {
  Container,
  GradientButton,
  NotificationCard,
  OTPModal,
  PetCard,
  ReportModal,
  ReqPetCard,
  ReviewModal,
  ServiceCard,
  TextButton,
} from '../Components'
import DashboardCarousel from '../Components/DashboardCarousel'
import PetExpertCard from '../Components/PetExpertCard'
import { MODAL } from '../Redux/types'

const options = [
  { img: Training, title: 'training', value: 'pet_trainers', advt: false },
  { img: Grooming, title: 'grooming', value: 'pet_grooming', advt: false },
  { img: Boarding, title: 'boarding', value: 'pet_boarders', advt: false },
  { img: Walking, title: 'Walking', value: 'dog_walking', advt: false },
  { img: Sitting, title: 'sitting', value: 'pet_sitters', advt: false },
  { img: Veterinarian, title: 'veterinarian', value: 'vet', advt: false },
  {
    img: DogInsurance,
    title: 'Pet Insurance',
    value: 'pet_insurance',
    advt: true,
  },
  { img: pathology, title: 'Pathology', value: 'pathology', advt: false },
]

const DashboardPage = () => {
  const navigate = useNavigate()
  const {
    pet: { loading, pets },
    service: { services },
    notification: { items },
  } = useSelector(({ pet, service, notification }) => ({
    pet,
    service,
    notification,
  }))
  const { requestId, type, visibility } = useSelector(
    ({ modalReducer }) => modalReducer,
  )
  const dispatch = useDispatch()
  const [modalVisibility, setModalVisibility] = useState(false)
  const [reportModal, setReportModal] = useState(false)

  const { trendingExperts } = useSelector(
    ({ trendingExpert }) => trendingExpert,
  )
  const { completedExperts } = useSelector(
    ({ completedExperts }) => completedExperts,
  )

  const profileOptions = [
    {
      image: Paw,
      name: `${pets?.length}`,
      breed: 'Pet Profile Created',
      link: '/my-pet-profile',
    },
    {
      image: User,
      name: `${services?.length}`,
      breed: 'Total Services Booked',
      link: '/my-requests',
    },
  ]

  useEffect(() => {
    new WOW.WOW().init()
    getAllCookies()
  }, [])

  function getAllCookies() {
    let db
    const request = indexedDB.open('firebaseLocalStorageDb')
    request.onerror = (event) => {
      console.error("Why didn't you allow my web app to use IndexedDB?!")
    }
    request.onsuccess = (event) => {
      console.log('success')
      db = event.target.result
    }
  
  }

  if (!loading && pets.length === 0) {
    return <Navigate to="/onboarding" />
  }

  services.map((service) => {
    service['size'] = 'small'
  })

  let otherDatas = {
    setModalVisibility,
    setReportModal,
    page: 'dashboard',
  }

  return (
    <>
      {/* TODO: remove OTPModal, uncomment ReviewModal */}
      {/* <ReviewModal
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
      /> */}
      <ReportModal reportModal={reportModal} setReportModal={setReportModal} />
      <OTPModal
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
      />
      <Container className="py-8" wrapperClassName="">
        <div className="flex items-center justify-between pb-6 wow animate__animated animate__fadeInUp">
          <h4 className=" text-lg">Dashboard</h4>
          <GradientButton
            onPress={() => navigate('/create-request')}
            title="CREATE A REQUEST"
          />
        </div>
        <p className="text-bold mt-2 mb-5 font-semibold wow animate__animated animate__fadeInUp">
          Services
        </p>
        <div className="grid grid-cols-3 md:grid-cols-8 gap-2 md:gap-4 wow animate__animated animate__fadeInUp">
          {options.map(({ title, img, value, advt }) => (
            <>
              <Link
                to="/create-request"
                state={{ type: value }}
                key={title}
                className="bg-action relative flex flex-col items-center rounded-md py-3 cursor-pointer hover:shadow-md transition-all"
              >
                {advt && (
                  <div className="absolute   -top-2 -right-4 text-white px-3 py-1 text-xs lg:w-[90%] h-auto md:w-[120%]  sm:w-[50%]">
                    {/* <p className="text-white font-semibold text-base">
                ADVT
              </p> */}
                    <img
                      src={ADVT}
                      alt="pet-image"
                      className=" w-[100%] h-[100%] object-contain"
                    />
                  </div>
                )}
                <img src={img} alt="pet-image" className=" w-20 mb-2 h-auto" />
                <p className=" capitalize text-white font-semibold text-base">
                  {title}
                </p>
              </Link>
            </>
          ))}
        </div>

        {/* <hr className="mt-12" style={{
          color: '#000000',
          backgroundColor: '#000000',
          height: .5,
          borderColor: '#000000'
        }} /> */}

        <div className="grid grid-cols-1 md:grid-cols-5 gap-6">
          {/* Services */}
          <div className="md:col-span-3">
            {services.length > 0 && (
              <>
                <div className="flex justify-between mt-14 mb-2 wow animate__animated animate__fadeInUp">
                  <p className="text-bold font-semibold">Recent Requests</p>
                  <TextButton
                    onClick={() => navigate('/my-requests')}
                    title="View more"
                  />
                </div>
                {/* <div className='grid lg:grid-cols-2 gap-4 wow animate__animated animate__fadeInUp'>
                {.map((item) => (
                  < key={item.docid} details={item} />
                ))}
              </div> */}
                <DashboardCarousel
                  items={services?.slice(0, 4)}
                  Component={ReqPetCard}
                  otherDatas={otherDatas}
                />
              </>
            )}
            <div className="flex justify-between mt-6 mb-2 wow animate__animated animate__fadeInUp">
              <p className="text-bold font-semibold">
                Pet Services Completed around You
              </p>
            </div>
            {[].length !== 0 ? (
              <DashboardCarousel
                items={completedExperts}
                Component={PetExpertCard}
                smallImage
              />
            ) : (
              <h1 className="text-base">
                Currently there are not any pet services completed in your area.
              </h1>
            )}

            {/* Experts */}
            <div className="flex justify-between mt-6 mb-3">
              <p className="text-bold font-semibold wow animate__animated animate__fadeInUp">
                Trending Pet Experts
              </p>
            </div>
            <DashboardCarousel
              items={trendingExperts.slice(0, 4)}
              Component={ServiceCard}
              smallImage
            />
          </div>

          <div className="col-span-1 md:col-span-2">
            {/* Profile */}
            <div className="flex justify-between mt-14 mb-2 wow animate__animated animate__fadeInUp">
              <p className="text-bold font-semibold">My Pet Profiles</p>
              <TextButton
                onClick={() => navigate('/my-pet-profile')}
                title="View more"
              />
            </div>
            <div className="grid grid-cols-2 gap-3">
              {pets.slice(0, 2).map((item) => (
                <PetCard key={item.docid} item={item} animate={true} />
              ))}
            </div>
            <div className="grid grid-cols-2 gap-3 mt-3">
              {profileOptions.map((item) => (
                <PetCard
                  pet
                  key={item.breed}
                  item={item}
                  animate
                  link={item.link}
                />
              ))}
            </div>
            {/* Notifications */}
            <div className="flex justify-between mt-6 mb-2">
              <p className="text-bold font-semibold wow animate__animated animate__fadeInUp">
                Notifications
              </p>
              <TextButton
                onClick={() => navigate('/notifications')}
                title="View more"
                className="wow animate__animated animate__fadeInUp"
              />
            </div>
            {items
              .filter((item) => !item.seen)
              ?.slice(0, 5)
              .map((item) => (
                <NotificationCard
                  key={item.docid}
                  item={item}
                  small
                  animate={true}
                />
              ))}
          </div>
        </div>
      </Container>
      {type === 'review' && (
        <ReviewModal
          modalVisibility={visibility}
          setModalVisibility={(payload) => {
            dispatch({ type: MODAL.TOGGLE_VISIBILITY, payload })
          }}
          requestId={requestId}
        />
      )}
    </>
  )
}

export default DashboardPage
