import React from 'react';
import PropTypes from 'prop-types';
import LogoImg from '../Assets/logo.png';

const Logo = ({ className, size }) => {
  return (
    <div className={`${className}`}>
      <img src={LogoImg} alt='logo' className={`logo-${size}`} />
    </div>
  );
};

Logo.defaultProps = {
  size: 'sm',
};

Logo.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg', 'md']),
};

export default Logo;
