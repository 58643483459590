import { doc, getDoc } from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  GradientButton,
  PayWithRazorPay,
  RequestProfileCard,
} from '..';
import { SERVICES_TEXT, REQ_PROFILE_CARD_OPTIONS } from '../../Constants/Constants'
import { db } from '../../firebase';
import { updateServiceRequest } from '../../Redux/actions/serviceActions';
import JobDetails from './JobDetails';

const ConfirmBooking = ({ bookingDetails, requestId, id }) => {
  const [stage, setStage] = useState(2);
  const [providerDetails, setProviderDetails] = useState({});
  const [activeServices, setActiveServices] = useState([]);

  // console.log(requestId);

  const { provider_offer } = bookingDetails;
  const payableAmount = (provider_offer.service_quote?.total * (1 / 10))  + (((provider_offer.service_quote?.total / 100) * 2) / 100) * 10  ;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchProviderDetails = async () => {
    const providerDetailsRef = doc(db, 'provider_details', `${id}`);
    const docSnap = await getDoc(providerDetailsRef);

    if (docSnap.exists()) {
      setProviderDetails(docSnap.data());
    }
  };

  useEffect(() => {
    fetchProviderDetails();
  }, []);

  // console.log(providerDetails);

  const handleBookingComplete = (payment_id) => {
    // console.log("hello");

    // TODO  save data to transaction as well
    const transaction_doc_id = 'wdvO2dxF0JqYnI30jIrZ';
    const provider_doc_id = provider_offer?.provider_obj.uid
    const data = {
      razorpay_amount_paid: payableAmount,
      transaction_id: payment_id,
      transaction_obj: `transactions/${transaction_doc_id}`,
      booked_expert: provider_offer?.provider_obj,
      offered_provider_id: provider_doc_id,
      accepted_amount: provider_offer.service_quote?.total,
      final_amount: provider_offer.service_quote?.total - payableAmount,
      request_open_for_offers: 'offer_accepted',
      offer_acceptance_status: 'accepted',
      offered_provider_obj: provider_offer?.provider_obj,
      coupon_data: {},
      coupon_doc_id: '',
      discount_amount: 0,
      offer_acceptance_date: new Date(),
    };
    dispatch(updateServiceRequest({ data, docid: requestId }));
    setStage(3);
  };

  useEffect(() => {
    let a = [];
    providerDetails.active_services?.map((item) => {
      a.push(SERVICES_TEXT[item.service_slug]);
    });
    setActiveServices(a);
    // console.log(activeServices);
  }, [providerDetails]);

  const accordionData = [
    {
      title: 'About Me',
      // content: providerDetails.about_me,
      content: REQ_PROFILE_CARD_OPTIONS[0].aboutMe,
    },
    {
      title: 'Experience',
      // content: providerDetails.experiences || '',
      content: REQ_PROFILE_CARD_OPTIONS[0].experiences,
    },
    {
      title: 'Other services',
      content: activeServices,
    },
    {
      title: 'Awards',
      content: REQ_PROFILE_CARD_OPTIONS[0].awards,
    },
    {
      title: 'Certificates',
      // content: null,
      content: REQ_PROFILE_CARD_OPTIONS[0].certificates,
    },
  ];

  return (
    <>
      <h1 className='text-lg font-semibold mb-7'>
        {stage === 2 ? 'Confirm Booking' : 'Confirmation successful'}
      </h1>
      <div className='grid sm:grid-cols-1 lg:grid-cols-2 gap-7'>
        <div>
          <RequestProfileCard details={provider_offer} stage={stage} />
          
          {/* <hr className='lg:mb-7 lg:mt-14' />
          <Accordion data={accordionData} /> */}
        </div>
        <div>
          <JobDetails stage={stage} offer={provider_offer.service_quote} />
          {stage === 2 ? (
            <PayWithRazorPay
              amount={payableAmount}
              onComplete={handleBookingComplete}
            />
          ) : (
            <div className='hidden mt-7 items-center justify-end'>
              <h1 className='font-semibold text-xl mr-2 cursor-pointer'>
                Download Invoice
              </h1>
              <div className='border-1 border-solid border-darkGreen bg-darkGreen rounded-full p-2 cursor-pointer'>
                <AiOutlineDownload fill='white' className='w-7 h-7' />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='flex justify-center my-7'>
        {stage === 3 && (
          <GradientButton
            title='VIEW BOOKINGS'
            onPress={() => {
              navigate(`/req-details/${requestId}/${0}`);
            }}
          />
        )}
      </div>
    </>
  );
};

ConfirmBooking.propTypes = {
  bookingDetails: PropTypes.object.isRequired,
  requestId: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export default ConfirmBooking;
