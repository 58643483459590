import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { ReqPetCard } from ".";
import DashboardCarousel from "./DashboardCarousel";

function PaginatedItems({items, otherDatas}) {
  // const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  const itemsPerPage = 8;
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(items);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {
        items.length > 8
          ?
          <>
            <div className={'grid lg:grid-cols-2 xs:grid-cols-1 mt-7 gap-7 mb-10'}>
              {currentItems.map((details, j) => (
                <ReqPetCard key={j} data={details} otherDatas={otherDatas} />
              ))}
            </div>
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< pre"
              renderOnZeroPageCount={null}
              containerClassName={'flex justify-center'}
              pageLinkClassName={'mx-2'}
              activeClassName={'bg-[#f7bc48] font-semibold pt-[2px] rounded-[100%] w-7 text-white'}
              nextClassName={'text-white rounded-lg bg-[#1eaf9c] mx-2 p-1'}
              previousClassName={'text-white rounded-lg bg-[#1eaf9c] mx-2 p-1'}
            />
          </>
          :
          <div className={'grid lg:grid-cols-2 xs:grid-cols-1 mt-7 gap-7 mb-10'}>
            {currentItems.map((details, j) => (
              <ReqPetCard key={j} data={details} otherDatas={otherDatas} />
            ))}
          </div>
      }


      {/* <Items currentItems={currentItems} /> */}

    </>
  );
}

PaginatedItems.defaultProps = {
  items: [],
  otherDatas: {},
};

PaginatedItems.propTypes = {
  items: PropTypes.array,
  otherDatas: PropTypes.object,
};

export default PaginatedItems;
