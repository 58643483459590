const initialState = {
  name: "hello",
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "PET_INSURANCE":
      return {
        ...payload,
      };
    case "COMPLETE_PET_INSURANCE":
      return {
        ...state,
        paymentDetails: payload,
      };
    default:
      return state;
  }
};
