import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PATHOLOGY_TOPBUTTON } from '../../Redux/types';

const TopOptionsPathology = () => {
  const { type } = useSelector(({ filter }) => filter);
  const dispatch = useDispatch();
  return (
    <div className='flex overflow-hidden w-72'>
      {['All', 'Tests', 'Packages'].map((item) => (
        <div className=' w-36' key={item}>
          <div
            className={`h-14 font-semibold cursor-pointer ${
              type === item
                ? 'text-darkGreen border-b-[3px] border-primary'
                : 'text-veryLightDark'
            } ${
              (type === 'Ongoing' && item === 'Tests') && 'text-[#3eb4a2] border-b-[3px] border-primary'
            }  flex justify-center items-center `}
            onClick={() =>
              dispatch({ type: PATHOLOGY_TOPBUTTON.UPDATE_TYPE, payload: item })
            }
          >
            {item}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TopOptionsPathology;
