import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlinePicture } from 'react-icons/ai';

const FileInput = ({ file, onChange, placeholder, disable }) => {
  return (
    <div className='border-2 border-solid rounded-md cursor-pointer items-center h-[193px] relative overflow-hidden w-[224px] p-4'>
     
      <p className=' text-md text-[16px] font-roboto text-center'>
        {placeholder}
      </p>
      <input
        type='file'
        onChange={onChange}
        style={{ opacity: 0, zIndex: 10 }}
        accept='.jpg,.jpeg,.png,'
        className='w-full h-full absolute top'
        disabled={disable}
      />
      {file && (
        <img
          src={file?.name ? URL.createObjectURL(file) : file}
          alt='upload_file'
          className='w-auto ml-14 mt-3 h-[40%] absolute top object-cover'
        />
      )}
    </div>
  );
};

FileInput.defaultProps = {
  file: '',
  onChange: () => {},
  placeholder: '',
  disable: false,
};
FileInput.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disable: PropTypes.bool,
};

export default FileInput;
