import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Container from '../Components/Container';
import EditSectionOne from '../Components/PetPageComponents/EditSectionOne';
import EditSectionTwo from '../Components/PetPageComponents/EditSectionTwo';
import ReviewPetProfile from '../Components/PetPageComponents/ReviewPetProfile';
import TopButtons from '../Components/PetPageComponents/TopButtons';
import { ADD_PET } from '../Redux/types';

const EditPetPage = () => {
  const { step } = useSelector(({ addPet }) => addPet);
  const { pets } = useSelector(({ pet }) => pet);
  const dispatch = useDispatch();
  let { petId } = useParams();

  let reqPet = pets.find((pet) => {
    return pet.docid === petId;
  });

  useEffect(() => {
    dispatch({ type: ADD_PET.CLEAR_DATA });
  }, []);

  return (
    <div className='py-4'>
      <Container>
        {step !== 2 && <TopButtons sec={step} type='edit' />}
        <div className='mt-7 flex'>
          {step + 1 === 1 ? (
            <EditSectionOne details={reqPet} />
          ) : step + 1 === 2 ? (
            <EditSectionTwo details={reqPet} />
          ) : (
            <ReviewPetProfile editId={petId} />
          )}
        </div>
      </Container>
    </div>
  );
};

export default EditPetPage;
