import React, {useEffect, useState} from "react";
import { Container, LoginForm } from "../Components";
import MobileDog from "../Assets/mobile-dog.png";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, useLocation} from "react-router-dom";
import {setUserQuery, setRedirect} from '../Redux/actions/authActions';

const LoginPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);

  useEffect(
    () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const searchParams = Object.fromEntries(urlSearchParams.entries());
      dispatch(setRedirect(searchParams['redirect_to'] || '/'))

      dispatch(setUserQuery({
        name: searchParams['name'],
        email: searchParams['email'],
        phone: searchParams['phone']
      }));
    },
    []
  )


  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <Container className="md:py-2">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex md:hidden justify-center pt-7">
          <img src={MobileDog} alt="dog" width={250} />
        </div>
        <div className="flex flex-col md:pt-12 px-4 md:pl-36">
          <LoginForm />
        </div>
        <div className="hidden md:flex justify-center pt-12">
          <img src={MobileDog} alt="dog" className="section-dog" />
        </div>
      </div>
    </Container>
  );
};


export default LoginPage;
