import React from 'react';
import PropTypes from 'prop-types';

const Container = ({ children, className, wrapperClassName }) => {
  return (
    <div className={className}>
      <div
        className={`container ml-auto mr-auto ${wrapperClassName} px-3 md:mx-auto`}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;

Container.propTypes = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  children: PropTypes.any,
};
