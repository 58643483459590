import React from "react";
import { Container, GradientButton } from "../Components";
import Dog from "../Assets/icons/dog.png";
import { useNavigate } from "react-router-dom";

const OnboardingPage = () => {
  const navigate = useNavigate();
  return (
    <Container wrapperClassName="py-16 pb-20 flex flex-col items-center">
      <img src={Dog} alt="dog" className=" h-16" />
      <h3 className="text-lg font-medium mt-3">Congratulation!</h3>
      <h3 className=" text-center text-lg font-medium mb-3">
        You have been registered as a Pet Parent
      </h3>
      <h5 className="text-sm text-center">
        You are now a registered Pet Parent on PAWPURRFECT.
      </h5>
      <h5 className="text-sm mb-8">Let’s go ahead and add your pet.</h5>
      <GradientButton
        size="md"
        onPress={() => navigate("/add-pet")}
        title="ADD MY PET"
      />
    </Container>
  );
};

export default OnboardingPage;
