import PropTypes from 'prop-types';
import React from 'react';
import { GradientButton, Modal } from '..';
import sadFace from '../../Assets/sadFace.png';

const RejectPopUp = ({ modalVisibility, setModalVisibility, reject }) => {
  const handleReject = () => {
    reject(true);
  };

  return (
    <Modal visibilty={modalVisibility}>
      <div className='relative py-20 px-10 rounded'>
        <div className='flex justify-center'>
          <img src={sadFace} alt='sad' srcSet='' />
        </div>
        <div className='my-4'>
          <p className='text-dark text-md font-semibold leading-relaxed text-center'>
            If you reject the request, you will not be able to come back to this
            response. Let us get back to you on more responses.
          </p>
        </div>
        <div className='my-4 text-center'>
          <h5 className='bg-lightYellow col-span-2 mt-3 p-2 rounded text-sm'>
            *Please note: You will get experts availability and rates within the
            next 30 minutes.
          </h5>
        </div>
        <div className='flex justify-center mt-4 gap-3'>
          <GradientButton grey title='REJECT' onPress={handleReject} />
          <GradientButton
            title='CANCEL'
            onPress={() => setModalVisibility(false)}
          />
        </div>
      </div>
    </Modal>
  );
};

RejectPopUp.defaultProps = {
  modalVisibility: false,
  setModalVisibility: () => {},
  reject: () => {},
};

RejectPopUp.propTypes = {
  modalVisibility: PropTypes.bool,
  setModalVisibility: PropTypes.func,
  reject: PropTypes.func,
};

export default RejectPopUp;
