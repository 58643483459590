import React, {useState} from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';

import Input from '../Input';
import moment from 'moment';
import InputSelect from '../InputSelect';
import { DATE_TYPE_OPTIONS } from '../../Constants/Constants';

const DateOptions = ({
  setStartDate,
  setEndDate,
  startDate,
  endDate, multipleDays,
  setMultipleDate,
}) => {
  return (
    <div className='mb-7 mt-4'>
      <InputSelect
        options={DATE_TYPE_OPTIONS}
        placeholder='select service dates'
        onChange={(e) => setMultipleDate(e.value)}
        value={multipleDays}
      />

      <div className='grid grid-cols-2 mt-5 mb-3 gap-5'>
        <div>
          <h6 style={{ marginBottom: '-8px' }}>Select Start Date </h6>
          <Input
            required
            type='date'
            placeholder='Start Date'
            Icon={FaCalendarAlt}
            onChange={(e) => {
              const date = e.target.value;
              setStartDate(new Date(date));
            }}
            value={moment(startDate).format('YYYY-MM-DD')}
            min={moment(new Date()).format('YYYY-MM-DD')}
            max={moment(new Date()).add(89, 'days').format('YYYY-MM-DD')}
          />
        </div>
        <div>
          
          <h6 style={{ marginBottom: '-8px' }}>Select End Date</h6>
          <Input
            required
            type='date'
            placeholder='Start Date'
            disabled={!startDate}
            value={moment(endDate).format('YYYY-MM-DD')}
            min={moment(new Date(startDate)).format('YYYY-MM-DD')}
            max={moment(new Date()).add(89, 'days').format('YYYY-MM-DD')}
            Icon={FaCalendarAlt}
            onChange={(e) => {
              const date = e.target.value;
              setEndDate(new Date(date));
            }}
            defaultValue={''}
          />
        </div>
      </div>
    </div>
  );
};

DateOptions.propTypes = {
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  multipleDays: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setMultipleDate: PropTypes.func,
};

export default DateOptions;
