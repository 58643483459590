import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ChipCard, InputSelect } from '..';
import bath from '../../Assets/icons/DogBath.png';
import {BATHING_OPTIONS, GROOMING_OPTIONS, HAIR_STYLE_OPTIONS} from '../../Constants/Constants';

const GroomingOptions = ({ setData }) => {
  const [servicesList, setServicesList] = useState([]);
  const [formData, setFormData] = useState({bath: '', hair: ''})

  useEffect(() => {
    setData([...servicesList, ...(formData.hair && [formData.hair]), ...(formData.bath && [formData.bath])]);
  }, [servicesList]);

  return (
    <div className='mt-1'>
      <h6 className='mb-3'>Select services</h6>
      <div className='grid lg:grid-cols-2 sm:grid-cols-1 gap-3 md:gap-7 text-[19px]'>
        <InputSelect
          options={BATHING_OPTIONS}
          placeholder={'Select Bathing'}
          image={bath}
          value={formData.bath}
          onChange={(e) => setFormData({...formData, bath: e.value})}
        />
        <InputSelect
          options={HAIR_STYLE_OPTIONS}
          placeholder={'Select Hair Styling'}
          image={bath}
          value={formData.hair}
          onChange={(e) => setFormData({...formData, hair: e.value})}
        />
        {GROOMING_OPTIONS.map(({ image, title, value }) => (
          <ChipCard
            Icon={image}
            ticked={true}
            key={value}
            title={title}
            isSelected={servicesList.includes(value)}
            fill={false}
            onPress={() =>
              setServicesList((prev) =>
                prev.includes(value)
                  ? prev.filter((item) => item !== value)
                  : [...prev, value]
              )
            }
          />
        ))}
      </div>
    </div>
  );
};

GroomingOptions.defaultProps = {
  setData: () => {},
};

GroomingOptions.propTypes = {
  setData: PropTypes.func,
};

export default GroomingOptions;
