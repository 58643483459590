import 'animate.css';
import React from 'react';
import PropTypes from 'prop-types';
import LazyImage from './LazyImage';
import {SERVICES_TEXT} from "../Constants/Constants";

const PetExpertCard = ({data}) => {
  return (
    <div className=' border-1 rounded-lg px-1 py-2 hover:shadow-sm wow animate__animated animate__fadeInUp animate__delay-0.4s'>
      <div className='flex'>
        <LazyImage
          src={data?.profile_picture}
          alt='expert'
          className='w-16 aspect-square object-cover rounded-full'
        />
        <div className='ml-2'>
          <p className='text-md font-semibold'>{data?.name}</p>
          <p className='text-sm font-semibold capitalize col-span-10 ml-1'>
            Phone Number - {data?.mobile}
          </p>
        </div>
      </div>
    </div>
  );
};

PetExpertCard.propTypes = {
  data: PropTypes.object,
};

export default PetExpertCard;
