import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsDownload } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdRefreshCircle } from "react-icons/io";
import Checked from "../Assets/Checked.png";
import {
  Container,
  GradientButton,
  Modal,
  PetProfileCard,
  ReviewModal,
} from "../Components";
import {
  BATHING_OPTIONS,
  ClientVetService,
  GROOMING_OPTIONS,
  HAIR_STYLE_OPTIONS,
  SERVICES_TEXT,
  TRAINING_OPTIONS,
  VetServicesOptions,
  INSURANCE_OPTIONS,
} from "../Constants/Constants";
import {
  noShowByExpertAction,
  verifyCompleteOTPAction,
} from "../Redux/actions/serviceActions";
import { cancelServiceRequest } from "../Redux/actions/serviceActions";
import moment from "moment";
import Loader from "../Components/Loader";
import PetExpertCard from "../Components/PetExpertCard";
import OtpInput from 'react18-input-otp';
import { toast } from "react-toastify";
import { getOfferStatus } from "../utils/utils";

const ReqDetails = () => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [modalVisibility2, setModalVisibility2] = useState(false);

  let { reqId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { services } = useSelector(({ service }) => service);
  const [completeOTP, setCompleteOTP] = useState("");
  const data = services.find((item) => item.docid === reqId) || {};
  console.log("data.user_requirement", data.user_requirement);
  console.log("data.user_requirement", data);

  const offerStatus = getOfferStatus(data.request_open_for_offers);

  const section =
    !data.has_offers && offerStatus === "Pending" // no offers
      ? "0"
      : data.has_offers && offerStatus === "Pending" // has offers
      ? "1"
      : offerStatus === "Accepted"
      ? "2" // accepted
      : ["Completed", "Expired", "Cancelled", "NoShow"].includes(offerStatus) && // history
        "3";

  if (data.status === undefined) {
    return <Loader />;
  }

  const { request_datetime } = data;

  // console.log(data);
  let humanDate = request_datetime?.seconds
    ? new Date(request_datetime.seconds * 1000)
    : request_datetime;

  const handleDeletePress = (e) => {
    e.preventDefault();
    dispatch(
      cancelServiceRequest(data.docid, () => {
        navigate("/my-requests");
      })
    );
  };

  const noShowByExpert = () => {
    dispatch(
      noShowByExpertAction(reqId, (success) => {
        if (success) {
          setModalVisibility(false);
          navigate("/my-requests");
        }
      })
    );
  };

  const verifyCompleteOTP = () => {
    dispatch(
      verifyCompleteOTPAction(reqId, completeOTP, (success) => {
        if (success) {
          toast.success("Request Complete!");
          setModalVisibility2(true);
          // navigate("/my-requests");
        }
      })
    );
  };

  //TODO: Service Type

  const packageArray = data.user_requirement?.service
    ?.filter((item) => item.isPackage == true)
    ?.reduce((acc, curr) => acc + curr.price, 0);

  const individualArray = data.user_requirement?.service
    ?.filter((item) => item.isTest == true)
    ?.reduce((acc, curr) => acc + curr.price, 0);

  const subTotal = packageArray + individualArray;

  return (
    <>
      <Modal visibilty={modalVisibility}>
        <div className="grid grid-cols-12 pr-4 pt-4">
          <div className="col-span-11"></div>
          <h1
            className="text-center text-base rounded-full cursor-pointer font-extrabold"
            onClick={() => setModalVisibility(false)}
          >
            X
          </h1>
        </div>
        <div className="relative px-6 pb-6 flex-auto text-center max-w-sm">
          <h1 className="text-lg font-bold px-7 py-4">Confirm No Show</h1>
          <p className="px-7 py-4">
            By proceeding you are confirming that the expert has not arrived for the
            service.
          </p>
          <GradientButton title="PROCEED" onPress={noShowByExpert} />
        </div>
      </Modal>
      <Container className="py-8" wrapperClassName="">
        <div className="flex items-center justify-between pb-6">
          <h1 className="text-lg font-semibold">Request Details</h1>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-11">
          <div>
            <div className="py-3">
              <h3 className="font-semibold font-number">
                {moment(humanDate).format("ddd, MMMM Do YYYY h:mm a")}
              </h3>
            </div>
            <hr />

            {data.service_required_dates?.length > 1 ? (
              <div className="py-3">
                <h3 className="font-bold">Service Dates</h3>
                {data.service_required_dates.map((item) => (
                  <p key={item.requested_date_time} className="font-number">
                    {moment(
                      item.requested_date_time?.seconds
                        ? new Date(item.requested_date_time.seconds * 1000)
                        : item.requested_date_time
                    ).format("ddd, MMMM Do YYYY h:mm a")}
                  </p>
                ))}
              </div>
            ) : null}
            <div className="py-3">
              {data.user_requirement.service_type !== "insurance" ? (
                data.user_requirement.service_type === "pathology" ? (
                  <>
                    <h3 className="font-semibold">Job Details - Pathology</h3>
                    {data.user_requirement.service.map(
                      (item, index) =>
                        item.isPackage && (
                          <view
                            key={index}
                            className="text-[#767676] font-sans flex flex-row items-center mt-2 justify-start"
                          >
                            <li />
                            <h6 className="lg:max-w-[320px] lg:min-w-[320px] mb:max-w-[217px] mb:min-w-[217px]  break-all  ">
                              {item?.name}
                            </h6>
                          </view>
                        )
                    )}

                    {data.user_requirement?.service.map(
                      (item, index) =>
                        item?.isTest && (
                          <view
                            key={index}
                            className="text-[#767676] font-sans flex flex-row items-center mt-2 justify-start"
                          >
                            <li />
                            <h6 className="lg:max-w-[320px] lg:min-w-[320px] mb:max-w-[217px] mb:min-w-[217px]  break-all  ">
                              {item?.name}
                            </h6>
                          </view>
                        )
                    )}
                  </>
                ) : (
                  <h3 className="font-semibold">
                    Job Details - {SERVICES_TEXT[data.user_requirement.service_type]}
                  </h3>
                )
              ) : (
                <>
                  <h3 className="font-sans text-[24.34px] font-semibold">
                    Insurance Details
                  </h3>
                  <div className="border-[4.17px] border-darkGreen mt-[31.42px] w-[346.06px] mb:w-full h-[150.1px] rounded-md">
                    <img
                      src={INSURANCE_OPTIONS[0].image}
                      className="w-[244.61px] h-[91.73px] my-[29.19px] mx-[50.73px]"
                    />
                  </div>
                  <div>
                    <h3 className="font-robot font-semibold my-[32px]">Base Cover</h3>

                    <div className="flex ml-3 mb-4">
                      <img src={Checked} className="w-[24.52px] h-[24.52px] mr-[14px]" />
                      <h3 className="font-sans font-semibold font-medium">
                        Terminal Illness Cover
                      </h3>
                    </div>
                    <div className="flex ml-3 mb-4">
                      <img src={Checked} className="w-[24.52px] h-[24.52px] mr-[14px]" />
                      <h3 className="font-sans font-semibold font-medium">
                        Surgery and Treatment
                      </h3>
                    </div>
                    <div className="flex ml-3 mb-4">
                      <img src={Checked} className="w-[24.52px] h-[24.52px] mr-[14px]" />
                      <h3 className="font-sans font-semibold font-medium">
                        Death and Funeral Cover
                      </h3>
                    </div>
                    <div className="flex ml-3 mb-4">
                      <img src={Checked} className="w-[24.52px] h-[24.52px] mr-[14px]" />
                      <h3 className="font-sans font-semibold font-medium">
                        Pre-hospitalization and Post-hospitalization Expenses
                      </h3>
                    </div>

                    <h3 className="font-robot font-semibold my-[32px]">
                      Additional Add-ons
                    </h3>
                    {data.add_on.map((item, index) => (
                      <div key={index} className="flex ml-3 mb-4">
                        <img
                          src={Checked}
                          className="w-[24.52px] h-[24.52px] mr-[14px]"
                        />
                        <h3 className="font-sans font-semibold font-medium">
                          {item.Type}
                        </h3>
                      </div>
                    ))}
                    {/* <div className="flex ml-3 mb-4">
                      <img src={Checked} className="w-[24.52px] h-[24.52px] mr-[14px]" />
                      <h3 className="font-sans font-semibold font-medium">
                        Lost and Stolen Cover
                      </h3>
                    </div>
                    <div className="flex ml-3 mb-4">
                      <img src={Checked} className="w-[24.52px] h-[24.52px] mr-[14px]" />
                      <h3 className="font-sans font-semibold font-medium">
                        Emergency Pet Minding{" "}
                      </h3>
                    </div>
                    <div className="flex ml-3 mb-4">
                      <img src={Checked} className="w-[24.52px] h-[24.52px] mr-[14px]" />
                      <h3 className="font-sans font-semibold font-medium">
                        Vet Consultation & Doctor on Call
                      </h3>
                    </div> */}
                  </div>
                </>
              )}

              <h3 className="text-slate-500 font-medium mt-2 font-number">
                {data.user_requirement.service_type === "vet" &&
                  data.user_requirement.service.map((service, i) => (
                    <ul key={i} className="list-disc ml-4">
                      <li>
                        {VetServicesOptions.find((item) => item.value === service)?.title}
                        {ClientVetService.find((item) => item.value === service)?.title}
                      </li>
                    </ul>
                  ))}
                {data.user_requirement.service_type === "pet_grooming" &&
                  data.user_requirement.service.map((service, i) => (
                    <ul key={i} className="list-disc ml-4">
                      <li>
                        {GROOMING_OPTIONS.find((item) => item.value === service)?.title}
                        {BATHING_OPTIONS.find((item) => item.value === service)?.label}
                        {HAIR_STYLE_OPTIONS.find((item) => item.value === service)?.label}
                      </li>
                    </ul>
                  ))}
                {data.user_requirement.service_type === "pet_trainers" && (
                  <>
                    <h1>
                      {TRAINING_OPTIONS[data.user_requirement.service[0]] || "None"}
                    </h1>
                  </>
                )}
                {data.user_requirement.service_type === "pet_sitters" && (
                  <>
                    <h1>
                      Needs To Be Walked? {data.user_requirement.petWalk ? "Yes" : "No"}
                    </h1>
                    <h1>Walk No.: {data.user_requirement.per_day_allotment}</h1>
                  </>
                )}
                {data.user_requirement.service_type === "pet_boarders" && (
                  <>
                    <h1>
                      Do you need your pet to be picked up from your home?{" "}
                      {data.user_requirement.pickService ? "Yes" : "No"}
                    </h1>
                    {/*<h1>*/}
                    {/*  Do you need your pet to be droped up at your home?{' '}*/}
                    {/*  {data.user_requirement.service.drop}*/}
                    {/*</h1>*/}
                    <h1>
                      Food +Treats to be provided by you?{" "}
                      {data.user_requirement.selfFeed ? "Yes" : "No"}
                    </h1>
                    <h1>
                      Does your pet need to be walked?{" "}
                      {data.user_requirement.petWalk ? "Yes" : "No"}
                    </h1>
                    <h1>
                      If yes how many times a day?{" "}
                      {data.user_requirement.per_day_allotment}
                    </h1>
                  </>
                )}
                {data.user_requirement.service_type === "dog_walking" && (
                  <>
                    <h1>Duration of Walk: {data.user_requirement.service[1]}</h1>
                    <h1>No of walks: {data.user_requirement.per_day_allotment}</h1>
                  </>
                )}
              </h3>
            </div>
            <hr />
            <div className="py-3">
              <h3 className="font-semibold">Address</h3>
              <h3 className="text-slate-500 font-medium mt-2 font-number">
                {data.pet_obj.building}, {data.pet_obj.location_address}
              </h3>
            </div>
            <hr />
            <div className="py-3">
              <h3 className="font-semibold">Pet Details</h3>
              <PetProfileCard data={data.pet_obj} noEdit noLocation className="mt-4" />
            </div>
            {data.user_requirement.service_type === "pathology" && (
              <div>
                <h3 className="font-semibold mt-[40.45px]">Selected Time Slot</h3>
                <h6 className="bg-primary rounded-md text-white lg:text-[21px] mb:text-[15px] lg:pt-3 mb:pt-4 font-sans font-semibold pt-2 text-center lg:w-[442px] mb:w-full h-[59px] mt-[20px]">
                  {moment(humanDate).format("ddd, MMMM Do YYYY h:mm a")}
                </h6>
                <div className="border-1 border-[#B8B8B8] px-[20px] rounded-md  lg:w-[516.86px] mb:w-full h-[auto] pb-4 mt-[41.45px]">
                  <h6 className="mt-[25px] mb-[20px] text-[20px] font-roboto font-semibold">
                    Recommended By:
                  </h6>
                  <div className=" flex row">
                    <input type="radio" checked />{" "}
                    <h6 className=" ml-2 text-[20px] font-roboto font-semibold">
                      {data?.user_requirement?.Recommended_By}
                    </h6>
                  </div>
                  {data?.user_requirement?.Recommended_By !== "Self" && (
                    <>
                      <h6 className="mt-[25px] mb-[20px] text-[20px] font-roboto font-semibold">
                        Doctor&apos;s Name
                      </h6>
                      <h6 className="border-1 border-[#ABB4BD] rounded-md h-[41px] p-2">
                        {data.user_requirement.Recommending_Doctor}
                      </h6>

                      <h6 className="mt-[25px] mb-[20px] text-[20px] font-roboto font-semibold">
                        Doctor&apos;s Location
                      </h6>
                      <h6 className="border-1 border-[#ABB4BD] rounded-md h-[41px] p-2">
                        {data.user_requirement.Doctor_Location}
                      </h6>

                      {data.user_requirement.Upload_Prescription && (
                        <>
                          <h6 className="mt-[25px] mb-[20px] text-[20px] font-roboto font-semibold">
                            Prescription
                          </h6>
                          <div className="flex items-center justify-between p-2  mt-3 gap-7 border-1 border-[0.443703px] rounded-md border-[#ABB4BD]  hover:border-yellow ">
                            <h6 className="mt-[5px] mb-[2px] text-[15px] font-roboto ">
                              Download Prescription
                            </h6>
                            <BsDownload
                              scale={1.5}
                              className="text-[20px] hover:cursor-pointer "
                              onClick={() => {
                                window.open(data.user_requirement.Upload_Prescription);
                              }}
                            />
                            {/* <input
                              type="file"
                              onChange={async (e) => {}}
                              placeholder="1 file selected"
                              onClick={(e) => {
                                e.preventDefault();
                                console.log("clicked");
                                window.open(data.user_requirement.Upload_Prescription);
                              }}
                            /> */}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>

                <h3 className="font-roboto text-[20.65px] text-[#3B3D4C] font-normal mt-[30px]">
                  Pick Up
                </h3>

                <div className="mt-[20px] border-1 border-[#B8B8B8] px-[20px] rounded-md  w-[516.86px] mb:w-full h-[auto] pb-4">
                  <h6 className="mt-[25px] mb-[20px] text-[20px] font-roboto font-semibold">
                    Do you want draw the samples (blood test) of your pet to be picked
                    from its location.
                  </h6>
                  <div className=" flex row">
                    <input type="radio" checked />{" "}
                    <h6 className=" ml-2 text-[20px] font-roboto font-semibold">
                      {data.user_requirement.Tested_Location}
                    </h6>
                  </div>
                  {data.user_requirement.Tested_Location === "Yes" && (
                    <>
                      <h6 className="mt-[25px] mb-[20px] text-[20px] font-roboto font-semibold">
                        pet&apos;s Location
                      </h6>
                      <h6 className="border-1 border-[#ABB4BD] rounded-md h-[41px] p-2">
                        {data.user_requirement.pet_Location}
                      </h6>
                      <h6 className="mt-[25px] mb-[20px] text-[20px] font-roboto font-semibold">
                        Post Office
                      </h6>
                      <h6 className="border-1 border-[#ABB4BD] rounded-md h-[41px] p-2">
                        {data.user_requirement.pet_Location_Post_Office}
                      </h6>
                    </>
                  )}
                </div>
              </div>
            )}
            {section === "2" ? (
              <div className="py-3">
                <h3 className="font-semibold">Expert Details</h3>
                <PetExpertCard data={data.booked_expert} />

                {/* <h3 className="font-semibold mt-6 mb-[-40px]">OTP Status</h3> */}

                <div className="border-2 rounded-lg pt-3 my-3 mt-10 flex">
                  {/* <div className={hide2}>

                  <p className="font-semibold mt-2 text-blue-500 ml-4 cursor-pointer"
                    onClick={() => {
                      setHide('');
                      setHide2('hidden');
                    }}
                    >
                      Click Here for OTP
                    </p>
                  </div> */}

                  <div className="w-[80%]">
                    {data.start_job_otp && data.complete_job_otp === undefined ? (
                      <>
                        <h2 className="mt-2 ml-4 font-semibold">
                          Please provide this OTP to expert
                        </h2>
                        <h2 className="mt-2 ml-4 font-semibold">
                          OTP - {data.start_job_otp}
                        </h2>
                      </>
                    ) : (
                      <>
                        {data.complete_job_otp ? (
                          <h2 className="mt-2 ml-4 font-semibold">
                            Awaiting confirmation of job completion
                          </h2>
                        ) : (
                          <h2 className="mt-2 ml-4 font-semibold">
                            Service has not started yet.
                          </h2>
                        )}
                      </>
                    )}
                  </div>
                  <div className="flex mb-3 ml-5">
                    <IoMdRefreshCircle
                      className="mt-1 cursor-pointer"
                      size="34"
                      fill="#F49F02"
                      onClick={() => {
                        location.reload();
                      }}
                    />
                    <h1 className="text-base font-bold mt-2 mr-1 lg:mr-2">Refresh</h1>
                    {/* <div className="text-[11px] font-bold">
                      <p>Click</p>
                      <p className="mt-[-4px]">Here</p>
                      {data.start_job_otp && data.complete_job_otp === undefined ? (
                      null
                      )
                      :
                      <p className="mt-[-4px]">for OTP</p>}
                    </div> */}
                  </div>
                </div>

                {data.complete_job_otp ? (
                  <>
                    <h2 className="mt-2 font-semibold">Enter Job Complete OTP</h2>
                    <div className="flex flex-col lg:flex-row">
                      <OtpInput
                        value={completeOTP}
                        shouldAutoFocus
                        onChange={(otp) => setCompleteOTP(otp)}
                        numInputs={6}
                        placeholder="------"
                        inputStyle=" font-medium font-number border-1 text-lg text-base rounded-md mx-4 otp-input first:ml-0"
                        containerStyle="my-4"
                      />
                      <GradientButton
                        disabled={completeOTP?.length !== 6}
                        type="submit"
                        onPress={verifyCompleteOTP}
                        className="w-3 m-3 lg:ml-[15px] ml-[22%]"
                        title="VERIFY"
                      />
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
            {section === "3" ? (
              <ReviewModal
                requestId={reqId}
                modalVisibility={modalVisibility2}
                setModalVisibility={setModalVisibility2}
              />
            ) : null}
          </div>
          <div>
            <div>
              <div style={{ height: "40vh", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    // eslint-disable-next-line no-undef
                    key: process.env.REACT_APP_MAP_KEY,
                  }}
                  defaultCenter={{ lat: 19.076, lng: 72.8777 }}
                  defaultZoom={14}
                  center={{
                    lat: data.pet_obj.location_latitude || 19.076,
                    lng: data.pet_obj.location_longitude || 72.8777,
                  }}
                >
                  <FaMapMarkerAlt
                    lat={data.pet_obj.location_latitude}
                    lng={data.pet_obj.location_longitude}
                    size={26}
                    fill={"#F49F02"}
                    text={data.pet_obj.location_address}
                  />
                </GoogleMapReact>
              </div>
            </div>
            {data?.accepted_amount && (
              <>
                <hr />
                <div className="py-3">
                  {/*<h3 className='font-semibold'>*/}
                  {/*  Job Details -{' '}*/}
                  {/*  {SERVICES_TEXT[data.user_requirement.service_type]}*/}
                  {/*</h3>*/}
                  {/*<h4 className='my-3'>{data.user_requirement.service[0]}</h4>*/}
                  <hr />
                  <div className="my-3 grid grid-cols-2 text-center text-right md:text-left">
                    <h4>Offer Amount</h4>
                    <h4 className="font-number">₹ {data.accepted_amount}</h4>
                    <h4>Payment Gateway fee 2%</h4>
                    <h4 className="font-number">₹ {(data.accepted_amount / 1000) * 2}</h4>
                    <h4>Booking Charges</h4>
                    <h4 className="font-number">₹ - {data.razorpay_amount_paid}</h4>
                  </div>
                  <hr />
                  <div className="my-3 grid grid-cols-2 font-bold text-center text-right md:text-left">
                    <h4>
                      {section === "4"
                        ? "Final amount paid to expert"
                        : "Payment at the end of services (excluding payment gateway charges)"}
                    </h4>
                    <h4 className="font-number">
                      ₹ {data.accepted_amount - data.accepted_amount / 10}
                    </h4>
                  </div>
                </div>
              </>
            )}
            {data.user_requirement.service_type === "insurance" && (
              <div className="mt-8">
                <h3 className="font-sans font-semibold text-[21.17px]">
                  Your Quote No. {data.quoteId}
                </h3>

                <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
                  <h6 className="text-[#545454]">Plan Details</h6>
                  <h6>Amount</h6>
                </div>

                <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
                  <h6 className="text-[#767676] font-normal">{`${data.pet_obj.name}, ${data.pet_obj.breed} `}</h6>
                  <h6>Rs. {data.priceDetails.totalCoverAmount}</h6>
                </div>

                <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
                  <h6 className="text-[#545454]">Term Premium</h6>
                  <h6>Rs. {data.priceDetails.totalCoverAmount}</h6>
                </div>

                <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
                  <h6 className="text-[#545454]">Premium without Service Tax</h6>
                  <h6>Rs. {data.priceDetails.totalCoverAmount}</h6>
                </div>

                <hr className="bg-gray mt-[20px]" />

                <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
                  <h6 className="text-[#545454]">Gross Total</h6>
                  <h6>Rs. {data.priceDetails.grossTotal}</h6>
                </div>

                <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
                  <h6 className="text-[#767676] font-normal">GST @ 18%</h6>
                  <h6>Rs. {data.priceDetails.gst}</h6>
                </div>

                <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
                  <h6 className="text-[#767676] font-normal">Payment Gateway fees 2%</h6>
                  <h6>Rs. {data.priceDetails.gatewayCharge}</h6>
                </div>

                <hr className="bg-gray mt-[20px]" />

                <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
                  <h6 className="text-[#545454]">Final Premium</h6>
                  <h6>Rs. {data.priceDetails.final_premium}</h6>
                </div>
              </div>
            )}
            {data.user_requirement.service_type === "pathology" && (
              <div>
                <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
                  <h6>Packages</h6>
                  {/* {const result = words.filter(word => word.length} */}
                  <h6>Rs. {packageArray}</h6>
                </div>

                {data.user_requirement.service.map(
                  (item, index) =>
                    item.isPackage && (
                      <div key={index} className="flex justify-between mt-[20px]">
                        <h6 className="lg:max-w-[320px] lg:min-w-[320px] mb:max-w-[217px] mb:min-w-[217px] break-all">
                          {item.name}
                        </h6>
                        <h6 className="min-w-[55px] ml-2 mb:ml-1">Rs. {item.price}</h6>
                      </div>
                    )
                )}

                <hr className="bg-gray mt-[20px]" />

                <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
                  <h6>Individual Tests</h6>
                  <h6>Rs. {individualArray}</h6>
                </div>

                {data.user_requirement.service.map(
                  (item, index) =>
                    item?.isTest && (
                      <div key={index} className="flex justify-between mt-[20px]">
                        <h6 className="lg:max-w-[320px] lg:min-w-[320px] mb:max-w-[217px] mb:min-w-[217px] break-all">
                          {item?.name}
                        </h6>
                        <h6 className="min-w-[55px] ml-2 mb:ml-1">Rs. {item?.price}</h6>
                      </div>
                    )
                )}

                {data.user_requirement.Tested_Location === "Yes" && (
                  <>
                    <hr className="bg-gray mt-[20px]" />
                    <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
                      <h6>Pickup Charges</h6>
                      <h6>Rs. {data.user_requirement.Pick_Up_Amount}</h6>
                    </div>
                  </>
                )}
                <hr className="bg-gray mt-[20px]" />

                <div className="flex justify-between font-sans mt-[20px]">
                  <h6>Sub-total</h6>
                  <h6>Rs. {data.user_requirement?.charges?.subTotal}</h6>
                </div>

                <div className="flex justify-between font-sans mt-[20px]">
                  <h6>GST 18%</h6>
                  <h6>Rs. {data.user_requirement?.charges?.gst}</h6>
                </div>

                <div className="flex justify-between font-sans  mt-[20px]">
                  <h6>Payment Gateway fees 2%</h6>
                  <h6>Rs. {data.user_requirement?.charges?.gatewayCharges}</h6>
                </div>

                <hr className="bg-gray mt-[20px]" />

                <div className="flex justify-between text-[18px] font-sans font-semibold mt-[20px]">
                  <h6>Final Amount</h6>
                  <h6>Rs. {data.user_requirement?.charges?.totalAmount}</h6>
                </div>
              </div>
            )}
            {
              // data.user_requirement.service_type !== "pathology" &&
              //   data.user_requirement.service_type !== "insurance" && (
              //     <div className="py-7">
              //       <h3 className="font-semibold">Your Request</h3>
              //       <h3 className="text-slate-500 font-medium mt-2 font-number">
              //         {/* {data.user_requirement.optionalRequirements || "None"} */}
              //       </h3>
              //     </div>
              //   )
            }
          </div>
        </div>
        <div className="flex justify-center mt-4 gap-4">
          {section === "0" ? (
            <GradientButton title="CANCEL" onPress={handleDeletePress} />
          ) : section === "1" ? (
            <GradientButton
              title="VIEW OFFERS"
              onPress={() => {
                navigate("/view-requests", {
                  state: { requestId: data.docid },
                });
              }}
            />
          ) : section === "2" ? (
            <GradientButton
              title="NO SHOW BY EXPERT"
              onPress={() => setModalVisibility(true)}
            />
          ) : (
            (section === "3" || section === "5") && (
              <GradientButton
                title="BACK TO MY REQUESTS"
                onPress={() => {
                  navigate("/my-requests");
                }}
              />
            )
          )}
        </div>
      </Container>
    </>
  );
};

export default ReqDetails;
